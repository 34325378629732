import React, { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import DropDown from 'components/inputs/DropDown';
import Button from 'components/inputs/Button';
import { AiOutlineUser } from 'react-icons/ai';
// import { BsBell } from 'react-icons/bs';
// import { HiOutlineMenuAlt1 } from 'react-icons/hi';
import { BiChevronLeft } from 'react-icons/bi';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_SIDEBAR } from 'actions/sidebar';
import { SidebarContext } from '../layout';
import CZIconLogo from 'assets/images/icons/czIconWhite.svg';
// import { Link } from 'react-router-dom';
import { MdOutlineLogout } from 'react-icons/md';
// import { FiUser } from 'react-icons/fi';
import { cookie } from 'utils/cookies/cookies';
import { FETCH_NOTIFICATION, REMOVE_NOTIFICATION } from 'actions/notification';
import ActivityItem from 'components/general/ActivityItem';
import { AiOutlineCloudSync } from 'react-icons/ai';
import { MdOutlinePersonOutline } from 'react-icons/md';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import LogoutModal from 'view/logout/LogoutModal';
//import ProfilePicInput from 'components/inputs/ProfilePicInput';
import { FaBell } from 'react-icons/fa';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { GET_NOTIFICATIONS, MANAGE_NOTIFICATIONS } from 'components/common/constant';

const AdminHeader = (props) => {
  const { title, backTo, showButton, buttonText, onClickButton, className } = props;
  const dispatch = useDispatch();
  const sidebarData = useContext(SidebarContext);
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const profileData = useSelector((state) => state.profile.userProfile);
  const allGeneralNotification = useSelector((state) => state.notification.notifications);
  const notification = useSelector((state) => state.notification.notificationCount);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const excludedModules = useSelector((state) => state.manageRole?.excludedModules);

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const getGeneralNotification = useCallback(() => {
    const data = {
      limit: 10,
    };
    dispatch({ type: FETCH_NOTIFICATION, payload: data });
  }, []);

  const handleSidebarToggle = () => {
    dispatch({ type: SHOW_SIDEBAR });
  };

  const handleClick = useCallback(() => {
    const sessionTenantId = new URLSearchParams(window.location.search).get('tenant');
    if (sessionTenantId) {
      localStorage.removeItem(`${sessionTenantId}_token`);
      localStorage.removeItem(`${sessionTenantId}_tenant_data`);
      window.location.href = '/login';
    } else {
      cookie.clean();
      window.location.href = '/login';
    }
  }, []);

  const handleCloseLogoutModel = () => {
    setShowLogoutModal(false);
  };

  const removeNotification = () => {
    dispatch({ type: REMOVE_NOTIFICATION });
  };

  const notificationPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_NOTIFICATIONS || item.name === MANAGE_NOTIFICATIONS),
      (data) => data.name
    );

  const isExcludedTenant = (label) => {
    let valCheck = false;
    excludedModules?.map((data) => {
      if (_.isEqual(data?.name, label)) {
        valCheck = true;
      }
    });
    return valCheck;
  };

  return (
    <React.Fragment>
      <div className="header-container__main">
        <div className="header-container__inner">
          <div className="header-left-content__block">
            {sidebarData.showToggle && (
              <div className="header-sidebar-toggle">
                <div className="header-logo--img" onClick={handleSidebarToggle}>
                  {profileData?.tenant?.logo ? <img src={profileData?.tenant?.logo} alt="logo" /> : <img src={CZIconLogo} alt="logo" />}
                </div>
              </div>
            )}
            <div className="header-left-icon">
              {backTo && !_.isEmpty(backTo) ? (
                <a onClick={() => navigateTo(backTo)} className="back-to-link">
                  <BiChevronLeft />
                </a>
              ) : (
                <div></div>
              )}
            </div>
            <div className={`header-page--title ${className ? className : ''}`}>
              <span>{title}</span>
            </div>
            {showButton && (
              <div className="header-left--btn">
                <Button className="header--btn" onClick={onClickButton}>
                  {buttonText}
                </Button>
              </div>
            )}
          </div>
          <div className="header-right-content__block">
            {!_.isEmpty(notificationPermissions) && !isExcludedTenant('Notification') && (
              <div className="notification-icon" title="See Notification">
                <DropDown
                  popupClass="bottom-left notification-list-menu scrollable"
                  trigger={
                    <div className="notification--block" onClick={removeNotification}>
                      <FaBell onClick={() => getGeneralNotification()} />
                      {notification > 0 && <span className="notification-count"> {notification}</span>}
                    </div>
                  }
                  triggerClass={'p-2'}
                >
                  {_.isEmpty(
                    _.filter(allGeneralNotification, (item) => {
                      return item.message_type !== 'firmware_update';
                    })
                  ) ? (
                    <ul>
                      <li className="item justify-content-center">
                        <div>{allGeneralNotification ? 'Loading..' : allGeneralNotification}</div>
                      </li>
                    </ul>
                  ) : (
                    _.map(
                      _.filter(allGeneralNotification, (item) => {
                        return item.message_type !== 'firmware_update';
                      }),
                      (notification, index) => (
                        <div onClick={() => navigateTo('/notification')}>
                          <ActivityItem
                            key={`notification-${index}`}
                            icon={notification.type === 'charger' ? <AiOutlineCloudSync /> : <MdOutlinePersonOutline />}
                            text={
                              <>
                                {_.get(notification, 'from', '')} {''}
                                <span className="meta-info">{_.get(notification, 'message', '')}</span> {_.get(notification, 'to', '')}
                              </>
                            }
                            date={moment(_.get(notification, 'createdAt')).format('dddd, DD MMMM, h:mm A')}
                          />
                        </div>
                      )
                    )
                  )}
                </DropDown>
              </div>
            )}
            <div className="has-dropdown  p-2 user-profile-icon" onClick={() => navigateTo('/setting?tab=profile')}>
              <AiOutlineUser />
              <div className="user-info--block">
                <div className="user__info">
                  <span className="user--name text-ellipsis">{_.get(profileData, 'name', '')}</span>
                  <span className="user--role text-ellipsis">{_.startCase(_.get(profileData, 'role', ''))}</span>
                </div>
              </div>
            </div>
            <div
              className="has-dropdown  p-2 user-logout"
              title="Logout"
              onClick={() => {
                setShowLogoutModal(true);
                handleClick;
              }}
            >
              <MdOutlineLogout />
            </div>
          </div>
        </div>
        {showLogoutModal && (
          <Modal show={showLogoutModal} centered onHide={handleCloseLogoutModel}>
            <LogoutModal onClose={handleCloseLogoutModel} onRemove={handleClick} />
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

const DropDownList = (props) => {
  const { icon, text, time, className } = props;
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);

  return (
    <div className={`notification-dropdown-list__main ${className ? className : ''}`} onClick={() => navigateTo('/notification')}>
      <div className="list-item--inner">
        <div className="list-icon--block">
          <div className="list_icon__inner">
            <div className="list--icon">{icon}</div>
          </div>
        </div>
        <div className="list-content__main-block">
          <div className="list-content--inner">
            <div className="list-details--content-block">
              <div className="list-details-block">{text}</div>
              <span className="list-timing">{time}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
DropDownList.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.node,
  time: PropTypes.string,
  className: PropTypes.string,
};

AdminHeader.propTypes = {
  title: PropTypes.string,
  backTo: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.func,
  className: PropTypes.string,
};

export default AdminHeader;
