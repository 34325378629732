import React, { useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import * as _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import { UPDATE_CPO } from 'actions/partnerManagement';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const Financials = () => {
  const { ocpiId } = useParams();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const roleId = useSelector((state) => state.partnerManagement.partnerInfo.id);
  const ocpiData = useSelector((state) => state.partnerManagement.partnerInfo);

  const EMSPFinanceSchema = Yup.object().shape({
    bankName: Yup.string().required(t('financialsEMSP.bankName')),
    branch: Yup.string().required(t('financialsEMSP.branch')),
    account_no: Yup.string().required(t('financialsEMSP.accountNo')),
    ifscCode: Yup.string().required(t('financialsEMSP.ifscCode')),
    owner: Yup.string().required(t('financialsEMSP.owner')),
    commission: Yup.string().required(t('financialsEMSP.commission')),
    discount: Yup.string().required(t('financialsEMSP.discount')),
    serviceCharge: Yup.string().required(t('financialsEMSP.serviceCharge')),
  });

  const initialValues = ocpiId
    ? {
        bankName: ocpiData.bankName,
        branch: ocpiData.branch,
        account_no: ocpiData.account_no,
        ifscCode: ocpiData.ifscCode,
        owner: ocpiData.owner,
        commission: ocpiData.commission,
        discount: ocpiData.discount,
        serviceCharge: ocpiData.serviceCharge,
      }
    : {
        bankName: '',
        branch: '',
        account_no: '',
        ifscCode: '',
        owner: '',
        commission: '',
        discount: '',
        serviceCharge: '',
      };

  return (
    <React.Fragment>
      <LayoutContainer>
        <Card className="customerBooking-table">
          <Formik
            initialValues={initialValues}
            validationSchema={EMSPFinanceSchema}
            onSubmit={(data) =>
              dispatch({
                type: UPDATE_CPO,
                payload: { ...data, id: roleId },
                cb: (res) => {
                  if (_.get(res, 'status', '') === UPDATE_STATUS_CODE) {
                    navigateTo(`/OCPI-Partner`);
                  }
                },
              })
            }
          >
            {({ handleSubmit, values, errors, touched, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Card>
                  <div className="address_details_sec">
                    <div className="cpo_title">
                      <span>{t('financialsCPO.bankDetails')}</span>
                    </div>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('financialsCPO.bankNameLabel')}
                          placeholder={t('financialsCPO.enterBankName')}
                          name="bankName"
                          value={values.bankName}
                          onChange={(val) => setFieldValue('bankName', val.target.value)}
                          error={errors.bankName && touched.bankName ? errors.bankName : null}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('financialsCPO.branchName')}
                          placeholder={t('financialsCPO.enterBranchName')}
                          name="branch"
                          value={values.branch}
                          onChange={(val) => setFieldValue('branch', val.target.value)}
                          error={errors.branch && touched.branch ? errors.branch : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('financialsCPO.accountNoLabel')}
                          placeholder={t('financialsCPO.enterAccountNo')}
                          name="ac"
                          value={values.account_no}
                          onChange={(val) => setFieldValue('account_no', val.target.value)}
                          error={errors.account_no && touched.account_no ? errors.account_no : null}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('financialsCPO.ifscCodeLabel')}
                          placeholder={t('financialsCPO.enterIFSC')}
                          name="ifscCode"
                          value={values.ifscCode}
                          onChange={(val) => setFieldValue('ifscCode', val.target.value)}
                          error={errors.ifscCode && touched.ifscCode ? errors.ifscCode : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('financialsCPO.ownerName')}
                          placeholder={t('financialsCPO.enterOwner')}
                          name="owner"
                          value={values.owner}
                          onChange={(val) => setFieldValue('owner', val.target.value)}
                          error={errors.owner && touched.owner ? errors.owner : null}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>

                <div>&nbsp;</div>

                {/* Billing Information */}

                <Card>
                  <div className="address_details_sec">
                    <div className="cpo_title">
                      <span>{t('financialsCPO.billing')}</span>
                    </div>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('financialsCPO.commissionLabel')}
                          placeholder={t('financialsCPO.commissionLabel')}
                          value={values.commission}
                          onChange={(val) => setFieldValue('commission', val.target.value)}
                          error={errors.commission && touched.commission ? errors.commission : null}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('financialsCPO.discountLabel')}
                          placeholder={t('financialsCPO.discountLabel')}
                          value={values.discount}
                          onChange={(val) => setFieldValue('discount', val.target.value)}
                          error={errors.discount && touched.discount ? errors.discount : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('financialsCPO.serviceChargeLabel')}
                          placeholder={t('financialsCPO.serviceChargeLabel')}
                          value={values.serviceCharge}
                          onChange={(val) => setFieldValue('serviceCharge', val.target.value)}
                          error={errors.serviceCharge && touched.serviceCharge ? errors.serviceCharge : null}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>

                <div>&nbsp;</div>

                <div className="cpo_save_btn">
                  <Button type="submit" className="cpo_save_btn__1">
                    {t('button.save')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default Financials;
