import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MdClose } from 'react-icons/md';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import { CREATE_CUSTOMER_BOOKING } from 'actions/customer';
import Button from 'components/inputs/Button';
import { useTranslation } from 'react-i18next';
const ScheduleBooking = (props) => {
  const { onClose, bookingInfo, bookingData } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const createCustomerBooking = useCallback((data) => {
    dispatch({
      type: CREATE_CUSTOMER_BOOKING,
      payload: data,
    });
  }, []);

  const handleClick = () => {
    if (_.get(bookingData, 'useChargeCoin')) {
      createCustomerBooking({ ...bookingData, chargecoin: _.get(bookingInfo, 'chargecoin') });
    } else {
      createCustomerBooking(bookingData);
    }
    onClose();
  };

  return (
    <React.Fragment>
      <div className="booking-page__main">
        <Card>
          <div className="booking-header__block">
            <div className="booking-header-name">
              <span>{t('scheduleBooking.successMessage')}</span>
            </div>
            <div className="booking-close_btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <BookingInfoBlock headingName={'Booking Info'}>
            <BookingDetail
              bookingText={
                _.get(bookingData, 'booking_type') === 'unit'
                  ? 'Units Charged For'
                  : _.get(bookingData, 'booking_type') === 'money'
                  ? 'Amount Charged For'
                  : 'Time Charged For'
              }
              bookingvalue={_.get(bookingInfo, 'units')}
            />
            <BookingDetail bookingText={'Service Charges'} bookingvalue={_.get(bookingInfo, 'service_charge')} />
            {_.get(bookingData, 'useChargeCoin') && <BookingDetail bookingText={'Charge Coins'} bookingvalue={_.get(bookingInfo, 'chargecoin')} />}
            <BookingDetail bookingText={'Sub Total'} bookingvalue={_.get(bookingInfo, 'subtotal')} />
            <BookingDetail bookingText={'GST'} bookingvalue={_.get(bookingInfo, 'gst')} />
            <BookingDetail bookingText={'Amount'} bookingvalue={_.get(bookingInfo, 'amount')} />
          </BookingInfoBlock>
          <div className="booking-close-button-box" onClick={handleClick}>
            <Button className="booking-close-button">{t('button.createBooking')}</Button>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export const BookingInfoBlock = (props) => {
  const { headingName, children } = props;

  return (
    <div className="booking-body__block">
      <div className="booking-inner--block">
        <div className="booking--header">
          <span className="booking-header-text">{headingName}</span>
        </div>
        <div className="booking-content">{children}</div>
      </div>
    </div>
  );
};
BookingInfoBlock.propTypes = {
  headingName: PropTypes.string,
  children: PropTypes.node,
};

export const BookingDetail = (props) => {
  const { bookingText, bookingvalue } = props;
  return (
    <div className="booking-content-inner">
      <div className="booking-content-text">{bookingText}</div>
      <div className="booking-content-detail">{bookingvalue}</div>
    </div>
  );
};

BookingDetail.propTypes = {
  bookingText: PropTypes.string,
  bookingvalue: PropTypes.string,
};

ScheduleBooking.propTypes = {
  onClose: PropTypes.func,
  bookingInfo: PropTypes.func,
  bookingData: PropTypes.func,
  bookingCoupons: PropTypes.func,
};

export default ScheduleBooking;
