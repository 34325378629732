import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Col, Row, Spinner, Modal } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
import DateTimePicker from 'components/inputs/DateTimePicker';
import * as _ from 'lodash';
// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';
import { roundOfDigit } from 'components/common/utils';
//import { millisecondsToHourMinute, roundOfDigit } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { DOWNLOAD_STATION_UNIT_CONSUMPTION, STATION_UNIT_CONSUMPTION } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { FETCH_COUNTRY, FETCH_STATE, GET_CITY_BY_STATE } from 'actions/address';
import { FETCH_CPO } from 'actions/partnerManagement';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { useTranslation } from 'react-i18next';
import VinForm from 'view/chargerStatus/vinForm';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FETCH_TAGS } from 'actions/tagmanagement';
import { DOWNLOAD_CHARGE_RCT } from 'actions/invoice';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const StationUnitConsumption = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAllowed } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  const [cityLimit, setCityLimit] = useState(false);
  // const [allStationUnitConsumptionData, setAllStationUnitConsumptionData] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeState, setChangeState] = useState(false);
  const [changeCity, setChangeCity] = useState(false);
  const [changeStation, setChangeStation] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [changeStationType, setChangeStationType] = useState(false);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showForm, setShowForm] = useState(false);
  const [bookingID, setBookingID] = useState('');

  const stationUnitConsumptionList = useSelector((state) => state.stationUnitConsumption.stationUnitConsumptions);
  const isLoader = useSelector((state) => state.stationUnitConsumption.isLoading);
  const page = useSelector((state) => state.stationUnitConsumption.page);
  const totalData = useSelector((state) => state.stationUnitConsumption.total);
  const limit = useSelector((state) => state.stationUnitConsumption.limit);
  const totalPages = useSelector((state) => state.stationUnitConsumption.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');
  const [downloading, setDownloading] = useState({});
  const [selectedOption, setSelectedOption] = useState('station_name');
  const searchOptions = [
    { label: 'Station Name', value: 'station_name' },
    { label: 'Charger ID', value: 'charger_id' },
  ];

  const stationUnitConsumption = useCallback(
    (value) => {
      if (searchText) {
        const stationUnitConsumptionData = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: {
            [selectedOption]: searchText,
          },
          is_emsp_based_booking: false,
        };
        dispatch({
          type: STATION_UNIT_CONSUMPTION,
          payload: stationUnitConsumptionData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else if (!_.isEmpty(userProfileStations)) {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
          is_emsp_based_booking: false,
        };
        dispatch({
          type: STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText, selectedOption]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (
        advanceFilterData.charging_station === 'All' &&
        advanceFilterData.state === 'All' &&
        advanceFilterData.city === 'All' &&
        advanceFilterData.access_type === 'All'
      ) {
        const filterData = { ...advanceFilterData, charging_station: '', state: '', city: '', access_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', city: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else if (advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        stationUnitConsumption(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
      };
      stationUnitConsumption(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      stationUnitConsumption(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, searchText, selectedOption]);

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      stationUnitConsumption(data);
    } else {
      stationUnitConsumption();
    }
  }, []);

  // useEffect(() => {
  //   setAllStationUnitConsumptionData(
  //     _.filter(
  //       stationUnitConsumptionList,
  //       (item) =>
  //         startsWith(_.get(item, 'charger.charging_station.access_type'), searchText) ||
  //         startsWith(roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3).toString(), searchText) ||
  //         startsWith(millisecondsToHourMinute(_.get(item, 'time_taken')).toString(), searchText) ||
  //         startsWith(_.get(item, 'charger.charging_station.name'), searchText) ||
  //         startsWith(_.get(item, 'no_of_session').toString(), searchText)
  //     )
  //   );
  // }, [searchText, stationUnitConsumptionList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };
  const handleSearch = () => {
    stationUnitConsumption({});
  };
  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && stationUnitConsumption(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        stationUnitConsumption(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && stationUnitConsumption(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        stationUnitConsumption(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlevinclick = (bID) => {
    setShowForm(true), setBookingID(bID);
  };

  const downloadChargeRct = useCallback((data) => {
    setDownloading((prev) => ({
      ...prev,
      [data.id]: true,
    }));
    dispatch({
      type: DOWNLOAD_CHARGE_RCT,
      payload: data.id,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Charge Receipt'}.pdf`);
          }
        }
        setDownloading(false);
      },
    });
  });

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        if (
          advanceFilterData.charging_station === 'All' &&
          advanceFilterData.state === 'All' &&
          advanceFilterData.city === 'All' &&
          advanceFilterData.access_type === 'All'
        ) {
          const filterData = { ...advanceFilterData, charging_station: '', state: '', city: '', access_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, state: '', city: '', charging_station: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '', state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '', city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '', state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.state === 'All') {
          const filterData = { ...advanceFilterData, state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else if (advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          stationUnitConsumption(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        stationUnitConsumption(filterDateData);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: {
            [selectedOption]: searchText,
          },
          page: page.selected + 1,
        };
        stationUnitConsumption(data);
      } else if (searchText) {
        const data = {
          search: {
            [selectedOption]: searchText,
          },
          page: page.selected + 1,
        };
        stationUnitConsumption(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        stationUnitConsumption(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        stationUnitConsumption(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, selectedOption, searchText]
  );

  // Download Excel Functionality

  const downloadStationUnitConsumption = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      if (
        advanceFilterData.charging_station === 'All' &&
        advanceFilterData.state === 'All' &&
        advanceFilterData.city === 'All' &&
        advanceFilterData.access_type === 'All'
      ) {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          state: '',
          city: '',
          access_type: '',
        };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = {
          ...advanceFilterData,
          state: '',
          city: '',
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
              }
            }
          },
        });
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
        excel: true,
        is_emsp_based_booking: false,
      };
      dispatch({
        type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        excel: true,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        is_emsp_based_booking: false,
      };
      dispatch({
        type: DOWNLOAD_STATION_UNIT_CONSUMPTION,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Station Unit Consumption'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadStationUnitConsumption();
  };
  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = (
    <div className="search-box-option">
      <Select options={searchOptions} value={selectedOption} onChange={(value) => setSelectedOption(value)} placeholder="Search Option" />
      <SearchBox
        preIcon={<BsSearch />}
        value={searchText}
        onChange={searchHandler}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
    </div>
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const totalStateData = useSelector((state) => state.state.total);
  const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  const totalCityData = useSelector((state) => state.cityByState.total);
  const ocpidata = useSelector((state) => state.partnerManagement.partnerDetails);
  const ocpiPage = useSelector((state) => state.partnerManagement.page);
  const ocpiTotalPages = useSelector((state) => state.partnerManagement.totalPages);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityByStatePage = useSelector((state) => state.cityByState.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);
  //const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  // const stationList = allStation.filter((x) => userProfileStations.indexOf(x.id) !== -1);
  const allTags = useSelector((state) => state.tagmanagement.tags);

  const stationList =
    userProfileStations && userProfileStations.length > 0
      ? allStationByCity.filter((x) => userProfileStations.indexOf(x.id) !== -1)
      : allStationByCity;


  console.log("??????????????", stationList);

  const initialValues = !_.isEmpty(advanceFilterData)
    ? { ...advanceFilterData }
    : { country: '', state: '', city: '', charging_station: '', access_type: '', tags: '' };

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: { ...data, deleted: true } });
  }, []);

  const getCityByStateName = useCallback((data = {}) => {
    dispatch({ type: GET_CITY_BY_STATE, payload: { ...data, deleted: true } });
  }, []);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      access_type: '',
    };
    dispatch({ type: GET_STATION_BY_CITY, payload: stationData });
  }, []);

  const getTags = useCallback((data = {}) => {
    const tagData = {
      ...data,
    };
    dispatch({ type: FETCH_TAGS, payload: tagData });
  });

  const getOcpidata = useCallback(
    (data) =>
      dispatch({
        type: FETCH_CPO,
        payload: data,
      }),
    []
  );

  useEffect(() => {
    if (showAdvanceFilterPopup) {
      getStateByCountry({ country_name: country, limit: totalStateData });
    }
    if (cityLimit) {
      getCityByStateName({ state_names: state, limit: totalCityData });
    }
    getAllStation();
  }, [country, state, totalStateData, totalCityData, cityLimit, getAllStation]);

  useEffect(() => {
    getAllCountry();
    getAllStation();
    getTags({ limit: 999 });
    getOcpidata({ limit: 999 });
  }, []);

  const allStationType = [
    { label: 'Public', value: 'Public' },
    { label: 'Private', value: 'Private' },
    { label: 'Commercial', value: 'Commercial' },
  ];
  // const [SUCdata, setSUCdata] = useState(stationUnitConsumptionList);
  // const [initiated_by, setInitiated_by] = useState('');

  // useEffect(()=>{
  //   setSUCdata(stationUnitConsumptionList);
  //   // _.map(SUCdata, data=>console.log("mapedData: ", data));
  //   // console.log("normal Data: ", SUCdata);
  //   if(initiated_by==="OTP"){
  //     setSUCdata(_.filter(SUCdata, data=>data.is_rfid_based_booking===false))
  //     console.log("OTP filter Data: ", SUCdata);
  //   }else if(initiated_by==="RFID"){
  //     setSUCdata(_.filter(SUCdata, data=>data.is_rfid_based_booking===true))
  //     console.log("RFID filter Data: ", SUCdata);
  //   }
  // },[stationUnitConsumptionList,initiated_by])

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.stationUnitConsumption')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 4 : 'auto'}`}
                        md={`${showSearchBar ? 12 : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 5 : 'auto'}`} md={`${showSearchBar ? 10 : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment(new Date()).startOf('day')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={2}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={4}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={1} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                      {/* <Col xl={1} md={2}>
                      <div className="ongoing-booking-history-download-icon">
                        <AiOutlineDownload />
                      </div>
                    </Col> */}
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.srNo')}</span>
                              </div>
                            </th>
                            {/* <th>
                            <div className="sorting">
                              <span>Country</span>
                            </div>
                          </th> */}
                            {/* <th>
                            <div className="sorting">
                              <span>State</span>
                            </div>
                          </th> */}
                            {/* <th>
                            <div className="sorting">
                              <span>City</span>
                            </div>
                          </th> */}
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.stationType')}</span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.stationName')}</span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.chargerID')}</span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.otp')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.rfid')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.partyId')}</span>
                              </div>
                            </th>
                            {/* <th>
                            <div className="sorting">
                              <span>OCPP Id</span>
                            </div>
                          </th> */}
                            {/* <th>
                            <div className="sorting">
                              <span>Connector Name</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th> */}
                            {/* <th>
                            <div className="sorting">
                              <span>Charging Date</span>
                            </div>
                          </th> */}
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.unitConsumed')}</span>
                                <span className="ico" onClick={() => handleSorting('meterstop', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.chargingDuration')}</span>
                              </div>
                            </th>
                            {isAllowed('viewVRN') ? (
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.vinNo')}</span>
                                </div>
                              </th>
                            ) : (
                              ''
                            )}
                            {isAllowed('viewVRN') ? (
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.action')}</span>
                                </div>
                              </th>
                            ) : (
                              ''
                            )}
                            {isAllowed('viewVRN') ? (
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addStationUnitConsumption.invoice')}</span>
                                </div>
                              </th>
                            ) : (
                              ''
                            )}

                            {/*<th>*/}
                            {/*  <div className="sorting">*/}
                            {/*    <span>Vehicle Name</span>*/}
                            {/*    /!* <span className="ico">*/}
                            {/*      <TiArrowUnsorted />*/}
                            {/*    </span> *!/*/}
                            {/*  </div>*/}
                            {/*</th>*/}
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(stationUnitConsumptionList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('addStationUnitConsumption.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(stationUnitConsumptionList, (item, index) => {
                              // const connectorData = _.find(_.get(item, 'charger.plugs', []), { connector_id: item.connectorId });
                              const otp = _.get(item, 'is_rfid_based_booking') === false ? _.get(item, 'idTag') : '-';
                              const rfid = _.get(item, 'is_rfid_based_booking') === true ? _.get(item, 'idTag') : '-';
                              const serial_num = limit * (page - 1) + index;
                              const loading = downloading[item.id];
                              function calculateTimeDifference(start, end) {
                                const startTime = moment(start);
                                const stopTime = moment(end);

                                const timeDiffInMS = stopTime - startTime;

                                const seconds = moment.duration(timeDiffInMS).seconds();
                                const minutes = moment.duration(timeDiffInMS).minutes();
                                const hours = Math.trunc(moment.duration(timeDiffInMS).asHours());

                                if (Math.sign(timeDiffInMS) === -1) {
                                  return `-${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`;
                                } else {
                                  return `${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`;
                                }
                              }
                              return (
                                <>
                                  <tr key={`station-unit-consumption-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    {/* <td>{_.get(item, 'charger.charging_station.country', '-')}</td> */}
                                    {/* <td>{_.get(item, 'charger.charging_station.state', '-')}</td> */}
                                    {/* <td>{_.get(item, 'charger.charging_station.city', '-')}</td> */}
                                    <td>
                                      {isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.access_type', '')}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charger_id', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : otp}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : rfid}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiCredential.partyId', '-')}</td>
                                    {/* <td>{_.get(item, 'charger.charger_id', '-')}</td> */}
                                    {/* <td>{_.get(connectorData, 'name', '-')}</td> */}
                                    {/* <td>{item.schedule_datetime ? moment(item.schedule_datetime).format('DD/MM/YYYY') : '-'}</td> */}
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <>
                                          {isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart') / 1000)
                                            ? ''
                                            : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3)}
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <>{calculateTimeDifference(item.booking_start, item.booking_stop)}</>
                                      )}
                                    </td>
                                    {isAllowed('viewVRN') ? <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'vrn', '-')}</td> : ''}
                                    {isAllowed('viewVRN') ? (
                                      <td>
                                        <div onClick={() => handlevinclick(_.get(item, 'id'))}>
                                          <Button>{isLoader ? <Skeleton height={15} width={100} /> : 'Update VIN'}</Button>
                                        </div>
                                      </td>
                                    ) : (
                                      ''
                                    )}
                                    {isAllowed('viewVRN') ? (
                                      <td>
                                        {loading ? (
                                          <div className="loader--block">
                                            <Spinner as="span" animation="border" size="sm" role="status" />
                                            <span className="ps-2">{t('placeHolder.loading')}</span>
                                          </div>
                                        ) : (
                                          <div onClick={() => downloadChargeRct(item)}>
                                            <Button>{t('button.download')}</Button>
                                          </div>
                                        )}
                                      </td>
                                    ) : (
                                      ''
                                    )}
                                    {/*<td>{_.get(item, 'vehicle.model', '-')}</td>*/}
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(stationUnitConsumptionList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}
      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              const {
                country,
                state,
                city,
                charging_station,
                access_type,
                booking_initiated_by,
                partyId,
                tags,
                ...restValues
              } = values;

              const filteredValues = {
                ...restValues,
                ...(country && { country }),
                ...(state && { state }),
                ...(city && { city }),
                ...(charging_station && { charging_station }),
                ...(access_type && { access_type }),
                ...(booking_initiated_by && { booking_initiated_by }),
                ...(partyId && { partyId }),
                ...(tags && { tags }),
              };

              let stationUnitConsumptionData = { ...filteredValues };

              if (values.charging_station === 'All' && values.state === 'All' && values.city === 'All' && values.access_type === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  state: '',
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  access_type: '',
                };
              } else if (values.state === 'All' && values.charging_station === 'All' && values.access_type === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  state: '',
                  access_type: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.state === 'All' && values.city === 'All' && values.access_type === 'All'){
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  city: '',
                  state: '',
                  access_type: '',
                };

              } else if (values.city === 'All' && values.charging_station === 'All' && values.access_type === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  city: '',
                  access_type: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.city === 'All' && values.charging_station === 'All' && values.state === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  city: '',
                  state: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.state === 'All' && values.city === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  state: '',
                  city: '',
                };
              } else if (values.city === 'All' && values.access_type === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  access_type: '',
                  city: '',
                };
              } else if (values.charging_station === 'All' && values.access_type === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  access_type: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.state === 'All' && values.charging_station === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  state: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.city === 'All' && values.charging_station === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.city === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  city: '',
                };
              } else if (values.state === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  state: '',
                };
              } else if (values.charging_station === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.access_type === 'All') {
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  access_type: '',
                };
              } else if (values.booking_initiated_by !== 'ocpi'){
                stationUnitConsumptionData = {
                  ...stationUnitConsumptionData,
                  partyId: '',
                };
              }

              stationUnitConsumption(stationUnitConsumptionData);
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.country')}
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectCountry')}
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val, limit: 999 });
                      setCountry(val);
                      setFieldValue(`country`, val);
                      setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.state')}
                    options={
                      !changeState
                        ? values.country &&
                        !_.isEmpty(allState) && [
                          { label: 'All', value: 'All' },
                          ..._.map(allState, (state) => {
                            return { label: state.name, value: state.name };
                          }),
                        ]
                        : values.country && !_.isEmpty(allState) && [{ label: 'All', value: 'All' }]
                    }
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectState')}
                    isMulti
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getCityByStateName({ state_names: '' });
                        setChangeState(true);
                        setFieldValue(`state`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeState(false);
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`state`, '');
                      } else {
                        getCityByStateName({ state_names: val });
                        setState(val);
                        setCityLimit(true);
                        setChangeState(false);
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`state`, val);
                      }
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.city')}
                    options={
                      !changeCity
                        ? !_.isEmpty(values.state) &&
                        !_.isEmpty(allCityByState) && [
                          { label: 'All', value: 'All' },
                          ..._.map(allCityByState, (state) => {
                            return { label: state.name, value: state.name };
                          }),
                        ]
                        : !_.isEmpty(values.state) && values.country && !_.isEmpty(allCityByState) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder={t('placeHolder.selectCity')}
                    name="city"
                    isMulti
                    value={values.city}
                    onMenuScrollDown={true}
                    page={cityByStatePage}
                    totalPage={cityByStateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getAllStation({ city_array: '' });
                        setChangeCity(true);
                        setFieldValue(`city`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`city`, '');
                      } else {
                        getAllStation({ city_array: val });
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue('city', val);
                      }
                      setFieldValue(`charging_station`, '');
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.station')}
                      options={
                        !changeStation
                          ? (!_.isEmpty(_.get(values, 'city')) && !_.isEmpty(stationList) && _.get(values, 'state') === 'All') ||
                            _.get(stationList, 'city') === _.get(values, 'city')
                            ? [
                              ..._.map(stationList, (station) => {
                                return { label: station?.name, value: station?.id };
                              }),
                            ]
                            : [
                              ..._.map(
                                stationList?.filter((o) => o.state === values.state[0]),
                                (station) => {
                                  return { label: station?.name, value: station?.id };
                                }
                              ),
                            ]
                          : !_.isEmpty(_.get(values, 'city')) &&
                          !_.isEmpty(_.get(values, 'state')) &&
                          values.country &&
                          !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectStation')}
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setChangeStation(false);
                          setFieldValue(`charging_station`, '');
                        } else {
                          setChangeStation(false);
                          setFieldValue(`charging_station`, val);
                        }
                        setFieldValue('charger_id', ''); // Reset charger_id when charging_station changes
                      }}
                    />
                    <Select
                      label={t('Charger Id')}
                      options={
                        !_.isEmpty(stationList)
                          ? !changeStation && values.charging_station && values.charging_station !== 'All'
                            ? _.flatMap(
                              stationList.filter(
                                (station) => values.charging_station.includes(station.id)
                              ),
                              (station) =>
                                station.chargers && station.chargers.length > 0
                                  ? station.chargers
                                    .filter((charger) => !charger.is_deleted)
                                    .map((charger) => ({
                                      label: charger.charger_id,
                                      value: charger.id,
                                    }))
                                  : []
                            )
                            : []
                          : !changeStation && values.charging_station && values.charging_station !== 'All'
                            ? _.flatMap(
                              stationList.filter(
                                (station) => values.charging_station.includes(station.id)
                              ),
                              (station) =>
                                station.chargers && station.chargers.length > 0
                                  ? station.chargers
                                    .filter((charger) => !charger.is_deleted)
                                    .map((charger) => ({
                                      label: charger.charger_id,
                                      value: charger.id,
                                    }))
                                  : []
                            )
                            : []
                      }
                      placeholder={t('Charger Id')}
                      name="charger_id"
                      isMulti
                      value={values.charger_id}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.isEmpty(val)) {
                          setFieldValue(`charger_id`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charger_id', val);
                        }
                      }}
                    />
                    <Select
                      label={t('filters.stationType')}
                      options={
                        !changeStationType
                          ? !_.isEmpty(allStationType) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allStationType, (item) => {
                              return { label: item.label, value: item.value };
                            }),
                          ]
                          : !_.isEmpty(allStationType) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectStationType')}
                      name="access_type"
                      isMulti
                      value={values.access_type}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStationType(true);
                          setFieldValue(`access_type`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`access_type`, '');
                          setChangeStationType(false);
                        } else {
                          setChangeStationType(false);
                          setFieldValue('access_type', val);
                        }
                      }}
                    />
                    <Select
                      label={t('filters.tags')}
                      isMulti
                      options={_.map(allTags, (item) => {
                        return { label: item.name, value: item.id };
                      })}
                      className="filter-select-box"
                      placeholder={t('placeHolder.selectTags')}
                      name="tags"
                      value={values.tags}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getTags}
                      onChange={(val) => {
                        setFieldValue(`tags`, val);
                      }}
                    />
                    <Select
                      label={t('filters.initiatedBy')}
                      options={[
                        { label: 'OTP', value: 'otp' },
                        { label: 'RFID', value: 'rfid' },
                        { label: 'Auto Charge', value: 'autocharge' },
                        { label: 'OCPI', value: 'ocpi' },
                      ]}
                      placeholder={t('placeHolder.selectAnyone')}
                      name="booking_initiated_by"
                      value={values.booking_initiated_by}
                      onChange={(val) => {
                        setFieldValue('booking_initiated_by', val);
                      }}
                    />
                    {values.booking_initiated_by === 'ocpi' ? (
                      <Select
                        label={t('filters.ocpiParties')}
                        options={[
                          ..._.map(ocpidata, (data) => {
                            return { label: data.partyId, value: data.partyId };
                          }),
                        ]}
                        placeholder={t('filters.selectParty')}
                        name="partyId"
                        onMenuScrollDown={true}
                        getDataOnScrollDown={getOcpidata}
                        page={ocpiPage}
                        totalPage={ocpiTotalPages}
                        value={values.partyId}
                        onChange={(val) => {
                          setFieldValue('partyId', val);
                        }}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      stationUnitConsumption();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
      {showForm && (
        <Modal show={showForm} size="lg" centered>
          <VinForm onClose={() => setShowForm(false)} bookingID={bookingID} onSuccess={stationUnitConsumption} />
        </Modal>
      )}
    </>
  );
};

export default StationUnitConsumption;
