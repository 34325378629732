export const SUPER_ADMIN = 'super_admin';
export const TENANT_ADMIN = 'tenant_admin';
export const ADMIN = 'admin';
export const CUSTOMER = 'customer';
export const CPO = 'cpo';
export const FINANCE = 'finance';
export const DOCO = 'doco';
export const SUPPORT = 'support';
export const B2BCLIENT = 'b2bclient';
export const GCM_CPO = 'gcmcpo';
export const MNM_DEALER = 'mnm_dealer';
export const SAAS_ADMIN = 'saas_admin';
export const MD_ADMIN = 'md_admin';
export const MD_DEALER = 'md_dealer';
export const SUPPORT_ADMIN = 'support_admin';
export const NETWORK_ADMIN = 'network_admin';
export const ZMT_ADMIN = 'ZMT_admin';
export const FINANCE_ADMIN = 'finance_admin';
export const FLEET_OWNER = 'fleet_owner';
export const FLEET_MEMBER = 'fleet_member';
export const GENTARI_CUSTOM_ROLE = 'gentari_custom_role';
export const GET_ADD_ONS = 'getAddOns';
export const MANAGE_ADD_ONS = 'manageAddOns';
export const GET_CHARGERS = 'getChargers';
export const MANAGE_CHARGERS = 'manageChargers';
export const GET_CHARGING_STATIONS = 'getChargingStations';
export const MANAGE_CHARGING_STATIONS = 'manageChargingStations';
export const GET_CHARGER_STATUS = 'getChargerStatus';
export const GET_RESERVE_SLOTS = 'getReserveSlots';
export const GET_SCHEDULING_SETTINGS = 'getSchedulingSettings';
export const GET_PUBLIC_USERS = 'getPublicUsers';
export const GET_STATIONS = 'getStations';
export const MANAGE_STATIONS = 'manageStations';
export const GET_RATING = 'getRating';
export const MANAGE_RATING = 'manageRating';
export const GET_TARRIFS = 'getTarrifs';
export const MANAGE_TARRIFS = 'manageTarrifs';
export const GET_FAULTY_BOOKINGS = 'getFaultyBookings';
export const GET_CUSTOMERS = 'getCustomers';
export const MANAGE_CUSTOMERS = 'manageCustomers';
export const SHOW_CUSTOMER_WIDGET = 'showCustomerWidget';
export const GET_INVOICES = 'getInvoices';
export const GET_LIVE_CHARGING_SESSIONS = 'getLiveChargingSessions';
export const MANAGE_LIVE_CHARGING_SESSIONS = 'manageLiveChargingSessions';
export const MANAGE_INVOICES = 'manageInvoices';
export const GET_USER_GROUPS = 'getUserGroups';
export const GET_BILLING_WIDGETS = 'getBillingWidgets';
export const MANAGE_USER_GROUPS = 'manageUserGroups';
export const GET_VEHICLES = 'getVehicles';
export const MANAGE_VEHICLES = 'manageVehicles';
export const GET_REPORTS = 'getReports';
export const MANAGE_REPORTS = 'manageReports';
export const GET_REPORT_STATION_UNIT_CONSUMPTION = 'getReportStationUnitConsumption';
export const GET_OCPI_PARTNER_CHARGING_REPORT = 'getOCPIPartnerChargingReport';
export const GET_OCPI_PARTNER_GROUPS = 'getOcpiPartnerGroups';
export const GET_ADD_PARTNER_GROUP = 'getAddPartnerGroup';
export const GET_ADD_PARTY_ID = 'getAddPartyId';
export const GET_SELECT_PARTY_ID = 'getSelectPartyId';
export const MANAGE_OCPI_PARTNER_GROUPS = 'manageOcpiPartnerGroups';
export const GET_REPORT_TRANSACTION = 'getReportTransaction';
export const GET_REPORT_CHARGING_SESSION = 'getReportChargingSession';
export const GET_CAPACITY_UTIL_REPORT = 'getCapacityUtilReport';
export const GET_REPORT_CHARGING_HISTORY = 'getReportChargingHistory';
export const GET_REPORT_B2B_HISTORY = 'getReportB2BHistory';
export const GET_REPORT_B2C_HISTORY = 'getReportB2CHistory';
export const GET_REPORT_WALLET_TRANSACTION = 'getReportWalletTransaction';
export const GET_ALL_DATA_REPORT = 'getalldatareport';
export const GET_HMIL_CUSTOM_CHARGING_REPORT = ' getHMILCustomChargingReport';
export const GET_ELECTRICITY_BILL = 'getElectricityBillDetails';
export const MANAGE_ELECTRICITY_BILL = 'manageElectricityBillDetails';
export const GET_REPORT_APP_USER_DETAILS = 'getReportAppUserDetails';
export const GET_REPORT_MOST_VEHICLE_CHARGED = 'getReportMostVehicleCharged';
export const GET_REPORT_BOOKING_HISTORY = 'getReportBookingHistory';
export const GET_REPORT_USER_CHARGING_HISTORY = 'getReportUserChargingHistory';
export const GET_REPORT_BOOKING_SUMMARY_REPORT = 'getReportBookingSummaryReport';
export const GET_REPORT_MONEY_TRANSACTION_PG = 'getReportMoneyTransactionPG';
export const GET_REPORT_STATION_CHARGING_SUMMARY = 'getReportStationChargingSummary';
export const GET_REPORT_PUBLIC_STATION_CHARGING_SUMMARY = 'getReportPublicStationChargingSummary';
export const GET_REPORT_PRIVATE_STATION_CHARGING_SUMMARY = 'getReportPrivateStationChargingSummary';
export const GET_REPORT_STATION_DOWNTIME_REPORT = 'getReportStationDowntimeReport';
export const GET_AC_METER_READING_REPORT = 'getACMeterReadingReport';
export const GET_SMC_REPORTS = 'getSMCReports';
export const MANAGE_NO_LOAD_REPORT = 'manageNoLoadReport';
export const GET_SCHEDULE = 'getSchedule';
export const MANAGE_SCHEDULE = 'manageSchedule';
export const GET_RFIDS = 'getRFIDs';
export const MANAGE_RFIDS = 'manageRFIDs';
export const MANAGE_RFID_REQUESTS = 'manageRFIDRequests';
export const GET_USER_ACTIVITYS = 'getUserActivitys';
export const MANAGE_USER_ACTIVITYS = 'manageUserActivitys';
export const GET_NOTIFICATIONS = 'getNotifications';
export const MANAGE_NOTIFICATIONS = 'manageNotifications';
export const GET_OFFERS = 'getOffers';
export const MANAGE_OFFERS = 'manageOffers';
export const GET_PARTNER = 'getPartner';
export const MANAGE_PARTNER = 'managePartner';
export const GET_CHARGE_COINS = 'getChargeCoins';
export const MANAGE_CHARGE_COINS = 'manageChargeCoins';
export const UPDATE_APP_SETTINGS = 'updateAppSettings';
export const GET_GCM_REPORT = 'getGCMReport';
export const GET_HEATMAP_REPORT = 'getHeatmapReport';
export const GET_UPDATE_NEGATIVE_METER_VALUE = 'getUpdateNegativeMeterValue';
export const GET_ADMINISTARTION = 'getAdministartion';
export const GET_TAGS = 'getTags';
export const GET_INVOICE_PERMISSION_REPORT = 'getInvoicePermissionReport';
export const GET_VEHICLE_CHARGING_SESSION = 'getVehicleChargingSession';
export const GET_FLEET_MEMBERS = 'getFleetMembers';
export const MANAGE_FLEET_MEMBERS = 'manageFleetMembers';
export const GET_TENANAT_SETTINGS = 'getTenantSettings';
export const GET_OCPI = 'getOCPI';
export const MANAGE_OCPI = 'manageOCPI';
export const GET_CHARGER_LOGS = 'getChargerLogs';
export const GET_SERVER_LOGS = 'getServerLogs';
export const GET_SEQUENCE_LOGS = 'getSequenceLogs';
export const GET_CHARGER_BOOKINGS = 'getChargerBookings';
export const GET_MY_BOOKINGS = 'getMyBookings';
export const GET_WALLETS = 'getWallets';
export const GET_CHARGER_DOWNTIMES = 'getChargerDowntimes';
export const GET_PAYMENTS = 'getPayments';
export const GET_MQTT_LOGGER = 'getMqttLogger';
export const GET_MASTER_SETTINGS = 'getMasterSettings';
export const MANAGE_USERS = 'manageUsers';
export const GET_RFID_GROUPS = 'getRfidGroups';
export const GET_RFID_REQUESTS = 'getRfidRequests';
export const UPDATE_MY_PROFILE = 'updateMyProfile';
export const GET_CHARGER_STATES = 'getChargerStates';
export const STOP_TRANSACTION = 'stopTransaction';
export const MANAGE_COMPANIES = 'manageCompanies';
export const GET_COUNTRIES = 'getCountries';
export const GET_STATES = 'getStates';
export const GET_CITYS = 'getCitys';
export const GET_AREAS = 'getAreas';
export const GET_EV_MASTER_DATAS = 'getEvmasterDatas';
export const GET_EV_CATALOGS = 'getEvcatalogs';
export const GET_AMENITIES = 'getAmenities';
export const GET_ELECTRICITY_SUPPLIERS = 'getElectricitySuppliers';
export const GET_CS_ACCESS_TYPES = 'getCsAccessTypes';
export const GET_OEM_VENDORS = 'getOemVendors';
export const MANAGE_FAQS = 'manageFaqs';
export const MANAGE_PRIVACYS = 'managePrivacys';
export const GET_CONNECTOR_TYPES = 'getConnectorTypes';
export const MANAGE_CHARGE_SPEEDS = 'manageChargeSpeeds';
export const GET_OEMS = 'getOems';
export const GET_OWNERSHIP_TYPE='getOwnerShipTypes';
export const GET_ROLES = 'getRoles';
export const MANAGE_ROLES = 'manageRoles';
export const MANAGE_MODULES = 'manageModules';
export const MANAGE_PERMISSIONS = 'managePermissions';
export const MANAGE_OEM_CODES = 'manageOemCodes';
export const MANAGE_OEM_VENDORS = 'manageOemVendors';
export const MANAGE_CHARGER_STATUS = 'manageChargerStatus';
export const SMS_TEMPLATES = 'smsTemplates';
export const EMAIL_TEMPLATES = 'emailTemplates';
export const MANAGE_MASTER_SETTINGS = 'manageMasterSettings';
export const MANAGE_RESERVE_SLOTS = 'manageReserveSlots';
export const MANAGE_TENANT_SETTINGS = 'manageTenantSettings';
export const GET_BULK_EINVOICE_DOWNLOAD = 'getBulkEinvoiceDownload';
export const GET_STATION_CHARGER_OPERATIONS = 'getStationChargerOperations';
export const MANAGE_STATION_CHARGER_OPERATIONS = 'manageStationChargerOperations';
export const GET_STATION_CHARGERS = 'getStationChargers';
export const MANAGE_STATION_CHARGERS = 'manageStationChargers';
export const GET_STATION_CHARGER_INFORMATION = 'getStationChargerInformation';
export const MANAGE_STATION_CHARGER_INFORMATION = 'manageStationChargerInformation';
export const MANAGE_CHARGER_STATUS_FILTER = 'manageChargerStatusFilter';
export const GET_ADD_VRN = 'getAddVRN';
export const MANAGE_CUSTOMERS_BOOKING = 'manageCustomerBooking';
export const GET_CHARGECOIN_TRANSACTION = 'getchargeCoinTransaction';
export const GET_WIDGET_SESSIONS_TODAY = 'getWidgetSessionsToday';
export const GET_WIDGET_TOTAL_REVENUE = 'getWidgetTotalRevenue';
export const GET_WIDGET_CHARGERS_IN_USE = 'getWidgetChargersInUse';
export const GET_WIDGET_CHARGER_UNITS_FOR_TODAY = 'getWidgetChargerUnitsForToday';
export const GET_WIDGET_REALTIME_EVENTS = 'getWidgetRealtimeEvents';
export const GET_WIDGET_TYPE_OF_CHARGER = 'getWidgetTypeOfCharger';
export const GET_WIDGET_ENERGY_SOLD = 'getWidgetEnergySold';
export const GET_WIDGET_CHARGER_STATUS = 'getWidgetChargerStatus';
export const GET_CHARGER_SPOT_WISE_WIDGETS = 'getChargerSpotWiseWidgets';
export const GET_WIDGET_BOOKING_COUNT = 'getWidgetBookingCount';
export const GET_WIDGET_RECENT_CHARGER_BOOKING = 'getWidgetRecentChargerBooking';
export const GET_WIDGET_OEM_BREAKDOWN = 'getWidgetOEMBreakdown';
export const GET_WIDGET_NUMBER_OF_TOTAL_USERS = 'getWidgetNumberofTotalUsers';
export const GET_WIDGET_TYPE_OF_EV = 'getWidgetTypeOfEV';
export const GET_CHANGE_PASSWORD = 'getChangePassword';
export const MANAGE_CHANGE_PASSWORD = 'manageChangePassword';
export const MANAGE_MY_PROFILE = 'manageMyProfile';
export const GET_STATION_RESERVATION = 'getStationReservation';
export const MANAGE_STATION_RESERVATION = 'manageStationReservation';
export const GET_STATION_GENERAL = 'getStationGeneral';
export const MANAGE_STATION_GENERAL = 'manageStationGeneral';
export const GET_STATION_CONSUMPTION = 'getStationConsumption';
export const MANAGE_STATION_CONSUMPTION = 'manageStationConsumption';
export const GET_STATION_REVIEWS = 'getStationReviews';
export const MANAGE_STATION_REVIEWS = 'manageStationReviews';
export const UPDATE_ROLE_PASSWORD = 'updateRoleAndPassword';
export const USER_DELETED_REQUEST = 'getUserDeletedRequest';
export const GET_PLANT_LOAD_FACTOR = 'getPlantLoadFactor';
export const GET_VOUCHER = 'getVoucher';
export const MANAGE_VOUCHER = 'manageVoucher';
export const GET_COUPONS = 'getCoupons';
export const MANAGE_COUPONS = 'manageCoupons';
export const GET_PARTNER_CHARGING_SUMMARY = 'getReportPartnerStationChargingSummary';
export const SIDEBARMENU_DATA = {
  CHARGER_STATUS: 'Charger Status',
  STATIONS_AND_CHARGERS: 'Stations and Chargers',
  RATING_AND_REVIEW: 'Rating and Review',
  TARRIF_MANAGEMENT: 'Tarrif Management',
  CUSTOMER_SUPPORT: 'Customer Support',
  BILLING_AND_INVOICING: 'Billing and Invoicing',
  LIVE_CHARGING_SESSIONS: 'Live Charging sessions',
  VEHICLE_MANAGEMENT: 'Vehicle Management',
  ADMINISTRATION: 'Administration',
  REPORTS: 'Reports',
  SHEDULING: 'Sheduling',
  RFID: 'RFID',
  JOB_VOUCHER: 'Job Voucher',
  AL_REPORT: 'AL Report',
  ACTIVITY_LOGS: 'Activity Logs',
  NOTIFICATION: 'Notification',
  OFFERS_MANAGEMENT: 'Offer Management',
  FLEET_MANAGEMENT: 'Fleet Management',
  PARTNER_MANAGEMENT: 'Partner Management',
  CHARGECOIN_MANAGEMENT: 'Chargecoin Management',
  GCM_OLD_REPORT: 'GCM Old Report',
  UPDATE_APP_SETTINGS: 'Update App Settings',
};

export const CREATE_STATUS_CODE = 201;
export const UPDATE_STATUS_CODE = 200;
export const DELETE_STATUS_CODE = 204;
export const METHOD_NOT_ALLOWED = 405;
export const AUTO_CHARGE = 'AUTO CHARGE';
export const APP = 'APP';
