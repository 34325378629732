import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
import { FaFilter } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/inputs/Select';
import { FETCH_COUNTRY, FETCH_STATE, GET_CITY_BY_STATE } from 'actions/address';
import { useParams } from 'react-router';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { FETCH_TAGS } from 'actions/tagmanagement';
import { useTranslation } from 'react-i18next';
import { GET_CPO, ADD_LOCATION } from 'actions/partnerManagement';
import { SkeletonTheme } from 'react-loading-skeleton';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import OffCanvas from 'components/inputs/OffCanvas';
import { MdClear } from 'react-icons/md';
import CheckBox from 'components/inputs/CheckBox';
import { toast } from 'react-toastify';
const StationAccessForm = () => {
  const { navigateTo } = useContext(NavContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ocpiId } = useParams();
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const [selectAll, setSelectAll] = useState(false);
  const [stationSelection, setStationSelection] = useState({});
  const [isIncluded, setIsIncluded] = useState(false);
  const [stations, setStations] = useState([]);
  // const isLoader = useSelector((state) => state.user.isLoading);
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  const allFilterStations = useSelector((state) => state.stationByCity.stationByCities);
  const allTagManagement = useSelector((state) => state.tagmanagement.tags);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const cityByStatePage = useSelector((state) => state.cityByState.page);
  const userDetail = useSelector((state) => state.partnerManagement.partnerInfo);

  // const allStation = _.map(allFilterStations, (item) => item.id);
  // const allCharger = allFilterStations.flatMap((item) => item.chargers.map((charger) => charger.id));

  const userProfileIncludedStations = userDetail?.included_charging_stations?.map((station) => station.id);
  const userProfileIncludedChargers = userDetail?.included_chargers?.map((charger) => charger.id);

  const userProfileExcludedStations = userDetail?.excluded_charging_stations?.map((station) => station.id);
  const userProfileExcludedChargers = userDetail?.excluded_chargers?.map((charger) => charger.id);

  const includedStationList = !_.isEmpty(userProfileIncludedStations)
    ? allFilterStations.filter((x) => userProfileIncludedStations.indexOf(x.id) !== -1)
    : [];
  const includedChargerList = !_.isEmpty(userProfileIncludedChargers)
    ? allFilterStations.flatMap((item) => item.chargers.map((charger) => charger)).filter((x) => userProfileIncludedChargers.indexOf(x.id) !== -1)
    : [];

  const excludedStationList = !_.isEmpty(userProfileExcludedStations)
    ? allFilterStations.filter((x) => userProfileExcludedStations.indexOf(x.id) !== -1)
    : [];
  const excludedChargerList = !_.isEmpty(userProfileExcludedChargers)
    ? allFilterStations.flatMap((item) => item.chargers.map((charger) => charger)).filter((x) => userProfileExcludedChargers.indexOf(x.id) !== -1)
    : [];

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByStateName = useCallback((data = {}) => {
    dispatch({ type: GET_CITY_BY_STATE, payload: data });
  }, []);

  const getStations = useCallback((data = {}) => {
    const stationData = {
      ...data,
      access_type: 'Public',
      is_deleted: false,
      is_external_station: false,
    };
    dispatch({ type: GET_STATION_BY_CITY, payload: stationData });
  }, []);

  const getTags = useCallback((data = {}) => {
    dispatch({ type: FETCH_TAGS, payload: data });
  });

  const getPartner = useCallback((id) => {
    dispatch({ type: GET_CPO, payload: id });
  });

  useEffect(() => {
    getAllCountry();
    getPartner(ocpiId);
    getTags({ limit: 999 });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(advanceFilterData)) {
      const data = {
        state_array: advanceFilterData.state,
        city_array: advanceFilterData.city,
      };
      getStations(data);
    } else {
      getStations();
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(stations)) {
      setStationSelection({});
      setSelectAll(false);
    }
  }, [stations, advanceFilterData]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const updatedSelection = {};
    stations.forEach((station) => {
      const selectedStations = allFilterStations.find((e) => e.id === station);
      updatedSelection[selectedStations.id] = {
        stationChecked: newSelectAll,
        chargers: _.reduce(
          selectedStations.chargers.filter((item) => !item.is_deleted),
          (acc, charger) => {
            acc[charger.id] = newSelectAll;
            return acc;
          },
          {}
        ),
      };
    });
    setStationSelection(updatedSelection);
  };

  const handleStationSelect = (stationId) => {
    const stationChecked = !stationSelection[stationId]?.stationChecked;

    setStationSelection((prev) => {
      const updatedSelection = {
        ...prev,
        [stationId]: {
          stationChecked,
          chargers: allFilterStations
            .find((station) => station.id === stationId)
            .chargers.filter((item) => !item.is_deleted)
            .reduce((acc, charger) => {
              acc[charger.id] = stationChecked;
              return acc;
            }, {}),
        },
      };
      updateSelectAll(updatedSelection);
      return updatedSelection;
    });
  };

  const handleChargerSelect = (stationId, chargerId) => {
    const station = allFilterStations.find((station) => station.id === stationId);
    if (!station) return;

    const stationChargers = stationSelection[stationId]?.chargers || {};
    const chargerChecked = !stationChargers[chargerId];
    const updatedChargers = { ...stationChargers, [chargerId]: chargerChecked };
    const allChargersSelected = station.chargers.filter((charger) => !charger.is_deleted).every((charger) => updatedChargers[charger.id]);
    const updatedSelection = {
      ...stationSelection,
      [stationId]: {
        stationChecked: allChargersSelected,
        chargers: updatedChargers,
      },
    };

    setStationSelection(updatedSelection);
    updateSelectAll(updatedSelection);
  };

  const updateSelectAll = (updatedSelection) => {
    const filteredStations = allFilterStations.filter((station) => stations.includes(station.id));
    const allChecked = filteredStations?.every((station) => {
      const stationSelection = updatedSelection[station.id];
      return (
        stationSelection?.stationChecked === true &&
        Object.values(stationSelection.chargers)
          .filter((item) => !item.is_deleted)
          .every((checked) => checked)
      );
    });
    setSelectAll(allChecked);
  };

  const updateUser = useCallback(
    (data) => {
      const ocpiData = {
        ...data,
        id: ocpiId,
      };
      dispatch({
        type: ADD_LOCATION,
        payload: ocpiData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('Items are successfully added');
            setTimeout(() => {
              navigateTo(`/stationAccessToPartner/${ocpiId}`);
            }, 1000);
          }
        },
      });
    },
    [ocpiId]
  );

  const onCloseAdvanceFilterPopup = () => {
    setShowAdvanceFilterPopup(false);
    setStationSelection({});
    setSelectAll(false);
  };

  const initialValues = {
    charging_stations: '',
    charger: '',
  };

  const initialValue = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { country: '', state: '', city: '', tags: '' };

  const handleSubmit = ({ setSubmitting }) => {
    const includedChargingStations = Object.keys(stationSelection).filter((stationId) => stationSelection[stationId]?.stationChecked);

    const selectedChargers = _.flatMap(
      Object.values(stationSelection).map((selection) => Object.keys(selection.chargers || {}).filter((chargerId) => selection.chargers[chargerId]))
    );

    if (includedChargingStations.length === 0 && selectedChargers.length === 0) {
      toast.error('Please select any item to add.');
      setSubmitting(false);
      return;
    }

    const stationsWithChargers = new Set();
    const filteredStations = allFilterStations.filter((station) => stations.includes(station.id));

    filteredStations.forEach((station) => {
      const stationChargers = Object.keys(stationSelection[station.id]?.chargers || {}).filter(
        (chargerId) => stationSelection[station.id]?.chargers[chargerId]
      );
      stationChargers.forEach((chargerId) => {
        if (selectedChargers.includes(chargerId) && stationSelection[station.id]?.chargers[chargerId]) {
          stationsWithChargers.add(station.id);
        }
      });
    });

    const stationsWithChargersArray = Array.from(stationsWithChargers);
    const combinedChargingStations = _.uniq([...includedChargingStations, ...stationsWithChargersArray]);

    let payload;
    if (isIncluded) {
      payload = {
        included_charging_stations: _.uniq(
          _.concat(
            _.map(includedStationList, (item) => item.id),
            combinedChargingStations
          )
        ),
        included_chargers: _.uniq(
          _.concat(
            _.map(includedChargerList, (item) => item.id),
            selectedChargers
          )
        ),
      };
    } else {
      payload = {
        excluded_charging_stations: _.uniq(
          _.concat(
            _.map(excludedStationList, (item) => item.id),
            combinedChargingStations
          )
        ),
        excluded_chargers: _.uniq(
          _.concat(
            _.map(excludedChargerList, (item) => item.id),
            selectedChargers
          )
        ),
      };
    }

    updateUser(payload);
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.stationAccessToPatner')} backTo={`/stationAccessToPartner/${ocpiId}`} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="station-wise-filter_page__main">
              <Card className="station-wise-filter_page__main--card">
                <Row>
                  <Col lg={10} md={'auto'}>
                    <div className="report-search-excel-block">
                      <div className="advance-filter-btn">
                        <span>{t('header.stationAccessToPatner')}</span>
                      </div>
                    </div>
                  </Col>
                  <Col lg={2} md={'auto'}>
                    <div className="report-search-excel-block">
                      <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                        <Button className="report-search-button" style={{ padding: '1rem 5rem' }}>
                          <FaFilter className="hide-lap" />
                          <span>{t('button.advancedFilter')}</span>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                  {({ values, handleSubmit, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="mb-5">
                        <Row>
                          <Col lg={6} md={12}>
                            <div
                              className="select-all-checkbox-input__block"
                              style={{
                                justifyContent: 'flex-start',
                                marginBottom: '10px',
                              }}
                            >
                              <label className="select-all--label">{t('stationAccessToPatner.stationLabel')}</label>
                            </div>
                            <Select
                              options={
                                !_.isEmpty(allFilterStations) && [
                                  ..._.map(allFilterStations, (state) => {
                                    return { label: state.name, value: state.id };
                                  }),
                                ]
                              }
                              placeholder={t('stationAccessToPatner.stationLabel')}
                              name="charging_stations"
                              isMulti
                              value={values.charging_stations}
                              onMenuScrollDown={true}
                              onChange={(val) => {
                                setFieldValue(`charging_stations`, val);
                                setStations(val.map((s) => s));
                                setSelectAll(false);
                              }}
                            />
                          </Col>
                          <Col lg={12} md={12}>
                            {!_.isEmpty(allFilterStations) && !_.isEmpty(stations) && values.charging_stations ? (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div className="select-all-checkbox-input__block">
                                    <label className="select-all--label">{t('stationAccessToPatner.chargerLabel')}</label>
                                  </div>
                                  <div className="select-all-checkbox-input__block">
                                    <label className="select-all--label">{t('stationAccessToPatner.selectAll')}</label>
                                    <CheckBox checked={selectAll} onChange={handleSelectAll} />
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                            <div className="station-access-list__main">
                              <div className="station-access-list--block">
                                {!_.isEmpty(allFilterStations) && values.charging_stations
                                  ? _.flatMap(
                                      allFilterStations.filter((station) => values.charging_stations.includes(station.id)),
                                      (station) => (
                                        <ChargerDataItem
                                          key={station.id}
                                          station={station}
                                          stationChecked={stationSelection[station.id]?.stationChecked || false}
                                          chargers={stationSelection[station.id]?.chargers || {}}
                                          onStationSelect={() => handleStationSelect(station.id)}
                                          onChargerSelect={(chargerId) => handleChargerSelect(station.id, chargerId)}
                                        />
                                      )
                                    )
                                  : []}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div className="filter-btn__block">
                          <span>
                            <Button type="submit" className="filter_btn" onClick={() => setIsIncluded(true)}>
                              {t('stationAccessToPatner.saveInclude')}
                            </Button>
                          </span>
                        </div>
                        <div className="filter-btn__block" style={{ marginLeft: '10px' }}>
                          <span>
                            <Button type="submit" className="filter_btn" onClick={() => setIsIncluded(false)}>
                              {t('stationAccessToPatner.saveExclude')}
                            </Button>
                          </span>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
      </LayoutContainer>

      {/* Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValue}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              const stationData = {
                ...values,
                city_array: values.city,
                state_array: values.state,
              };
              const stationValues = _.omit(stationData, ['state', 'city', 'country']);
              getStations(stationValues);
              handleAdvanceFilterData(values);
              setSubmitting(false);
              onCloseAdvanceFilterPopup();
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.country')}
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectCountry')}
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val, limit: 999 });
                      setFieldValue(`country`, val);
                    }}
                  />
                  <Select
                    label={t('filters.state')}
                    options={[
                      ..._.map(allState, (state) => {
                        return { label: state.name, value: state.name };
                      }),
                    ]}
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectState')}
                    isMulti
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      getCityByStateName({ state_names: val, limit: 999 });
                      setFieldValue(`state`, val);
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.city')}
                    options={[
                      ..._.map(allCityByState, (state) => {
                        return { label: state.name, value: state.name };
                      }),
                    ]}
                    placeholder={t('placeHolder.selectCity')}
                    name="city"
                    isMulti
                    value={values.city}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getCityByStateName({ ...data, state_names: values.state === 'All' ? '' : values.state })}
                    page={cityByStatePage}
                    totalPage={cityByStateTotalPage}
                    onChange={(val) => {
                      setFieldValue('city', val);
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.tags')}
                      isMulti
                      options={_.map(allTagManagement, (item) => {
                        return { label: item.name, value: item.id };
                      })}
                      className="filter-select-box"
                      placeholder={t('placeHolder.selectTags')}
                      name="tags"
                      value={values.tags}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getTags}
                      onChange={(val) => {
                        setFieldValue(`tags`, val);
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      getStations();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </React.Fragment>
  );
};
const ChargerDataItem = (props) => {
  const { station, stationChecked, chargers, onStationSelect, onChargerSelect } = props;

  return (
    <div className="station-access--list">
      <div className="list-info">
        <div className="station-checkbox-input__block">
          <label className="station--label">{_.get(station, 'name', '')}</label>
          <CheckBox checked={stationChecked} onChange={onStationSelect} />
        </div>
        <div className="charger-list-info">
          {_.map(
            _.get(station, 'chargers', []).filter((charger) => !charger.is_deleted),
            (data, index) => {
              return (
                <>
                  <div className="charger-checkbox-input__block" key={`charger-${index}`}>
                    <label className="charger--label">{_.get(data, 'charger_id', '')}</label>
                    <CheckBox checked={chargers[data.id] || false} onChange={() => onChargerSelect(data.id)} />
                  </div>
                </>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

ChargerDataItem.propTypes = {
  station: PropTypes.object,
  chargers: PropTypes.array,
  onStationSelect: PropTypes.func,
  onChargerSelect: PropTypes.func,
  stationChecked: PropTypes.bool,
};
export default StationAccessForm;
