import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { Row, Col, Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { useTranslation } from 'react-i18next';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import * as Yup from 'yup';
import Select from 'components/inputs/Select';
import TextInput from 'components/inputs/Input';
import { CALCULATE_CUSTOMER_BOOKING } from 'actions/customer';
import CheckBox from 'components/inputs/CheckBox';
import ScheduleBooking from './ScheduleBooking';
import { BOOKING_COUPON } from 'actions/coupon';
import { useParams } from 'react-router-dom';
import { RiCoupon3Line } from 'react-icons/ri';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import CouponSelection from 'view/offerManagement/coupon/couponSelection';
import { toast } from 'react-toastify';

const ScheduleBookingForm = (props) => {
  const dispatch = useDispatch();
  const { onClose = _.noop() } = props;

  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [data, setData] = useState({});
  const [dataCalculated, setDataCalculated] = useState({});
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const customerProfileList = useSelector((state) => state.customerProfile.customerProfile);
  const profileData = useSelector((state) => state.profile.userProfile);
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);
  const { customerId } = useParams();
  const [bookingCoupons, setBookingCoupons] = useState([]);
  const [expandedCouponId, setExpandedCouponId] = useState(null);
  const { t } = useTranslation();
  const userProfileStations = profileData.charging_stations;
  const customerVehicle = customerProfileList.vehicles;
  const stationList =
    userProfileStations && userProfileStations.length > 0
      ? allStationByCity.filter((x) => userProfileStations.indexOf(x.id) !== -1)
      : allStationByCity;

  const ScheduleBookingSchema = Yup.object()
    .shape({
      station: Yup.string().required(t('scheduleBooking.station')),
      charger: Yup.string().required(t('scheduleBooking.charger')),
      vehicle: Yup.string().required(t('scheduleBooking.vehicle')),
      connectorId: Yup.number()
        .required(t('scheduleBooking.connectorId'))
        .test('is-available', 'Plug is not available for charging', function (value) {
          const { station, charger } = this.parent;
          const selectedPlug = _.find(_.get(_.find(_.get(_.find(stationList, { id: station }), 'chargers'), { id: charger }), 'plugs'), {
            connector_id: value,
          });
          return selectedPlug
            ? selectedPlug.status !== 'Unavailable' &&
                selectedPlug.status !== 'Preparing' &&
                selectedPlug.status !== 'PowerLoss' &&
                selectedPlug.status !== 'In Use' &&
                selectedPlug.status !== 'Charging' &&
                selectedPlug.status !== 'Faulted' &&
                selectedPlug.status !== 'Finishing'
            : true;
        }),
      booking_type: Yup.string().required(t('scheduleBooking.bookingType')),
      units: Yup.number().required(t('scheduleBooking.units')).min(5, t('scheduleBooking.minlimit5')),
      amount: Yup.number().required(t('scheduleBooking.amount')).min(100, t('scheduleBooking.minlimit')),
      minutes: Yup.number().required(t('scheduleBooking.minutes')).min(15, t('scheduleBooking.minlimit15')),
      time: Yup.string().required(t('scheduleBooking.time')),
      date: Yup.string().required(t('scheduleBooking.date')),
      useChargeCoin: Yup.boolean(),
      coupon: Yup.string().nullable(),
    })
    .test('chargecoin-or-coupon', 'Either ChargeCoin or Coupon can be used, not both', function (value) {
      return !(value.useChargeCoin && value.coupon);
    });

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const initialValues = {
    userId: '',
    station: '',
    charger: '',
    vehicle: '',
    connectorId: '',
    booking_type: 'unit',
    units: 5,
    amount: 100,
    minutes: 15,
    schedule_datetime: '',
    useChargeCoin: false,
    time: '',
    date: getCurrentDate(),
  };

  const calculateCustomerBooking = useCallback((data, openModal = false) => {
    dispatch({
      type: CALCULATE_CUSTOMER_BOOKING,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            setDataCalculated(res.data);
            if (openModal) {
              setShowBookingModal(true);
            }
          }
        }
      },
    });
  }, []);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      access_type: 'Public',
      is_deleted: true,
    };
    dispatch({ type: GET_STATION_BY_CITY, payload: stationData });
  }, []);

  const getAllBookingCoupon = useCallback((data = {}) => {
    const stationData = {
      ...data,
      nature: 'transaction',
      scheduleDateTime: data.scheduleDateTime,
      userId: customerId,
      charging_station: data.charging_station || '',
      charger: data.charger || '',
      booking_type: data.booking_type || 'mobile',
      booking_value: data.booking_value,
    };
    dispatch({
      type: BOOKING_COUPON,
      payload: stationData,
      cb: (res) => {
        if (res && res.status === 200) {
          setBookingCoupons(res.data.results);
        }
      },
    });
  }, []);

  useEffect(() => {
    getAllStation();
    getAllBookingCoupon();
  }, []);

  const handleCloseModel = () => {
    setShowBookingModal(false);
  };

  const handleOpenCouponModal = (values) => {
    const bookingValues = {
      userId: _.get(customerProfileList, 'id', ''),
      booking_type: values.booking_type,
      units: values.units,
      amount: values.amount,
      minutes: values.minutes,
      schedule_datetime: values.schedule_datetime,
      charger: values.charger,
      vehicle: values.vehicle,
      connectorId: values.connectorId,
    };
    calculateCustomerBooking(bookingValues, false);
    setShowCouponModal(true);
  };

  const debouncedGetAllBookingCoupon = useMemo(() => _.debounce(getAllBookingCoupon, 500), [getAllBookingCoupon]);

  const handleCloseCouponModal = () => {
    setShowCouponModal(false);
  };

  const handleApplyCoupon = (coupon) => {
    setSelectedCoupon(coupon);
    handleCloseCouponModal();
    setTimeout(() => {
      toast.success('Coupon applied successfully!');
    }, 300);
  };

  const toggleCouponDetails = (couponId) => {
    setExpandedCouponId((prevId) => (prevId === couponId ? null : couponId));
  };

  return (
    <React.Fragment>
      <div className="booking-form-page__main">
        <Card>
          <div className="booking-form-header__block">
            &nbsp;
            <div className="booking-header-name">
              <div className="booking-solt-text-align">{`Schedule Booking`}</div>
            </div>
            <div className="booking-close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
            &nbsp;
          </div>
          <div className="booking-form-body__block">
            <div className="booking-form--block">
              <Formik
                initialValues={{
                  ...initialValues,
                  coupon: '',
                }}
                validationSchema={ScheduleBookingSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (values.useChargeCoin && selectedCoupon) {
                    toast.error('Either ChargeCoin or Coupon can be used, not both');
                    setSubmitting(false);
                    return;
                  }

                  const istDate = new Date(`${values.date}T${values.time}:00.000Z`);
                  const formattedDate = new Date(istDate.getTime() - istDate.getTimezoneOffset() * -60000).toISOString();
                  const payload = _.omit(values, ['date', 'time', 'station']);

                  let bookingData;
                  if (values.booking_type === 'unit') {
                    payload.units = values.units;
                    bookingData = _.omit(payload, ['amount', 'minutes']);
                  } else if (values.booking_type === 'money') {
                    payload.amount = values.amount;
                    bookingData = _.omit(payload, ['units', 'minutes']);
                  } else {
                    payload.minutes = values.minutes;
                    bookingData = _.omit(payload, ['amount', 'units']);
                  }

                  const bookingValues = {
                    ...bookingData,
                    userId: _.get(customerProfileList, 'id', ''),
                    schedule_datetime: formattedDate,
                    coupon: selectedCoupon ? selectedCoupon.id : '',
                  };

                  calculateCustomerBooking(bookingValues, true);

                  setData(bookingValues);
                  getAllBookingCoupon({
                    charging_station: values.station,
                    charger: values.charger,
                    scheduleDateTime: formattedDate,
                  });
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, touched, errors, setFieldValue, handleChange, setFieldError }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6}>
                        <Select
                          isRequired
                          label={t('placeHolder.selectStation')}
                          options={[
                            ..._.map(stationList, (station) => {
                              return { label: `${station.name} (${station.status})`, value: station.id };
                            }),
                          ]}
                          placeholder={`Station Name`}
                          name="charging_station"
                          value={values.station}
                          onMenuScrollDown={true}
                          onChange={(val) => {
                            setFieldValue(`station`, val);
                            setFieldValue(`charger`, '');
                            setFieldValue(`connectorId`, '');
                          }}
                          error={errors.station && touched.station ? errors.station : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <Select
                          label={t('placeHolder.selectCharger')}
                          isRequired
                          options={
                            values.station && [
                              ..._.map(_.filter(_.get(_.find(stationList, { id: values.station }), 'chargers'), { is_active: true }), (chargers) => {
                                return { label: `${chargers.charger_id} (${chargers.charger_status})`, value: chargers.id };
                              }),
                            ]
                          }
                          placeholder={`Charger Id`}
                          name="charger"
                          value={values.charger}
                          onMenuScrollDown={true}
                          onChange={(val) => {
                            setFieldValue(`charger`, val);
                            setFieldValue(`connectorId`, '');
                          }}
                          error={errors.charger && touched.charger ? errors.charger : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <Select
                          label={t('placeHolder.selectConnector')}
                          isRequired
                          options={_.map(
                            _.get(_.find(_.get(_.find(stationList, { id: values.station }), 'chargers'), { id: values.charger }), 'plugs'),
                            (plug) => {
                              return {
                                label: `${plug.name} (${plug.status})`,
                                value: plug.connector_id,
                              };
                            }
                          )}
                          placeholder={`Select Connector`}
                          name="connectorId"
                          value={values.connectorId}
                          onMenuScrollDown={true}
                          onChange={(val) => {
                            const selectedPlug = _.find(
                              _.get(_.find(_.get(_.find(stationList, { id: values.station }), 'chargers'), { id: values.charger }), 'plugs'),
                              { connector_id: val }
                            );

                            if (selectedPlug && selectedPlug.status === 'Available') {
                              setFieldError('connectorId', undefined); // Clear error if available
                            } else {
                              setFieldError('connectorId', 'Plug is not available for charging');
                            }

                            setFieldValue('connectorId', val);
                          }}
                          error={errors.connectorId && touched.connectorId ? errors.connectorId : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <Select
                          label={t('placeHolder.selectVehicle')}
                          isRequired
                          options={_.map(customerVehicle, (vehicle) => {
                            return {
                              label: _.get(vehicle, 'make', '') + ' ' + `(${_.get(vehicle, 'model', '')})`,
                              value: vehicle.id,
                            };
                          })}
                          placeholder={`Select Vehicle`}
                          name="vehicle"
                          value={values.vehicle}
                          onMenuScrollDown={true}
                          onChange={(val) => {
                            setFieldValue(`vehicle`, val);
                          }}
                          error={errors.vehicle && touched.vehicle ? errors.vehicle : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <span className="font-color">{t('placeHolder.scheduleDateTime')}</span>
                        <div style={{ height: '12%' }}>&nbsp;</div>
                        <div className="time_sec">
                          <div className="custome_time">
                            <input
                              className="ct_selecter"
                              id="time"
                              name="date"
                              type="Date"
                              value={values.date}
                              min={getCurrentDate()}
                              onChange={(val) => {
                                setFieldValue(`date`, val.target.value);
                              }}
                            />
                            <div style={{ color: '#dc4949', fontSize: 10 }}>{errors.date && touched.date ? errors.date : null}</div>
                          </div>
                          <div className="to_sec"></div>
                          <div className="custome_time">
                            <input
                              className="ct_selecter"
                              type="time"
                              value={values.time}
                              onChange={(val) => {
                                const selectedTime = val.target.value;
                                setFieldValue('time', selectedTime);
                              }}
                            />
                            <div style={{ color: '#dc4949', fontSize: 10 }}>{errors.time && touched.time ? errors.time : null}</div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <Select
                          label={t('placeHolder.bookingType')}
                          isRequired
                          options={[
                            { label: 'Amount', value: 'money' },
                            { label: 'Unit', value: 'unit' },
                            { label: 'Time', value: 'time' },
                          ]}
                          placeholder={`Booking Type`}
                          name="booking_type"
                          value={values.booking_type}
                          onMenuScrollDown={true}
                          onChange={(val) => {
                            setFieldValue(`booking_type`, val);
                          }}
                          error={errors.booking_type && touched.booking_type ? errors.booking_type : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <TextInput
                          label={
                            values.booking_type === 'unit'
                              ? t('placeHolder.units')
                              : values.booking_type === 'money'
                              ? t('placeHolder.amount')
                              : t('placeHolder.minutes')
                          }
                          isRequired
                          placeholder={values.booking_type === 'unit' ? 'Units' : values.booking_type === 'money' ? 'Amount' : 'Minutes'}
                          post_text={values.booking_type === 'unit' ? 'kWh' : values.booking_type === 'money' ? 'Rs' : 'mins'}
                          name={values.booking_type === 'unit' ? 'units' : values.booking_type === 'money' ? 'amount' : 'minutes'}
                          type="number"
                          value={values.booking_type === 'unit' ? values.units : values.booking_type === 'money' ? values.amount : values.minutes}
                          onChange={(e) => {
                            handleChange(e);
                            debouncedGetAllBookingCoupon({
                              charging_station: values.station,
                              charger: values.charger,
                              scheduleDateTime: `${values.date}T${values.time}:00.000Z`,
                            });
                          }}
                          error={
                            values.booking_type === 'unit'
                              ? errors.units && touched.units
                                ? errors.units
                                : null
                              : values.booking_type === 'money'
                              ? errors.amount && touched.amount
                                ? errors.amount
                                : null
                              : errors.minutes && touched.minutes
                              ? errors.minutes
                              : null
                          }
                        />
                      </Col>
                      <Col lg={'auto'}>
                        <div className="useChargeCoin-field-main--block">
                          <div className="useChargeCoin-checkbox-input__block">
                            <label htmlFor="useChargeCoin" className="useChargeCoin--label">
                              {t('scheduleBooking.useChargeCoin')}
                            </label>
                            <CheckBox
                              name="useChargeCoin"
                              checked={values.useChargeCoin}
                              onChange={(e) => setFieldValue('useChargeCoin', e.target.checked)}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={'auto'} className="couponSelection">
                        <CouponSelection
                          selectedCoupon={selectedCoupon}
                          handleOpenCouponModal={() => handleOpenCouponModal(values)}
                          handleRemoveCoupon={() => setSelectedCoupon(null)}
                          t={t}
                          disabled={
                            (values.booking_type === 'unit' && !values.units) ||
                            (values.booking_type === 'money' && !values.amount) ||
                            (values.booking_type === 'time' && !values.minutes) ||
                            !values.station ||
                            !values.charger ||
                            !values.connectorId ||
                            !values.vehicle ||
                            !values.booking_type ||
                            !values.date ||
                            !values.time
                          }
                        />
                      </Col>
                    </Row>

                    <div className="booking-slot-btns-sec">
                      <div className="booking-slot-submit-btn">
                        <Button type="submit" style={{ padding: `10px 80px` }}>
                          {t('button.calculateAmount')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
        {showBookingModal && (
          <Modal show={showBookingModal} centered onHide={handleCloseModel}>
            <ScheduleBooking
              onClose={handleCloseModel}
              bookingInfo={dataCalculated}
              bookingData={data}
              bookingCoupons={selectedCoupon ? selectedCoupon : ''}
            />
          </Modal>
        )}

        {showCouponModal && (
          <Modal show={showCouponModal} centered onHide={handleCloseCouponModal} className="couponModal">
            <Modal.Header closeButton>
              <Modal.Title>{t('placeHolder.coupon')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {bookingCoupons.length > 0 ? (
                <div className="couponListBox">
                  {bookingCoupons.map((coupon) => {
                    const service_charge = parseFloat(dataCalculated.service_charge).toFixed(2) || 0;
                    const isCouponApplicable = service_charge >= coupon.min_order_value;

                    return (
                      <div key={coupon.id} className={`coupon-item ${isCouponApplicable ? '' : 'disabled-coupon'}`}>
                        <div className="coupon-header">
                          <div className="coupon_details">
                            <RiCoupon3Line className="coupon-icon" size={32} />
                            <div className="coupon_name">
                              <span className="coupons_name">{coupon.name}</span>
                              <span className="coupons_code">{coupon.code}</span>
                              {!isCouponApplicable && (
                                <div className="coupon-not-applicable">
                                  {t('couponForm.notApplicableMessage')} ₹{coupon.min_order_value} {t('couponForm.applycoupon')} ₹{service_charge}.
                                </div>
                              )}
                              <div className="coupon_step_to_avail">
                                {coupon.step_to_avail.length > 50 ? (
                                  <>
                                    <span>
                                      {expandedCouponId === coupon.id ? coupon.step_to_avail : `${coupon.step_to_avail.slice(0, 50)}...`}
                                      <span onClick={() => toggleCouponDetails(coupon.id)} className="show-more">
                                        {expandedCouponId === coupon.id ? (
                                          <>
                                            Show Less <IoIosArrowUp />
                                          </>
                                        ) : (
                                          <>
                                            Show More <IoIosArrowDown />
                                          </>
                                        )}
                                      </span>
                                    </span>
                                  </>
                                ) : (
                                  <span>{coupon.step_to_avail}</span>
                                )}
                              </div>
                            </div>

                            <Button onClick={() => handleApplyCoupon(coupon)} className="apply-coupon-btn" disabled={!isCouponApplicable}>
                              {t('button.tapToApply')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>{t('modal.noCouponsAvailable')}</p>
              )}
            </Modal.Body>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};
ScheduleBookingForm.propTypes = {
  onClose: PropTypes.func,
};
export default ScheduleBookingForm;
