import { COUPON_PERFORMANCE_REPORT, COUPON_PERFORMANCE_REPORT_SUCCESS, COUPON_PERFORMANCE_REPORT_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  couponPerformanceReport: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const couponPerformanceReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case COUPON_PERFORMANCE_REPORT: {
      return { ...state, isLoading: true };
    }
    case COUPON_PERFORMANCE_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        couponPerformanceReport: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case COUPON_PERFORMANCE_REPORT_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default couponPerformanceReportReducer;
