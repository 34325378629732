import { DOWNLOAD_INVOICE, DOWNLOAD_INVOICE_FAIL, DOWNLOAD_INVOICE_SUCCESS } from '../actions/invoice';
import {
  DOWNLOAD_CHARGER_LOG_DATA,
  DOWNLOAD_CHARGER_LOG_DATA_SUCCESS,
  DOWNLOAD_CHARGER_LOG_DATA_FAIL,
  DOWNLOAD_TABULAR_METERVALUE,
  DOWNLOAD_TABULAR_METERVALUE_SUCCESS,
  DOWNLOAD_TABULAR_METERVALUE_FAIL,
} from '../actions/activityLog';
import {
  DOWNLOAD_CUSTOMER_BOOKING,
  DOWNLOAD_CUSTOMER_BOOKING_SUCCESS,
  DOWNLOAD_CUSTOMER_BOOKING_FAIL,
  DOWNLOAD_CUSTOMER_TRANSACTION,
  DOWNLOAD_CUSTOMER_TRANSACTION_SUCCESS,
  DOWNLOAD_CUSTOMER_TRANSACTION_FAIL,
  DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION,
  DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION_SUCCESS,
  DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION_FAIL,
} from '../actions/customer';
import { DOWNLOAD_RFID_REQUEST, DOWNLOAD_RFID_REQUEST_SUCCESS, DOWNLOAD_RFID_REQUEST_FAIL } from '../actions/rfidRequest';
import {
  DOWNLOAD_STATION_CHARGING_SUMMARY,
  DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS,
  DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL,
  DOWNLOAD_BOOKING_HISTORY,
  DOWNLOAD_BOOKING_HISTORY_SUCCESS,
  DOWNLOAD_BOOKING_HISTORY_FAIL,
  DOWNLOAD_USER_CHARGING_HISTORY,
  DOWNLOAD_USER_CHARGING_HISTORY_SUCCESS,
  DOWNLOAD_USER_CHARGING_HISTORY_FAIL,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY_SUCCESS,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY_FAIL,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_SUCCESS,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_FAIL,
  DOWNLOAD_WALLET_TRANSACTION_REPORT,
  DOWNLOAD_WALLET_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_WALLET_TRANSACTION_REPORT_FAIL,
  DOWNLOAD_STATION_UNIT_CONSUMPTION,
  DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS,
  DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL,
  DOWNLOAD_STATION_DOWNTIME_REPORT,
  DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS,
  DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL,
  DOWNLOAD_CHARGING_HISTORY,
  DOWNLOAD_CHARGING_HISTORY_SUCCESS,
  DOWNLOAD_CHARGING_HISTORY_FAIL,
  DOWNLOAD_B2B_STATION_CHARGING_HISTORY,
  DOWNLOAD_B2B_STATION_CHARGING_HISTORY_SUCCESS,
  DOWNLOAD_B2B_STATION_CHARGING_HISTORY_FAIL,
  DOWNLOAD_AC_METER_READING_REPORT,
  DOWNLOAD_AC_METER_READING_REPORT_SUCCESS,
  DOWNLOAD_AC_METER_READING_REPORT_FAIL,
  DOWNLOAD_APP_USER_DETAIL_REPORT,
  DOWNLOAD_APP_USER_DETAIL_REPORT_SUCCESS,
  DOWNLOAD_APP_USER_DETAIL_REPORT_FAIL,
  DOWNLOAD_JOB_VOUCHER,
  DOWNLOAD_JOB_VOUCHER_SUCCESS,
  DOWNLOAD_JOB_VOUCHER_FAIL,
  DOWNLOAD_INVOICE_REPORT,
  DOWNLOAD_INVOICE_REPORT_SUCCESS,
  DOWNLOAD_INVOICE_REPORT_FAIL,
  DOWNLOAD_BOOKING_SUMMARY_REPORT,
  DOWNLOAD_BOOKING_SUMMARY_REPORT_SUCCESS,
  DOWNLOAD_BOOKING_SUMMARY_REPORT_FAIL,
  DOWNLOAD_MOST_VEHICLE_CHARGED_LIST,
  DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_SUCCESS,
  DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_FAIL,
  DOWNLOAD_PAYMENT_STATUS_REPORT,
  DOWNLOAD_PAYMENT_STATUS_REPORT_SUCCESS,
  DOWNLOAD_PAYMENT_STATUS_REPORT_FAIL,
  DOWNLOAD_CAPACITY_DATA,
  DOWNLOAD_CAPACITY_DATA_SUCCESS,
  DOWNLOAD_CAPACITY_DATA_ERROR,
  DOWNLOAD_VEHICLE_CHARGING_SESSION,
  DOWNLOAD_VEHICLE_CHARGING_SESSION_SUCCESS,
  DOWNLOAD_VEHICLE_CHARGING_SESSION_FAIL,
  DOWNLOAD_FAULTY_BOOKING_REPORT,
  DOWNLOAD_FAULTY_BOOKING_REPORT_SUCCESS,
  DOWNLOAD_FAULTY_BOOKING_REPORT_FAIL,
  DOWNLOAD_CHARGING_SESSION_REPORT,
  DOWNLOAD_CHARGING_SESSION_REPORT_SUCCESS,
  DOWNLOAD_CHARGING_SESSION_REPORT_FAIL,
  DOWNLOAD_ALL_DATA_REPORT,
  DOWNLOAD_ALL_DATA_REPORT_SUCCESS,
  DOWNLOAD_ALL_DATA_REPORT_FAIL,
  DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT,
  DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_FAIL,
  DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_SUCCESS,
  DOWNLOAD_OCPI_BOOKING,
  DOWNLOAD_OCPI_BOOKING_SUCCESS,
  DOWNLOAD_OCPI_BOOKING_FAIL,
  DOWNLOAD_PLANT_LOAD_FACTOR_REPORT,
  DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_SUCCESS,
  DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_FAIL,
  DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT,
  DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_FAIL,
  DOWNLOAD_COUPON_USAGE_REPORT,
  DOWNLOAD_COUPON_USAGE_REPORT_SUCCESS,
  DOWNLOAD_COUPON_USAGE_REPORT_FAIL,
  DOWNLOAD_COUPON_PERFORMANCE_REPORT,
  DOWNLOAD_COUPON_PERFORMANCE_REPORT_SUCCESS,
  DOWNLOAD_COUPON_PERFORMANCE_REPORT_FAIL,
  DOWNLOAD_PARTNER_CHARGING_SUMMARY,
  DOWNLOAD_PARTNER_CHARGING_SUMMARY_SUCCESS,
  DOWNLOAD_PARTNER_CHARGING_SUMMARY_FAIL,
} from '../actions/dataReport';
import { CHARGER_STATUS_DOWNLOAD, CHARGER_STATUS_DOWNLOAD_SUCCESS, CHARGER_STATUS_DOWNLOAD_FAIL } from 'actions/chargerStatus';
import { DOWNLOAD_EXCEL_VEHICLE, DOWNLOAD_EXCEL_VEHICLE_SUCCESS, DOWNLOAD_EXCEL_VEHICLE_FAIL } from 'actions/vehicle';
import {
  DOWNLOAD_RATING_REVIEW,
  DOWNLOAD_RATING_REVIEW_SUCCESS,
  DOWNLOAD_RATING_REVIEW_FAIL,
  DOWNLOAD_RATING_REVIEW_SUMMARY,
  DOWNLOAD_RATING_REVIEW_SUMMARY_FAIL,
  DOWNLOAD_RATING_REVIEW_SUMMARY_SUCCESS,
} from 'actions/rating';

const initialState = {
  isLoading: false,
};

const downloadingLoaderReducer = (state = initialState, { type }) => {
  switch (type) {
    case DOWNLOAD_ALL_DATA_REPORT:
    case DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT:
    case DOWNLOAD_OCPI_BOOKING:
    case DOWNLOAD_INVOICE:
    case DOWNLOAD_CHARGER_LOG_DATA:
    case DOWNLOAD_CUSTOMER_BOOKING:
    case DOWNLOAD_RFID_REQUEST:
    case DOWNLOAD_BOOKING_HISTORY:
    case DOWNLOAD_USER_CHARGING_HISTORY:
    case DOWNLOAD_B2C_STATION_CHARGING_HISTORY:
    case DOWNLOAD_MONEY_TRANSACTION_PG_REPORT:
    case DOWNLOAD_WALLET_TRANSACTION_REPORT:
    case DOWNLOAD_STATION_DOWNTIME_REPORT:
    case DOWNLOAD_STATION_UNIT_CONSUMPTION:
    case DOWNLOAD_CHARGING_HISTORY:
    case DOWNLOAD_B2B_STATION_CHARGING_HISTORY:
    case DOWNLOAD_AC_METER_READING_REPORT:
    case DOWNLOAD_APP_USER_DETAIL_REPORT:
    case DOWNLOAD_JOB_VOUCHER:
    case DOWNLOAD_INVOICE_REPORT:
    case DOWNLOAD_BOOKING_SUMMARY_REPORT:
    case DOWNLOAD_MOST_VEHICLE_CHARGED_LIST:
    case DOWNLOAD_STATION_CHARGING_SUMMARY:
    case CHARGER_STATUS_DOWNLOAD:
    case DOWNLOAD_TABULAR_METERVALUE:
    case DOWNLOAD_PAYMENT_STATUS_REPORT:
    case DOWNLOAD_EXCEL_VEHICLE:
    case DOWNLOAD_CAPACITY_DATA:
    case DOWNLOAD_CUSTOMER_TRANSACTION:
    case DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION:
    case DOWNLOAD_VEHICLE_CHARGING_SESSION:
    case DOWNLOAD_FAULTY_BOOKING_REPORT:
    case DOWNLOAD_RATING_REVIEW:
    case DOWNLOAD_RATING_REVIEW_SUMMARY:
    case DOWNLOAD_CHARGING_SESSION_REPORT:
    case DOWNLOAD_PLANT_LOAD_FACTOR_REPORT:
    case DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT:
    case DOWNLOAD_COUPON_USAGE_REPORT:
    case DOWNLOAD_COUPON_PERFORMANCE_REPORT:
    case DOWNLOAD_PARTNER_CHARGING_SUMMARY: {
      return { ...state, isLoading: true };
    }

    case DOWNLOAD_ALL_DATA_REPORT_SUCCESS:
    case DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_SUCCESS:
    case DOWNLOAD_OCPI_BOOKING_SUCCESS:
    case DOWNLOAD_INVOICE_SUCCESS:
    case DOWNLOAD_CHARGER_LOG_DATA_SUCCESS:
    case DOWNLOAD_CUSTOMER_BOOKING_SUCCESS:
    case DOWNLOAD_RFID_REQUEST_SUCCESS:
    case DOWNLOAD_BOOKING_HISTORY_SUCCESS:
    case DOWNLOAD_USER_CHARGING_HISTORY_SUCCESS:
    case DOWNLOAD_B2C_STATION_CHARGING_HISTORY_SUCCESS:
    case DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_SUCCESS:
    case DOWNLOAD_WALLET_TRANSACTION_REPORT_SUCCESS:
    case DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS:
    case DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS:
    case DOWNLOAD_CHARGING_HISTORY_SUCCESS:
    case DOWNLOAD_B2B_STATION_CHARGING_HISTORY_SUCCESS:
    case DOWNLOAD_AC_METER_READING_REPORT_SUCCESS:
    case DOWNLOAD_APP_USER_DETAIL_REPORT_SUCCESS:
    case DOWNLOAD_JOB_VOUCHER_SUCCESS:
    case DOWNLOAD_INVOICE_REPORT_SUCCESS:
    case DOWNLOAD_BOOKING_SUMMARY_REPORT_SUCCESS:
    case DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_SUCCESS:
    case DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS:
    case DOWNLOAD_TABULAR_METERVALUE_SUCCESS:
    case CHARGER_STATUS_DOWNLOAD_SUCCESS:
    case DOWNLOAD_PAYMENT_STATUS_REPORT_SUCCESS:
    case DOWNLOAD_EXCEL_VEHICLE_SUCCESS:
    case DOWNLOAD_CAPACITY_DATA_SUCCESS:
    case DOWNLOAD_CUSTOMER_TRANSACTION_SUCCESS:
    case DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION_SUCCESS:
    case DOWNLOAD_VEHICLE_CHARGING_SESSION_SUCCESS:
    case DOWNLOAD_FAULTY_BOOKING_REPORT_SUCCESS:
    case DOWNLOAD_RATING_REVIEW_SUCCESS:
    case DOWNLOAD_RATING_REVIEW_SUMMARY_SUCCESS:
    case DOWNLOAD_CHARGING_SESSION_REPORT_SUCCESS:
    case DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_SUCCESS:
    case DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_SUCCESS:
    case DOWNLOAD_COUPON_USAGE_REPORT_SUCCESS:
    case DOWNLOAD_COUPON_PERFORMANCE_REPORT_SUCCESS:
    case DOWNLOAD_PARTNER_CHARGING_SUMMARY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case DOWNLOAD_ALL_DATA_REPORT_FAIL:
    case DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_FAIL:
    case DOWNLOAD_OCPI_BOOKING_FAIL:
    case DOWNLOAD_INVOICE_FAIL:
    case DOWNLOAD_CHARGER_LOG_DATA_FAIL:
    case DOWNLOAD_CUSTOMER_BOOKING_FAIL:
    case DOWNLOAD_RFID_REQUEST_FAIL:
    case DOWNLOAD_BOOKING_HISTORY_FAIL:
    case DOWNLOAD_USER_CHARGING_HISTORY_FAIL:
    case DOWNLOAD_B2C_STATION_CHARGING_HISTORY_FAIL:
    case DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_FAIL:
    case DOWNLOAD_WALLET_TRANSACTION_REPORT_FAIL:
    case DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL:
    case DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL:
    case DOWNLOAD_CHARGING_HISTORY_FAIL:
    case DOWNLOAD_B2B_STATION_CHARGING_HISTORY_FAIL:
    case DOWNLOAD_AC_METER_READING_REPORT_FAIL:
    case DOWNLOAD_APP_USER_DETAIL_REPORT_FAIL:
    case DOWNLOAD_JOB_VOUCHER_FAIL:
    case DOWNLOAD_INVOICE_REPORT_FAIL:
    case DOWNLOAD_BOOKING_SUMMARY_REPORT_FAIL:
    case DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_FAIL:
    case DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL:
    case DOWNLOAD_TABULAR_METERVALUE_FAIL:
    case CHARGER_STATUS_DOWNLOAD_FAIL:
    case DOWNLOAD_PAYMENT_STATUS_REPORT_FAIL:
    case DOWNLOAD_EXCEL_VEHICLE_FAIL:
    case DOWNLOAD_CAPACITY_DATA_ERROR:
    case DOWNLOAD_CUSTOMER_TRANSACTION_FAIL:
    case DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION_FAIL:
    case DOWNLOAD_VEHICLE_CHARGING_SESSION_FAIL:
    case DOWNLOAD_FAULTY_BOOKING_REPORT_FAIL:
    case DOWNLOAD_RATING_REVIEW_FAIL:
    case DOWNLOAD_RATING_REVIEW_SUMMARY_FAIL:
    case DOWNLOAD_CHARGING_SESSION_REPORT_FAIL:
    case DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_FAIL:
    case DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_FAIL:
    case DOWNLOAD_COUPON_USAGE_REPORT_FAIL:
    case DOWNLOAD_COUPON_PERFORMANCE_REPORT_FAIL:
    case DOWNLOAD_PARTNER_CHARGING_SUMMARY_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default downloadingLoaderReducer;
