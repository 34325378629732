export const CREATE_OCPI_PARTNER_GROUP = 'CREATE_OCPI_PARTNER_GROUP';
export const CREATE_OCPI_PARTNER_GROUP_SUCCESS = 'CREATE_OCPI_PARTNER_GROUP_SUCCESS';
export const CREATE_OCPI_PARTNER_GROUP_FAIL = 'CREATE_OCPI_PARTNER_GROUP_FAIL';

export const FETCH_OCPI_PARTNER_GROUP = 'FETCH_OCPI_PARTNER_GROUP';
export const FETCH_OCPI_PARTNER_GROUP_SUCCESS = 'FETCH_OCPI_PARTNER_GROUP_SUCCESS';
export const FETCH_OCPI_PARTNER_GROUP_FAIL = 'FETCH_OCPI_PARTNER_GROUP_FAIL';

export const GET_OCPI_PARTNER_GROUP = 'GET_OCPI_PARTNER_GROUP';
export const GET_OCPI_PARTNER_GROUP_SUCCESS = 'GET_OCPI_PARTNER_GROUP_SUCCESS';
export const GET_OCPI_PARTNER_GROUP_FAIL = 'GET_OCPI_PARTNER_GROUP_FAIL';

export const DELETE_OCPI_PARTNER_GROUP = 'DELETE_OCPI_PARTNER_GROUP';
export const DELETE_OCPI_PARTNER_GROUP_SUCCESS = 'DELETE_OCPI_PARTNER_GROUP_SUCCESS';
export const DELETE_OCPI_PARTNER_GROUP_FAIL = 'DELETE_OCPI_PARTNER_GROUP_FAIL';

export const DELETE_OCPI_PARTNER = 'DELETE_OCPI_PARTNER';
export const DELETE_OCPI_PARTNER_SUCCESS = 'DELETE_OCPI_PARTNER_SUCCESS';
export const DELETE_OCPI_PARTNER_FAIL = 'DELETE_OCPI_PARTNER_FAIL';

export const UPDATE_OCPI_PARTNER_GROUP = 'UPDATE_OCPI_PARTNER_GROUP';
export const UPDATE_OCPI_PARTNER_GROUP_SUCCESS = 'UPDATE_OCPI_PARTNER_GROUP_SUCCESS';
export const UPDATE_OCPI_PARTNER_GROUP_FAIL = 'UPDATE_OCPI_PARTNER_GROUP_FAIL';

export const ALL_USERS_IN_OCPI_PARTNER_GROUP = 'ALL_USERS_IN_OCPI_PARTNER_GROUP';
export const ALL_USERS_IN_OCPI_PARTNER_GROUP_SUCCESS = 'ALL_USERS_IN_OCPI_PARTNER_GROUP_SUCCESS';
export const ALL_USERS_IN_OCPI_PARTNER_GROUP_FAIL = 'ALL_USERS_IN_OCPI_PARTNER_GROUP_FAIL';

export const ADD_PARTY_IN_OCPI_PARTNER_GROUP = 'ADD_PARTY_IN_OCPI_PARTNER_GROUP';
export const ADD_PARTY_IN_OCPI_PARTNER_GROUP_SUCCESS = 'ADD_PARTY_IN_OCPI_PARTNER_GROUP_SUCCESS';
export const ADD_PARTY_IN_OCPI_PARTNER_GROUP_FAIL = 'ADD_PARTY_IN_OCPI_PARTNER_GROUP_FAIL';

export const CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP = 'CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP';
export const CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP_SUCCESS = 'CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP_SUCCESS';
export const CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP_FAIL = 'CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP_FAIL';
