import React, { useCallback, useEffect, useState, useContext } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { Col, Row } from 'react-bootstrap';
import {
  //BsEye,
  // BsFillFileEarmarkZipFill,
  BsSearch,
  // BsUpload,
} from 'react-icons/bs';
// import { RiDeleteBin6Line, RiFileExcel2Line } from 'react-icons/ri';
// import { FiUsers } from 'react-icons/fi';
// import DatePicker from 'components/inputs/DatePicker';
import * as _ from 'lodash';
// import Avatar from 'components/inputs/Avatar';
// import DummyImage from 'assets/images/icons/dummyImage.png';
import SearchBox from 'components/general/SearchBox';
import PropTypes from 'prop-types';
import { FETCH_CUSTOMER, FETCH_WIDGETS, WALLET_TRANSACTION } from 'actions/customer';
import { useDispatch, useSelector } from 'react-redux';
// import { startsWith } from 'components/common/utils';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import Button from 'components/inputs/Button';
// import { RiDeleteBinLine } from 'react-icons/ri';
// import { FiEdit2 } from 'react-icons/fi';
import { Modal } from 'react-bootstrap';
import CustomerForm from './CustomerForm';
// import moment from 'moment';
// import { AiOutlineFile, AiOutlineFileJpg, AiOutlineFilePdf } from 'react-icons/ai';
// import { FcVideoFile } from 'react-icons/fc';
// import { FaRegFileAudio } from 'react-icons/fa';
// import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import { cookie } from 'utils/cookies/cookies';
import { MdClose } from 'react-icons/md';
import { Form, Formik } from 'formik';
import TextInput from 'components/inputs/Input';
import * as Yup from 'yup';
import Select from 'components/inputs/Select';
import { toast } from 'react-toastify';
import { CREATE_STATUS_CODE, MANAGE_CUSTOMERS } from 'components/common/constant';
// import { useHistory } from 'react-router-dom';
// import './customer.css';
// import { FaRegUser } from 'react-icons/fa';
import { FaUserFriends } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import { FaAndroid } from 'react-icons/fa';
import { IoLogoAndroid } from 'react-icons/io';
import { FaApple } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
// import chargeCoin from '../../assets/images/icons/chargeCoin.png';
import { FaRegUser } from 'react-icons/fa';
import { roundOfDigit } from 'components/common/utils';
import { FaRupeeSign } from 'react-icons/fa';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const CustomerList = () => {
  // const [startDate, setStartDate] = useState(moment().startOf('month'));
  // const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  // const history = useHistory();
  const { navigateTo, isAllowed } = useContext(NavContext);
  const currentUserRole = cookie.get('user-role');

  const initialSearch = useSelector((state) => state.customer.search);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState(initialSearch);
  // const [allCustomerData, setAllCustomerData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const [file, setFile] = useState([]);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showWalletForm, setShowWalletForm] = useState(false);
  const [customerList] = useState({});
  const [pageWiseCustomerData, setPageWiseCustomerData] = useState([]);

  // console.warn("customer",customerList)

  const customers = useSelector((state) => state.customer.customers);
  const isLoader = useSelector((state) => state.customer.isLoading);
  const page = useSelector((state) => state.customer.page);
  const totalData = useSelector((state) => state.customer.total);
  const limit = useSelector((state) => state.customer.limit);
  const totalPages = useSelector((state) => state.customer.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  //Functionality of showing data pagination wise
  const customerData = customers.filter(({ id: id1 }) => pageWiseCustomerData.some(({ id: id2 }) => id2 === id1));

  // const totalusers = useSelector((state) => state.customer.customerData.);

  const getCustomer = useCallback(
    (data = {}) => {
      if (searchText) {
        const customerData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_CUSTOMER,
          payload: customerData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseCustomerData(res.data.results);
              }
            }
          },
        });
      } else {
        dispatch({
          type: FETCH_CUSTOMER,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseCustomerData(res.data.results);
              }
            }
          },
        });
      }
    },
    [searchText, pageWiseCustomerData]
  );

  const getWidgets = useCallback((data = {}) => {
    dispatch({
      type: FETCH_WIDGETS,
      payload: data,
    });
  }, []);

  const walletTransaction = useCallback(
    (data = {}) => {
      dispatch({
        type: WALLET_TRANSACTION,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === CREATE_STATUS_CODE) {
              toast.success('Transaction in wallet successfully');
              setShowWalletForm(false);
              getCustomer({ page });
            }
          }
        },
      });
    },
    [page]
  );

  // function showFile() {
  //   const file = document.getElementById('file');
  //   setFile((prev) => [...prev, file.files.item(0)]);
  // }

  // const onRemoveFile = (removedFile) => {
  //   const newFiles = file.filter((file) => file.name !== removedFile);
  //   setFile(newFiles);
  // };

  // const getCustomerDetail = useCallback((id) => {
  //   dispatch({ type: GET_CUSTOMER, payload: id });
  // }, []);
  //
  // const deleteCustomer = useCallback((id) => dispatch({ type: DELETE_CUSTOMER, payload: id }), []);

  useEffect(() => {
    getCustomer();
    getWidgets();
  }, []);

  const dataFilter = useCallback(() => {
    if (searchText) {
      const filterDateData = {
        search: searchText,
      };
      getCustomer(filterDateData);
    } else {
      getCustomer();
    }
  }, [searchText]);

  // useEffect(() => {
  //   setAllCustomerData(
  //     _.filter(customers, (item) => startsWith(_.get(item, 'name', ''), searchText) || startsWith(_.get(item, 'email', ''), searchText))
  //   );
  // }, [searchText, customers]);

  // useEffect(() => {
  //   getCustomer();
  // }, []);

  // const getSortByItem = (name, order, page, filterDateData) => {
  //   const sortedOrder = order || 'asc';
  //   if (page) {
  //     const data = {
  //       from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
  //       to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
  //       page: page.selected + 1,
  //     };
  //     endDate && getCustomer(data);
  //   } else if (page) {
  //     const data = {
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
  //       page: page.selected + 1,
  //     };
  //     getCustomer(data);
  //   } else if (filterDateData) {
  //     const data = {
  //       ...filterDateData,
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
  //     };
  //     endDate && getCustomer(data);
  //   } else {
  //     const data = {
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
  //     };
  //     getCustomer(data);
  //   }
  // };

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getCustomer(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getCustomer(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  // const handlePageClick = (page) => {
  //   if (sortByItem.item && sortByItem.order) {
  //     const filterDateData = {
  //       from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
  //       to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
  //     };
  //     getSortByItem(sortByItem.item, sortByItem.order, page, filterDateData);
  //   } else if (startDate && endDate) {
  //     const data = {
  //       from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
  //       to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
  //       page: page.selected + 1,
  //     };
  //     getCustomer(data);
  //   } else {
  //     const data = {
  //       page: page.selected + 1,
  //     };
  //     getCustomer(data);
  //   }
  // };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (searchText) {
        const data = {
          search: searchText,
          page: page.selected + 1,
        };
        getCustomer(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getCustomer(data);
      }
    },
    [sortByItem, searchText]
  );

  const handleCloseFormCustomer = () => {
    setShowForm(false);
    setIsEdit(false);
  };
  const handleSearch = () => {
    getCustomer({});
  };

  const handleClose = () => {
    setShowWalletForm(false);
  };

  // const getCustomerPermissions =
  //   !_.isEmpty(rolePermissionData) &&
  //   _.map(
  //     _.filter(rolePermissionData[0].permissions, (item) => item.name === 'getCustomers'),
  //     (data) => data.name
  //   );

  const manageCustomerPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_CUSTOMERS),
      (data) => data.name
    );

  const WalletTransactionSchema = Yup.object().shape({
    message: Yup.string().required(t('customerList.message')),
    type: Yup.string().required(t('customerList.type')),
    amount: Yup.number().required(t('customerList.amount')),
  });
  // console.log(customerData);
  const totalUserCount = useSelector((state) => state.customer.totalUserCount);
  const activeusers = useSelector((state) => state.customer.activeUser);
  const inactiveUser = useSelector((state) => state.customer.inactiveUser);
  const androidUser = useSelector((state) => state.customer.androidUser);
  const iosUser = useSelector((state) => state.customer.iosUser);

  const formatPhoneNumber = (item) => {
    return `+${_.get(item, 'country_code')} ${_.get(item, 'phone')}`;
  };

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text);
    toast.success(`${type} copied!`);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.customerManagement')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="customer-management-page__main">
              <div className="customer-details--block">
                {isAllowed('viewWidgetsCustomerManagement') && (
                  <div className="status-blocks">
                    <div className="status-block">
                      <div className="breating-area">
                        {isLoader ? <Skeleton circle="true" height={35} width={35} /> : <FaUserFriends className="icon-inner-color" size={35} />}
                      </div>
                      <div className="status-info">
                        <h1>{isLoader ? <Skeleton height={15} width={100} /> : totalUserCount}</h1>
                        <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.totalUsers')}</p>
                      </div>
                    </div>

                    <div className="status-block">
                      <div className="breating-area">
                        {isLoader ? <Skeleton circle="true" height={35} width={35} /> : <FaUserAlt className="icon-inner-color" size={35} />}
                      </div>
                      <div className="status-info">
                        <h1>{isLoader ? <Skeleton height={15} width={100} /> : activeusers}</h1>
                        <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.activeUsers')}</p>
                      </div>
                    </div>

                    <div className="status-block">
                      <div className="breating-area">
                        {isLoader ? <Skeleton circle="true" height={35} width={35} /> : <FaUserAlt className="icon-inner-color" size={35} />}
                      </div>
                      <div className="status-info">
                        <h1>{isLoader ? <Skeleton height={15} width={100} /> : inactiveUser}</h1>
                        <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.inActiveUsers')}</p>
                      </div>
                    </div>

                    <div className="status-block">
                      <div className="breating-area">
                        {isLoader ? (
                          <Skeleton circle="true" height={35} width={35} />
                        ) : (
                          <FaAndroid color="#be210b" className="icon-inner-color" size={35} />
                        )}
                      </div>
                      <div className="status-info">
                        <h1>{isLoader ? <Skeleton height={15} width={100} /> : androidUser}</h1>
                        <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.android')}</p>
                      </div>
                      <div className="breating-area status-blue">
                        {isLoader ? (
                          <Skeleton circle="true" height={35} width={35} />
                        ) : (
                          <FaApple color="#3c7cdd" className="icon-inner-color" size={35} />
                        )}
                      </div>
                      <div className="status-info">
                        <h1>{isLoader ? <Skeleton height={15} width={100} /> : iosUser}</h1>
                        <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.iOS')}</p>
                      </div>
                    </div>
                  </div>
                )}

                <Card style={{ border: 'none', borderRadius: 'none' }}>
                  <div className="customer-details-search--block" style={{ border: 'none', borderRadius: 'none' }}>
                    <Row>
                      <Col xl={11} md={11}>
                        <SearchBox
                          preIcon={<BsSearch />}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch();
                            }
                          }}
                        />
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="customerList-add-boxButton" onClick={dataFilter}>
                          <Button className="customerList-add-box-Btn">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col
                        xl={`${currentUserRole === 'super_admin' ? 12 : !_.isEmpty(manageCustomerPermissions) ? 10 : 12}`}
                        md={`${currentUserRole === 'super_admin' ? 12 : !_.isEmpty(manageCustomerPermissions) ? 8 : 12}`}
                      ></Col>
                    </Row>
                    {/* <Col xl={6} md={10} className="date-picker">
                      <DateTimePicker
                        onChangeOfStartDate={(item) => setStartDate(item)}
                        onChangeOfEndDate={(item) => setEndDate(item)}
                        initialValueOfStartDate={moment().startOf('month')}
                        initialValueOfEndDate={moment(new Date()).endOf('day')}
                      />
                    </Col> */}
                    {/* {currentUserRole !== 'super_admin' &&
                      (!_.isEmpty(manageCustomerPermissions) || (!_.isEmpty(getCustomerPermissions) && !_.isEmpty(manageCustomerPermissions))) && (
                        <Col xl={2} md={4} className="customerList-add-boxButton">
                          <Button
                            className="customerList-add-box-Btn"
                            onClick={() => {
                              setShowForm(true);
                              setIsEdit(false);
                            }}
                          >
                            Add Customer
                          </Button>
                        </Col>
                      )}
                  */}
                  </div>
                  {/* <div className="customer-details-inner">
                  <Row className="customer-details-block">
                    <Col xl={4} md={6} className="customer-details-wrapper">
                      <CustomerInfoBlock icon={<FiUsers color="#3C7CDD" />} title={totalData} subtitle={'Total Customer'} />
                    </Col>
                    <Col xl={4} md={6} className="customer-details-wrapper">
                      <CustomerInfoBlock icon={<FiUsers color="#3C7CDD" />} title={0} subtitle={'New Customer'} />
                    </Col>
                    <Col xl={4} md={6} className="customer-details-wrapper">
                      <CustomerInfoBlock icon={<FiUsers color="#3C7CDD" />} title={0} subtitle={'Customer using chargers'} />
                    </Col>
                  </Row>
                </div> */}
                </Card>
              </div>
              <div
                className="new-customer-list"
                style={{ backgroundColor: 'none', transform: 'translateY(-30px)', border: 'none', borderRadius: 'none' }}
              >
                <Card style={{ backgroundColor: 'none', transform: 'translateY(-30px)', border: 'none', borderRadius: 'none' }}>
                  {/* <div className="customer-text">{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.customerDetails')}</div> */}
                  <div className="customer-list-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.customerName')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.contactDetails')}</span>
                                <span className="ico" onClick={() => handleSorting('phone', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.walletBalance')}</span>
                              </div>
                            </th>
                            {/* <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.chargeCoin')}</span>
                              </div>
                            </th> */}
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.rfidTag')}</span>
                              </div>
                            </th>
                            {/* <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.currentLevel')}</span>
                              </div>
                            </th> */}
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.appType')}</span>
                              </div>
                            </th>
                            {/* {currentUserRole === 'super_admin' && (
                              <th className="action-col">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCustomerList.action')}</span>
                              </th>
                            )} */}
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            // If loading, display a loading skeleton
                            <tr className="customer-row">
                              <td className="customer-cell border-0">
                                <Skeleton height={15} width={100} />
                              </td>
                            </tr>
                          ) : _.isEmpty(customerData) ? (
                            // If no data, display a message
                            <tr className="customer-row">
                              <td className="customer-cell border-0">
                                <div className="empty-data-block">{t('customerList.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            // Map through customer data and display each item in a row
                            _.map(customerData, (item, key) => {
                              const rfidTag = item.rfid ? _.head(_.map(item.rfid, (data) => data.code)) : '';
                              const hasAndroidToken = item.androidToken ? true : false;
                              const hasIosToken = item.iosToken ? true : false;

                              return (
                                <tr
                                  key={`customer-${key}`}
                                  className="customer-row"
                                  onClick={() => navigateTo(`/customerProfile/${_.get(item, 'id')}`)}
                                >
                                  <td>
                                    <div className="avatar-with-name">
                                      {isLoader ? <Skeleton circle="true" height={15} width={15} /> : <FaRegUser size={25} color="#3c7cdd" />}
                                      <span className="customer-name">
                                        {isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'name', 'Guest User')}
                                      </span>
                                    </div>
                                  </td>

                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <div>
                                        <span
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            copyToClipboard(formatPhoneNumber(item), 'Phone number');
                                          }}
                                        >
                                          {formatPhoneNumber(item)}
                                        </span>
                                        <br />

                                        <span
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            copyToClipboard(_.get(item, 'email'), 'Email');
                                          }}
                                        >
                                          {_.get(item, 'email', '-')}
                                        </span>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <div className="wallet-rupee-icon">
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} key="skeleton" />
                                      ) : (
                                        <>
                                          <FaRupeeSign size={12} />
                                          {roundOfDigit(_.get(item, 'wallet_balance'), 2)}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  {/* <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <div className="wallet-rupee-icon">
                                        <img src={chargeCoin} alt="Charge Coin" className="icon" />
                                        &nbsp;
                                        {item.charge_coin}
                                      </div>
                                    )}
                                  </td> */}
                                  <td>{rfidTag ? rfidTag : '-'}</td>
                                  {/* <td></td> */}
                                  <td>
                                    <div className="app-icons">
                                      {hasIosToken && <FaApple size={24} color="#ffffff" title="IOS" />}
                                      {hasAndroidToken && <IoLogoAndroid size={24} color="#ffffff" title="Android" />}
                                    </div>
                                  </td>

                                  {/* {currentUserRole === 'super_admin' && (
                                <td >
                                  <Button
                                    onClick={() => {
                                      setShowWalletForm(true);
                                      setCustomerList(item);
                                    }}
                                  >
                                    Do transaction
                                  </Button>
                                </td>
                              )} */}
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(customerData) && (
                    // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </Card>
              </div>
              {/*<div className="users-group-list--block">*/}
              {/*  <Card>*/}
              {/*    <div className="user-group-inner">*/}
              {/*      <div className="user-group-text">User Group</div>*/}
              {/*      <div className="upload-file-block">*/}
              {/*        <input onChange={showFile} type="file" id="file" className="upload-file--input" />*/}
              {/*        <label className="upload-file-inner" htmlFor="file">*/}
              {/*          <BsUpload color="#e12f17" />*/}
              {/*          <span className="upload-file-text">Upload</span>*/}
              {/*        </label>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="users-group-list">*/}
              {/*      {file.length > 0 ? (*/}
              {/*        _.map(file, (item) => {*/}
              {/*          return (*/}
              {/*            <>*/}
              {/*              {item['type'] === 'image/jpeg' ? (*/}
              {/*                <UploadedUserList*/}
              {/*                  fileIcon={<AiOutlineFileJpg size={20} />}*/}
              {/*                  fileName={item.name}*/}
              {/*                  removeFile={() => onRemoveFile(item.name)}*/}
              {/*                />*/}
              {/*              ) : item['type'] === 'video/mp4' ? (*/}
              {/*                <UploadedUserList fileIcon={<FcVideoFile size={20} />} fileName={item.name} removeFile={() => onRemoveFile(item.name)} />*/}
              {/*              ) : item['type'] === 'application/zip' ? (*/}
              {/*                <UploadedUserList*/}
              {/*                  fileIcon={<BsFillFileEarmarkZipFill />}*/}
              {/*                  fileName={item.name}*/}
              {/*                  removeFile={() => onRemoveFile(item.name)}*/}
              {/*                />*/}
              {/*              ) : item['type'] === 'audio/mpeg' ? (*/}
              {/*                <UploadedUserList*/}
              {/*                  fileIcon={<FaRegFileAudio size={20} />}*/}
              {/*                  fileName={item.name}*/}
              {/*                  removeFile={() => onRemoveFile(item.name)}*/}
              {/*                />*/}
              {/*              ) : item['type'] === 'application/pdf' ? (*/}
              {/*                <UploadedUserList*/}
              {/*                  fileIcon={<AiOutlineFilePdf size={20} />}*/}
              {/*                  fileName={item.name}*/}
              {/*                  removeFile={() => onRemoveFile(item.name)}*/}
              {/*                />*/}
              {/*              ) : item['type'] === ('application/vnd.ms-excel' || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ? (*/}
              {/*                <UploadedUserList*/}
              {/*                  fileIcon={<RiFileExcel2Line size={20} />}*/}
              {/*                  fileName={item.name}*/}
              {/*                  removeFile={() => onRemoveFile(item.name)}*/}
              {/*                />*/}
              {/*              ) : (*/}
              {/*                <UploadedUserList*/}
              {/*                  fileIcon={<AiOutlineFile size={20} />}*/}
              {/*                  fileName={item.name}*/}
              {/*                  removeFile={() => onRemoveFile(item.name)}*/}
              {/*                />*/}
              {/*              )}*/}
              {/*            </>*/}
              {/*          );*/}
              {/*        })*/}
              {/*      ) : (*/}
              {/*        <div className="empty-data-block">No Files</div>*/}
              {/*      )}*/}
              {/*      /!* <UploadedUserList fileIcon={<RiFileExcel2Line />} fileName={'User_Group2.xls'} /> *!/*/}
              {/*    </div>*/}
              {/*  </Card>*/}
              {/*</div>*/}
            </div>
          </div>
        </SkeletonTheme>
        {showForm && (
          <Modal show={showForm} size="xl" centered>
            <CustomerForm onClose={handleCloseFormCustomer} isEdit={isEdit} />
          </Modal>
        )}
        {showWalletForm && (
          <Modal show={showWalletForm} size="lg" centered>
            <div className="wallet-transaction_main">
              <Card>
                <div className="wallet-transaction-header__block">
                  <div className="wallet-transaction-name">
                    <span>{t('customerDetail.walletTransaction')}</span>
                  </div>
                  <div className="close-btn">
                    <MdClose size={30} color="#be210b" onClick={handleClose} />
                  </div>
                </div>
                <div className="wallet-transaction_form">
                  <Formik
                    initialValues={{
                      amount: '',
                      user: _.get(customerList, 'id', ''),
                      type: '',
                      message: '',
                    }}
                    validationSchema={WalletTransactionSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      walletTransaction(values);
                      setSubmitting(false);
                    }}
                  >
                    {({ values, errors, touched, setFieldValue, handleSubmit, handleChange, isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg={6}>
                            <TextInput
                              isRequired
                              label={t('customerDetail.walletAmount')}
                              type="number"
                              placeholder={t('customerDetail.walletAmount')}
                              name="amount"
                              value={_.get(values, 'amount')}
                              onChange={handleChange}
                              error={errors.amount && touched.amount ? errors.amount : null}
                            />
                          </Col>
                          <Col lg={6}>
                            <Select
                              isRequired
                              label={t('customerDetail.walletType')}
                              options={[
                                { label: 'Credit', value: 'credit' },
                                { label: 'Debit', value: 'debit' },
                              ]}
                              placeholder={t('placeHolder.selectType')}
                              name="type"
                              value={values.type}
                              onChange={(val) => setFieldValue(`type`, val)}
                              error={errors.type && touched.type ? errors.type : null}
                            />
                          </Col>
                          <Col lg={12}>
                            <TextInput
                              isRequired
                              as="textarea"
                              rows="4"
                              inputClass="scrollable"
                              placeholder={t('customerDetail.walletMessage')}
                              label={t('customerDetail.walletMessage')}
                              name="message"
                              value={values.message}
                              onChange={handleChange}
                              error={errors.message && touched.message ? errors.message : null}
                            />
                          </Col>
                        </Row>
                        <div className="wallet-transaction_button">
                          <Button className="wallet-transaction-btn cancel_btn" onClick={handleClose}>
                            {t('button.cancel')}
                          </Button>
                          <Button type="submit" disabled={isSubmitting} className="wallet-transaction-btn create-btn">
                            {t('button.submit')}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card>
            </div>
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};
export default CustomerList;

const CustomerInfoBlock = (props) => {
  const { icon, title, subtitle } = props;
  return (
    <div className="customer-info--box">
      <div className="customer-info-icon">{icon}</div>
      <div className="customer-info--wrapper">
        <div className="total-customer">{title}</div>
        <div className="info-text">{subtitle}</div>
      </div>
    </div>
  );
};
CustomerInfoBlock.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.number,
  subtitle: PropTypes.string,
};

// const UploadedUserList = (props) => {
//   const { fileIcon, fileName, removeFile } = props;
//   return (
//     <div className="users-group-list-wrapper">
//       <div className="user-group--name">
//         <span>{fileIcon}</span>
//         <span className="list-text">{fileName}</span>
//       </div>
//       <div className="users-group-list-icon" onClick={removeFile}>
//         <RiDeleteBin6Line />
//       </div>
//     </div>
//   );
// };
// UploadedUserList.propTypes = {
//   fileIcon: PropTypes.object,
//   fileName: PropTypes.string,
//   removeFile: PropTypes.func,
// };
