import React, { useCallback, useState, useEffect } from 'react';
//import React, { useCallback, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { Row, Col, Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
// import TextInput from 'components/inputs/Input';
import { MdClose } from 'react-icons/md';
import DeleteModal from 'components/general/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { CREATE_RESERVE_SLOTS, UPDATE_RESERVE_SLOTS } from 'actions/reserveSlots';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';

import { BiTrash } from 'react-icons/bi';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import Select from 'components/inputs/Select';
// import { IoPlaySkipBack } from 'react-icons/io5';
// import { NavContext } from 'components/privateRoute/PrivateRoute';
import * as Yup from 'yup';
import ErrorMessage from 'components/inputs/ErrorMessage';

const ReserveYourSlot = ({ onClose = () => {}, payload }) => {
  const dispatch = useDispatch();
  // const { navigateTo } = useContext(NavContext);
  // const reserveSlots = useSelector((state) => state.reserveSlots.reserveSlots);
  const profileData = useSelector((state) => state.profile.userProfile);
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);
  const title = payload ? 'Update Your Reserve Slot' : 'Reserve Your Slot';
  const { t } = useTranslation();
  const userProfileStations = profileData.charging_stations;
  const today = moment().format('YYYY-MM-DD');
  const isNewReservation = !payload;
  const currentTime = moment().format('HH:mm');

  const stationList =
    userProfileStations && userProfileStations.length > 0
      ? allStationByCity.filter((x) => userProfileStations.indexOf(x.id) !== -1)
      : allStationByCity;
  const initialValues = payload
    ? {
        slots: [
          {
            charging_station: payload.charging_station?.id,
            charger: _.get(payload, 'charger').map((item) => item.id),
            startDate: moment.utc(payload.startDate).format('yyyy-MM-DD'),
            endDate: moment.utc(payload.endDate).format('yyyy-MM-DD'),
            timeFrom: payload.timeFrom,
            timeTo: payload.timeTo,
          },
        ],
      }
    : {
        slots: [
          {
            charging_station: '',
            charger: '',
            startDate: today,
            endDate: today,
            timeFrom: '',
            timeTo: '',
          },
        ],
      };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      access_type: 'Public',
    };
    dispatch({ type: GET_STATION_BY_CITY, payload: stationData });
  }, []);

  useEffect(() => {
    getAllStation();
  }, []);

  // const createReserveSlot = useCallback((data) => {
  //   const { charger, startDate, endDate, timeTo, timeFrom } = data;
  //   dispatch({
  //     type: CREATE_RESERVE_SLOTS,
  //     payload: { charger, startDate, endDate, timeTo, timeFrom },
  //   });
  // }, []);

  const editYourReserveSlot = useCallback((id, data) => {
    dispatch({
      type: UPDATE_RESERVE_SLOTS,
      payload: { id, data },
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          setTimeout(() => {
            window.location.reload(); // Reload the page after successful creation
          }, 1000);
        }
      },
    });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
  };

  const deleteReserveSlot = () => {
    setShowDeleteModal(false);
  };

  const validationSchema = Yup.object().shape({
    slots: Yup.array().of(
      Yup.object().shape({
        charging_station: Yup.string().required(t('ReserveYourSlot.station')),
        charger: Yup.array().min(1, 'At least one charger must be selected').required(t('ReserveYourSlot.charger')),
        startDate: Yup.date().required(t('ReserveYourSlot.startDate')),
        endDate: Yup.date().required(t('ReserveYourSlot.endDate')),
        timeFrom: Yup.string()
          .required(t('ReserveYourSlot.startTime'))
          .test('is-greater', 'Start time must be after current time', function (value) {
            const { startDate } = this.parent;
            if (startDate === today) {
              return moment(value, 'HH:mm').isAfter(moment(currentTime, 'HH:mm'));
            }
            return true;
          }),
        timeTo: Yup.string()
          .required(t('ReserveYourSlot.endTime'))
          .test('is-after-start', 'End time must be after start time', function (value) {
            const { timeFrom } = this.parent;
            return moment(value, 'HH:mm').isAfter(moment(timeFrom, 'HH:mm'));
          }),
      })
    ),
  });

  return (
    <React.Fragment>
      <div className="reserve-form-page__main">
        <Card>
          <div className="reserve-form-header__block">
            &nbsp;
            <div className="reserve-header-name">
              <div className="reserve-solt-text-align">{title}</div>
            </div>
            <div className="reserve-close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
            &nbsp;
          </div>
          <div className="reserve-form-body__block">
            <div className="reserve-form--block">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  const { slots } = values;

                  if (payload) {
                    const id = payload.id;
                    editYourReserveSlot(id, slots);
                  } else {
                    slots.forEach((value) => {
                      const { charging_station, charger, startDate, endDate, timeTo, timeFrom } = value;
                      dispatch({
                        type: CREATE_RESERVE_SLOTS,
                        payload: { charging_station, charger, startDate, endDate, timeTo, timeFrom },
                        cb: (res) => {
                          if (_.get(res, 'status') === CREATE_STATUS_CODE) {
                            setTimeout(() => {
                              window.location.reload(); // Reload the page after successful creation
                            }, 1000);
                          }
                        },
                      });
                    });
                  }
                  onClose();
                }}
                /* onSubmit={(values) => {
                if (reserveSlotId) {
                  const slotData = values.slots;
                  slotData?.map((data) => {
                    const updatedValue = { reserveSlotId, data };
                    editYourReserveSlot(updatedValue);
                    onClose();
                  });
                } else {
                  const slotData = values.slots;
                  slotData?.map((data) => {
                    createReserveSlot(data);
                    onClose();
                  });
                }
              }} */
              >
                {({ values, handleSubmit, isSubmitting, setFieldValue, setValues, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    {values
                      ? values.slots.map((slot, index) => {
                          return (
                            <Row key={index}>
                              <Col lg={6}>
                                <span className="font-color">{t('placeHolder.selectStation')}</span>
                                <div>&nbsp;</div>
                                <Select
                                  options={[
                                    ..._.map(stationList, (station) => {
                                      return { label: station.name, value: station.id };
                                    }),
                                  ]}
                                  placeholder={`Station Name 0${index + 1}`}
                                  name="charging_station"
                                  value={slot.charging_station}
                                  onMenuScrollDown={true}
                                  onChange={(val) => {
                                    setFieldValue(`slots.${index}.charging_station`, val);
                                  }}
                                  error={
                                    (errors.slots &&
                                      errors.slots[index] &&
                                      errors.slots[index].charging_station &&
                                      touched.slots &&
                                      touched.slots[index] &&
                                      touched.slots[index].charging_station &&
                                      errors.slots[index].charging_station) ||
                                    null
                                  }
                                />
                              </Col>
                              <Col lg={6}>
                                <span className="font-color">{t('placeHolder.selectCharger')}</span>
                                <div>&nbsp;</div>
                                <Select
                                  options={
                                    slot.charging_station && [
                                      ..._.map(_.get(_.find(stationList, { id: slot.charging_station }), 'chargers'), (chargers) => {
                                        return { label: chargers.charger_id, value: chargers.id };
                                      }),
                                    ]
                                  }
                                  isMulti
                                  placeholder={`Charger Number 0${index + 1}`}
                                  name="charging_point"
                                  value={slot.charger}
                                  onMenuScrollDown={true}
                                  onChange={(val) => {
                                    setFieldValue(`slots.${index}.charger`, val);
                                  }}
                                  error={
                                    errors.slots &&
                                    errors.slots[index] &&
                                    errors.slots[index].charger &&
                                    touched.slots &&
                                    touched.slots[index] &&
                                    touched.slots[index].charger
                                      ? errors.slots[index].charger
                                      : null
                                  }
                                />
                              </Col>
                              <Col lg={6}>
                                <span className="font-color">{t('placeHolder.selectDate')}</span>
                                <div>&nbsp;</div>
                                <div className="time_sec">
                                  <span className="custome_time">
                                    <input
                                      className="ct_selecter"
                                      name="startDate"
                                      min={isNewReservation ? today : undefined}
                                      type="Date"
                                      value={slot.startDate}
                                      onChange={(val) => {
                                        setFieldValue(`slots.${index}.startDate`, val.target.value);
                                        // Ensure endDate is not before startDate
                                        if (moment(val.target.value).isAfter(slot.endDate)) {
                                          setFieldValue(`slots.${index}.endDate`, val.target.value);
                                        }
                                      }}
                                    />
                                    <ErrorMessage>
                                      {errors.slots &&
                                      errors.slots[index] &&
                                      errors.slots[index].startDate &&
                                      touched.slots &&
                                      touched.slots[index] &&
                                      touched.slots[index].startDate
                                        ? errors.slots[index].startDate
                                        : null}
                                    </ErrorMessage>
                                  </span>
                                  <div className="to_sec">to</div>
                                  <span className="custome_time">
                                    <input
                                      className="ct_selecter"
                                      type="Date"
                                      min={isNewReservation ? slot.startDate || today : undefined}
                                      value={slot.endDate}
                                      onChange={(val) => {
                                        setFieldValue(`slots.${index}.endDate`, val.target.value);
                                      }}
                                    />
                                    <ErrorMessage>
                                      {errors.slots &&
                                      errors.slots[index] &&
                                      errors.slots[index].endDate &&
                                      touched.slots &&
                                      touched.slots[index] &&
                                      touched.slots[index].endDate
                                        ? errors.slots[index].endDate
                                        : null}
                                    </ErrorMessage>
                                  </span>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <span className="font-color">{t('placeHolder.selectTime')}</span>
                                <div>&nbsp;</div>
                                <div className="time_sec">
                                  <span className="custome_time">
                                    <input
                                      className="ct_selecter"
                                      type="time"
                                      value={slot.timeFrom}
                                      min={slot.startDate === today ? currentTime : '00:00'}
                                      onChange={(val) => {
                                        setFieldValue(`slots.${index}.timeFrom`, val.target.value);
                                      }}
                                    />
                                    <ErrorMessage>
                                      {errors.slots &&
                                      errors.slots[index] &&
                                      errors.slots[index].timeFrom &&
                                      touched.slots &&
                                      touched.slots[index] &&
                                      touched.slots[index].timeFrom
                                        ? errors.slots[index].timeFrom
                                        : null}
                                    </ErrorMessage>
                                  </span>
                                  <div className="to_sec">to</div>
                                  <span className="custome_time">
                                    <input
                                      className="ct_selecter"
                                      type="time"
                                      value={slot.timeTo}
                                      min={slot.timeFrom}
                                      onChange={(val) => {
                                        setFieldValue(`slots.${index}.timeTo`, val.target.value);
                                      }}
                                    />
                                    <ErrorMessage>
                                      {errors.slots &&
                                      errors.slots[index] &&
                                      errors.slots[index].timeTo &&
                                      touched.slots &&
                                      touched.slots[index] &&
                                      touched.slots[index].timeTo
                                        ? errors.slots[index].timeTo
                                        : null}
                                    </ErrorMessage>
                                  </span>
                                </div>
                              </Col>
                              {_.isNull(payload) && (
                                <Col className="btn_del">
                                  <Button
                                    className="field--btn"
                                    onClick={() => {
                                      const newSlots = values.slots.filter((item, i) => i !== index);

                                      setValues({ ...values, slots: newSlots });
                                    }}
                                  >
                                    <BiTrash size={26} />
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          );
                        })
                      : null}

                    <div className="reserve-slot-btns-sec">
                      <div className="reserve-slot-submit-btn">
                        <Button type="submit" disabled={isSubmitting} style={{ padding: `10px 80px` }} onClick={handleSubmit}>
                          {t('button.submit')}
                        </Button>
                      </div>
                      {_.isNull(payload) && (
                        <div className="reserve-slot-add-btn">
                          <Button
                            className="field--btn field--btn-prime"
                            onClick={() => {
                              setValues({
                                ...values,
                                slots: [...values.slots, { charging_station: '', charger: '', startDate: '', endDate: '', timeTo: '', timeFrom: '' }],
                              });
                            }}
                          >
                            <AiFillPlusCircle size={26} />
                          </Button>
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          {showDeleteModal && (
            <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
              <DeleteModal title="slot" onClose={handleCloseDeleteModel} onRemove={deleteReserveSlot} />
            </Modal>
          )}
        </Card>
      </div>
    </React.Fragment>
  );
};
ReserveYourSlot.propTypes = {
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  onSuccess: PropTypes.func,
  title: PropTypes.string,
  payload: PropTypes.string,
};
export default ReserveYourSlot;
