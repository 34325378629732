export const STATION_UNIT_CONSUMPTION = 'STATION_UNIT_CONSUMPTION';
export const STATION_UNIT_CONSUMPTION_SUCCESS = 'STATION_UNIT_CONSUMPTION_SUCCESS';
export const STATION_UNIT_CONSUMPTION_FAIL = 'STATION_UNIT_CONSUMPTION_FAIL';

export const OCPI_BOOKING = 'OCPI_BOOKING';
export const OCPI_BOOKING_SUCCESS = 'OCPI_BOOKING_SUCCESS';
export const OCPI_BOOKING_FAIL = 'OCPI_BOOKING_FAIL';

export const DOWNLOAD_OCPI_BOOKING = 'DOWNLOAD_OCPI_BOOKING';
export const DOWNLOAD_OCPI_BOOKING_SUCCESS = 'DOWNLOAD_OCPI_BOOKING_SUCCESS';
export const DOWNLOAD_OCPI_BOOKING_FAIL = 'DOWNLOAD_OCPI_BOOKING_FAIL';

export const CHARGING_HISTORY = 'CHARGING_HISTORY';
export const CHARGING_HISTORY_SUCCESS = 'CHARGING_HISTORY_SUCCESS';
export const CHARGING_HISTORY_FAIL = 'CHARGING_HISTORY_FAIL';

export const CHARGING_HISTORY_REPORT = 'CHARGING_HISTORY_REPORT';
export const CHARGING_HISTORY_REPORT_SUCCESS = 'CHARGING_HISTORY_REPORT_SUCCESS';
export const CHARGING_HISTORY_REPORT_FAIL = 'CHARGING_HISTORY_REPORT_FAIL';

export const B2B_STATION_CHARGING_HISTORY = 'B2B_STATION_CHARGING_HISTORY';
export const B2B_STATION_CHARGING_HISTORY_SUCCESS = 'B2B_STATION_CHARGING_HISTORY_SUCCESS';
export const B2B_STATION_CHARGING_HISTORY_FAIL = 'B2B_STATION_CHARGING_HISTORY_FAIL';

export const B2C_STATION_CHARGING_HISTORY = 'B2C_STATION_CHARGING_HISTORY';
export const B2C_STATION_CHARGING_HISTORY_SUCCESS = 'B2C_STATION_CHARGING_HISTORY_SUCCESS';
export const B2C_STATION_CHARGING_HISTORY_FAIL = 'B2C_STATION_CHARGING_HISTORY_FAIL';

export const JSON_REQUEST_RESPONSE_REPORT = 'JSON_REQUEST_RESPONSE_REPORT';
export const JSON_REQUEST_RESPONSE_REPORT_SUCCESS = 'JSON_REQUEST_RESPONSE_REPORT_SUCCESS';
export const JSON_REQUEST_RESPONSE_REPORT_FAIL = 'JSON_REQUEST_RESPONSE_REPORT_FAIL';

export const WALLET_TRANSACTION_REPORT = 'WALLET_TRANSACTION_REPORT';
export const WALLET_TRANSACTION_REPORT_SUCCESS = 'WALLET_TRANSACTION_REPORT_SUCCESS';
export const WALLET_TRANSACTION_REPORT_FAIL = 'WALLET_TRANSACTION_REPORT_FAIL';

export const HMIL_CUSTOM_CHARGING_REPORT = 'HMIL_CUSTOM_CHARGING_REPORT';
export const HMIL_CUSTOM_CHARGING_REPORT_SUCCESS = 'HMIL_CUSTOM_CHARGING_REPORT_SUCCESS';
export const HMIL_CUSTOM_CHARGING_REPORT_FAIL = 'HMIL_CUSTOM_CHARGING_REPORT_FAIL';

export const DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT = 'DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT';
export const DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_SUCCESS = 'DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_SUCCESS';
export const DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_FAIL = 'DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_FAIL';

export const ALL_DATA_REPORT = 'ALL_DATA_REPORT';
export const ALL_DATA_REPORT_SUCCESS = 'ALL_DATA_REPORT_SUCCESS';
export const ALL_DATA_REPORT_FAIL = 'ALL_DATA_REPORT_FAIL';

export const ADD_BILL_DETAILS = 'ADD_BILL_DETAILS';
export const ADD_BILL_DETAILS_SUCCESS = 'ADD_BILL_DETAILS_SUCCESS';
export const ADD_BILL_DETAILS_FAIL = 'ADD_BILL_DETAILS_FAIL';

export const MONEY_TRANSACTION_PG_REPORT = 'MONEY_TRANSACTION_PG_REPORT';
export const MONEY_TRANSACTION_PG_REPORT_SUCCESS = 'MONEY_TRANSACTION_PG_REPORT_SUCCESS';
export const MONEY_TRANSACTION_PG_REPORT_FAIL = 'MONEY_TRANSACTION_PG_REPORT_FAIL';

export const APP_USER_DETAILS_LIST = 'APP_USER_DETAILS_LIST';
export const APP_USER_DETAILS_LIST_SUCCESS = 'APP_USER_DETAILS_LIST_SUCCESS';
export const APP_USER_DETAILS_LIST_FAIL = 'APP_USER_DETAILS_LIST_FAIL';

export const MOST_VEHICLE_CHARGED_LIST = 'MOST_VEHICLE_CHARGED_LIST';
export const MOST_VEHICLE_CHARGED_LIST_SUCCESS = 'MOST_VEHICLE_CHARGED_LIST_SUCCESS';
export const MOST_VEHICLE_CHARGED_LIST_FAIL = 'MOST_VEHICLE_CHARGED_LIST_FAIL';

export const BOOKING_HISTORY = 'BOOKING_HISTORY';
export const BOOKING_HISTORY_SUCCESS = 'BOOKING_HISTORY_SUCCESS';
export const BOOKING_HISTORY_FAIL = 'BOOKING_HISTORY_FAIL';

export const USER_CHARGING_HISTORY = 'USER_CHARGING_HISTORY';
export const USER_CHARGING_HISTORY_SUCCESS = 'USER_CHARGING_HISTORY_SUCCESS';
export const USER_CHARGING_HISTORY_FAIL = 'USER_CHARGING_HISTORY_FAIL';

export const STATION_CHARGING_SUMMARY = 'STATION_CHARGING_SUMMARY';
export const STATION_CHARGING_SUMMARY_SUCCESS = 'STATION_CHARGING_SUMMARY_SUCCESS';
export const STATION_CHARGING_SUMMARY_FAIL = 'STATION_CHARGING_SUMMARY_FAIL';

export const PRIVATE_STATION_CHARGING_SUMMARY = 'PRIVATE_STATION_CHARGING_SUMMARY';
export const PRIVATE_STATION_CHARGING_SUMMARY_SUCCESS = 'PRIVATE_STATION_CHARGING_SUMMARY_SUCCESS';
export const PRIVATE_STATION_CHARGING_SUMMARY_FAIL = 'PRIVATE_STATION_CHARGING_SUMMARY_FAIL';

export const PUBLIC_STATION_CHARGING_SUMMARY = 'PUBLIC_STATION_CHARGING_SUMMARY';
export const PUBLIC_STATION_CHARGING_SUMMARY_SUCCESS = 'PUBLIC_STATION_CHARGING_SUMMARY_SUCCESS';
export const PUBLIC_STATION_CHARGING_SUMMARY_FAIL = 'PUBLIC_STATION_CHARGING_SUMMARY_FAIL';

export const BOOKING_SUMMARY_REPORT = 'BOOKING_SUMMARY_REPORT';
export const BOOKING_SUMMARY_REPORT_SUCCESS = 'BOOKING_SUMMARY_REPORT_SUCCESS';
export const BOOKING_SUMMARY_REPORT_FAIL = 'BOOKING_SUMMARY_REPORT_FAIL';

export const STATION_DOWNTIME_REPORT = 'STATION_DOWNTIME_REPORT';
export const STATION_DOWNTIME_REPORT_SUCCESS = 'STATION_DOWNTIME_REPORT_SUCCESS';
export const STATION_DOWNTIME_REPORT_FAIL = 'STATION_DOWNTIME_REPORT_FAIL';

export const DOWNLOAD_STATION_CHARGING_SUMMARY = 'DOWNLOAD_STATION_CHARGING_SUMMARY';
export const DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS = 'DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS';
export const DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL = 'DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL';

export const DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY = 'DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY';
export const DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY_SUCCESS = 'DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY_SUCCESS';
export const DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY_FAIL = 'DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY_FAIL';

export const DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY = 'DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY';
export const DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY_SUCCESS = 'DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY_SUCCESS';
export const DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY_FAIL = 'DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY_FAIL';

export const DOWNLOAD_METER_VALUES = 'DOWNLOAD_METER_VALUES';
export const DOWNLOAD_METER_VALUES_SUCCESS = 'DOWNLOAD_METER_VALUES_SUCCESS';
export const DOWNLOAD_METER_VALUES_FAIL = 'DOWNLOAD_METER_VALUES_FAIL';

export const DOWNLOAD_BOOKING_HISTORY = 'DOWNLOAD_BOOKING_HISTORY';
export const DOWNLOAD_BOOKING_HISTORY_SUCCESS = 'DOWNLOAD_BOOKING_HISTORY_SUCCESS';
export const DOWNLOAD_BOOKING_HISTORY_FAIL = 'DOWNLOAD_BOOKING_HISTORY_FAIL';

export const DOWNLOAD_USER_CHARGING_HISTORY = 'DOWNLOAD_USER_CHARGING_HISTORY';
export const DOWNLOAD_USER_CHARGING_HISTORY_SUCCESS = 'DOWNLOAD_USER_CHARGING_HISTORY_SUCCESS';
export const DOWNLOAD_USER_CHARGING_HISTORY_FAIL = 'DOWNLOAD_USER_CHARGING_HISTORY_FAIL';

export const DOWNLOAD_B2C_STATION_CHARGING_HISTORY = 'DOWNLOAD_B2C_STATION_CHARGING_HISTORY';
export const DOWNLOAD_B2C_STATION_CHARGING_HISTORY_SUCCESS = 'DOWNLOAD_B2C_STATION_CHARGING_HISTORY_SUCCESS';
export const DOWNLOAD_B2C_STATION_CHARGING_HISTORY_FAIL = 'DOWNLOAD_B2C_STATION_CHARGING_HISTORY_FAIL';

export const DOWNLOAD_MONEY_TRANSACTION_PG_REPORT = 'DOWNLOAD_MONEY_TRANSACTION_PG_REPORT';
export const DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_SUCCESS = 'DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_SUCCESS';
export const DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_FAIL = 'DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_FAIL';

export const DOWNLOAD_WALLET_TRANSACTION_REPORT = 'DOWNLOAD_WALLET_TRANSACTION_REPORT';
export const DOWNLOAD_WALLET_TRANSACTION_REPORT_SUCCESS = 'DOWNLOAD_WALLET_TRANSACTION_REPORT_SUCCESS';
export const DOWNLOAD_WALLET_TRANSACTION_REPORT_FAIL = 'DOWNLOAD_WALLET_TRANSACTION_REPORT_FAIL';

export const DOWNLOAD_ALL_DATA_REPORT = 'DOWNLOAD_ALL_DATA_REPORT';
export const DOWNLOAD_ALL_DATA_REPORT_SUCCESS = 'DOWNLOAD_ALL_DATA_REPORT_SUCCESS';
export const DOWNLOAD_ALL_DATA_REPORT_FAIL = 'DOWNLOAD_ALL_DATA_REPORT_FAIL';

export const DOWNLOAD_STATION_UNIT_CONSUMPTION = 'DOWNLOAD_STATION_UNIT_CONSUMPTION';
export const DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS = 'DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS';
export const DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL = 'DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL';

export const CALENDAR_VIEW = 'CALENDAR_VIEW';
export const CALENDAR_VIEW_SUCCESS = 'CALENDAR_VIEW_SUCCESS';
export const CALENDAR_VIEW_FAIL = 'CALENDAR_VIEW_FAIL';

export const DOWNLOAD_STATION_DOWNTIME_REPORT = 'DOWNLOAD_STATION_DOWNTIME_REPORT';
export const DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS = 'DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS';
export const DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL = 'DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL';

export const DOWNLOAD_CHARGING_HISTORY = 'DOWNLOAD_CHARGING_HISTORY';
export const DOWNLOAD_CHARGING_HISTORY_SUCCESS = 'DOWNLOAD_CHARGING_HISTORY_SUCCESS';
export const DOWNLOAD_CHARGING_HISTORY_FAIL = 'DOWNLOAD_CHARGING_HISTORY_FAIL';

export const DOWNLOAD_B2B_STATION_CHARGING_HISTORY = 'DOWNLOAD_B2B_STATION_CHARGING_HISTORY';
export const DOWNLOAD_B2B_STATION_CHARGING_HISTORY_SUCCESS = 'DOWNLOAD_B2B_STATION_CHARGING_HISTORY_SUCCESS';
export const DOWNLOAD_B2B_STATION_CHARGING_HISTORY_FAIL = 'DOWNLOAD_B2B_STATION_CHARGING_HISTORY_FAIL';

export const AC_METER_READING_REPORT = 'AC_METER_READING_REPORT';
export const AC_METER_READING_REPORT_SUCCESS = 'AC_METER_READING_REPORT_SUCCESS';
export const AC_METER_READING_REPORT_FAIL = 'AC_METER_READING_REPORT_FAIL';

export const DOWNLOAD_AC_METER_READING_REPORT = 'DOWNLOAD_AC_METER_READING_REPORT';
export const DOWNLOAD_AC_METER_READING_REPORT_SUCCESS = 'DOWNLOAD_AC_METER_READING_REPORT_SUCCESS';
export const DOWNLOAD_AC_METER_READING_REPORT_FAIL = 'DOWNLOAD_AC_METER_READING_REPORT_FAIL';

export const DOWNLOAD_APP_USER_DETAIL_REPORT = 'DOWNLOAD_APP_USER_DETAIL_REPORTDOWNLOAD_APP_USER_DETAIL_REPORT';
export const DOWNLOAD_APP_USER_DETAIL_REPORT_SUCCESS = 'DOWNLOAD_APP_USER_DETAIL_REPORTDOWNLOAD_APP_USER_DETAIL_REPORT_SUCCESS';
export const DOWNLOAD_APP_USER_DETAIL_REPORT_FAIL = 'DOWNLOAD_APP_USER_DETAIL_REPORT_FAIL';

export const DOWNLOAD_JOB_VOUCHER = 'DOWNLOAD_JOB_VOUCHER';
export const DOWNLOAD_JOB_VOUCHER_SUCCESS = 'DOWNLOAD_JOB_VOUCHER_SUCCESS';
export const DOWNLOAD_JOB_VOUCHER_FAIL = 'DOWNLOAD_JOB_VOUCHER_FAIL';

export const DOWNLOAD_GST_VOUCHER = 'DOWNLOAD_GST_VOUCHER';
export const DOWNLOAD_GST_VOUCHER_SUCCESS = 'DOWNLOAD_GST_VOUCHER_SUCCESS';
export const DOWNLOAD_GST_VOUCHER_FAIL = 'DOWNLOAD_GST_VOUCHER_FAIL';

export const INVOICE_REPORT = 'INVOICE_REPORT';
export const INVOICE_REPORT_SUCCESS = 'INVOICE_REPORT_SUCCESS';
export const INVOICE_REPORT_FAIL = 'INVOICE_REPORT_FAIL';

export const DOWNLOAD_INVOICE_REPORT = 'DOWNLOAD_INVOICE_REPORT';
export const DOWNLOAD_INVOICE_REPORT_SUCCESS = 'DOWNLOAD_INVOICE_REPORT_SUCCESS';
export const DOWNLOAD_INVOICE_REPORT_FAIL = 'DOWNLOAD_INVOICE_REPORT_FAIL';

export const DOWNLOAD_BOOKING_SUMMARY_REPORT = 'DOWNLOAD_BOOKING_SUMMARY_REPORT';
export const DOWNLOAD_BOOKING_SUMMARY_REPORT_SUCCESS = 'DOWNLOAD_BOOKING_SUMMARY_REPORT_SUCCESS';
export const DOWNLOAD_BOOKING_SUMMARY_REPORT_FAIL = 'DOWNLOAD_BOOKING_SUMMARY_REPORT_FAIL';

export const DOWNLOAD_MOST_VEHICLE_CHARGED_LIST = 'DOWNLOAD_MOST_VEHICLE_CHARGED_LIST';
export const DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_SUCCESS = 'DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_SUCCESS';
export const DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_FAIL = 'DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_FAIL';

export const CAPACITY_UTILISATION = 'CAPACITY_UTILISATION';
export const CAPACITY_UTILISATION_SUCCESS = 'CAPACITY_UTILISATION_SUCCESS';
export const CAPACITY_UTILISATION_FAIL = 'CAPACITY_UTILISATION_FAIL';

export const DOWNLOAD_CAPACITY_DATA = 'DOWNLOAD_CAPACITY_DATA';
export const DOWNLOAD_CAPACITY_DATA_SUCCESS = 'DOWNLOAD_CAPACITY_DATA_SUCCESS';
export const DOWNLOAD_CAPACITY_DATA_ERROR = 'DOWNLOAD_CAPACITY_DATA_ERROR';

export const PAYMENT_STATUS_REPORT = 'PAYMENT_STATUS_REPORT';
export const PAYMENT_STATUS_REPORT_SUCCESS = 'PAYMENT_STATUS_REPORT_SUCCESS';
export const PAYMENT_STATUS_REPORT_FAIL = 'PAYMENT_STATUS_REPORT_FAIL';

export const DOWNLOAD_PAYMENT_STATUS_REPORT = 'DOWNLOAD_PAYMENT_STATUS_REPORT';
export const DOWNLOAD_PAYMENT_STATUS_REPORT_SUCCESS = 'DOWNLOAD_PAYMENT_STATUS_REPORT_SUCCESS';
export const DOWNLOAD_PAYMENT_STATUS_REPORT_FAIL = 'DOWNLOAD_PAYMENT_STATUS_REPORT_FAIL';

export const VEHICLE_CHARGING_SESSION = 'VEHICLE_CHARGING_SESSION';
export const VEHICLE_CHARGING_SESSION_SUCCESS = 'VEHICLE_CHARGING_SESSION_SUCCESS';
export const VEHICLE_CHARGING_SESSION_FAIL = 'VEHICLE_CHARGING_SESSION_FAIL';

export const DOWNLOAD_VEHICLE_CHARGING_SESSION = 'DOWNLOAD_VEHICLE_CHARGING_SESSION';
export const DOWNLOAD_VEHICLE_CHARGING_SESSION_SUCCESS = 'DOWNLOAD_VEHICLE_CHARGING_SESSION_SUCCESS';
export const DOWNLOAD_VEHICLE_CHARGING_SESSION_FAIL = 'DOWNLOAD_VEHICLE_CHARGING_SESSION_FAIL';

export const CHARGING_SESSION_REPORT = 'CHARGING_SESSION_REPORT';
export const CHARGING_SESSION_REPORT_SUCCESS = 'CHARGING_SESSION_REPORT_SUCCESS';
export const CHARGING_SESSION_REPORT_FAIL = 'CHARGING_SESSION_REPORT_FAIL';

export const DOWNLOAD_CHARGING_SESSION_REPORT = 'DOWNLOAD_CHARGING_SESSION_REPORT';
export const DOWNLOAD_CHARGING_SESSION_REPORT_SUCCESS = 'DOWNLOAD_CHARGING_SESSION_REPORT_SUCCESS';
export const DOWNLOAD_CHARGING_SESSION_REPORT_FAIL = 'DOWNLOAD_CHARGING_SESSION_REPORT_FAIL';

export const FAULTY_BOOKING_REPORT = 'FAULTY_BOOKING_REPORT';
export const FAULTY_BOOKING_REPORT_SUCCESS = 'FAULTY_BOOKING_REPORT_SUCCESS';
export const FAULTY_BOOKING_REPORT_FAIL = 'FAULTY_BOOKING_REPORT_FAIL';

export const DOWNLOAD_FAULTY_BOOKING_REPORT = 'DOWNLOAD_FAULTY_BOOKING_REPORT';
export const DOWNLOAD_FAULTY_BOOKING_REPORT_SUCCESS = 'DOWNLOAD_FAULTY_BOOKING_REPORT_SUCCESS';
export const DOWNLOAD_FAULTY_BOOKING_REPORT_FAIL = 'DOWNLOAD_FAULTY_BOOKING_REPORT_FAIL';

export const PLANT_LOAD_FACTOR_REPORT = 'PLANT_LOAD_FACTOR_REPORT';
export const PLANT_LOAD_FACTOR_REPORT_SUCCESS = 'PLANT_LOAD_FACTOR_REPORT_SUCCESS';
export const PLANT_LOAD_FACTOR_REPORT_FAIL = 'PLANT_LOAD_FACTOR_REPORT_FAIL';

export const DOWNLOAD_PLANT_LOAD_FACTOR_REPORT = 'DOWNLOAD_PLANT_LOAD_FACTOR_REPORT';
export const DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_SUCCESS = 'DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_SUCCESS';
export const DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_FAIL = 'DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_FAIL';

export const CUSTOMER_WALLET_TRANSACTION_REPORT = 'CUSTOMER_WALLET_TRANSACTION_REPORT';
export const CUSTOMER_WALLET_TRANSACTION_REPORT_SUCCESS = 'CUSTOMER_WALLET_TRANSACTION_REPORT_SUCCESS';
export const CUSTOMER_WALLET_TRANSACTION_REPORT_FAIL = 'CUSTOMER_WALLET_TRANSACTION_REPORT_FAIL';

export const DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT = 'DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT';
export const DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_SUCCESS = 'DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_SUCCESS';
export const DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_FAIL = 'DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_FAIL';

export const COUPON_USAGE_REPORT = 'COUPON_USAGE_REPORT';
export const COUPON_USAGE_REPORT_SUCCESS = 'COUPON_USAGE_REPORT_SUCCESS';
export const COUPON_USAGE_REPORT_FAIL = 'COUPON_USAGE_REPORT_FAIL';

export const DOWNLOAD_COUPON_USAGE_REPORT = 'DOWNLOAD_COUPON_USAGE_REPORT';
export const DOWNLOAD_COUPON_USAGE_REPORT_SUCCESS = 'DOWNLOAD_COUPON_USAGE_REPORT_SUCCESS';
export const DOWNLOAD_COUPON_USAGE_REPORT_FAIL = 'DOWNLOAD_COUPON_USAGE_REPORT_FAIL';

export const COUPON_PERFORMANCE_REPORT = 'COUPON_PERFORMANCE_REPORT';
export const COUPON_PERFORMANCE_REPORT_SUCCESS = 'COUPON_PERFORMANCE_REPORT_SUCCESS';
export const COUPON_PERFORMANCE_REPORT_FAIL = 'COUPON_PERFORMANCE_REPORT_FAIL';

export const DOWNLOAD_COUPON_PERFORMANCE_REPORT = 'DOWNLOAD_COUPON_PERFORMANCE_REPORT';
export const DOWNLOAD_COUPON_PERFORMANCE_REPORT_SUCCESS = 'DOWNLOAD_COUPON_PERFORMANCE_REPORT_SUCCESS';
export const DOWNLOAD_COUPON_PERFORMANCE_REPORT_FAIL = 'DOWNLOAD_COUPON_PERFORMANCE_REPORT_FAIL';

export const PARTNER_CHARGING_SUMMARY = 'PARTNER_CHARGING_SUMMARY';
export const PARTNER_CHARGING_SUMMARY_SUCCESS = 'PARTNER_CHARGING_SUMMARY_SUCCESS';
export const PARTNER_CHARGING_SUMMARY_FAIL = 'PARTNER_CHARGING_SUMMARY_FAIL';

export const DOWNLOAD_PARTNER_CHARGING_SUMMARY = 'DOWNLOAD_PARTNER_CHARGING_SUMMARY';
export const DOWNLOAD_PARTNER_CHARGING_SUMMARY_SUCCESS = 'DOWNLOAD_PARTNER_CHARGING_SUMMARY_SUCCESS';
export const DOWNLOAD_PARTNER_CHARGING_SUMMARY_FAIL = 'DOWNLOAD_PARTNER_CHARGING_SUMMARY_FAIL';
