import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_WITH_OTP,
  LOGIN_WITH_OTP_SUCCESS,
  LOGIN_WITH_OTP_FAIL,
  TENANT_ADMIN_LOGIN,
  TENANT_ADMIN_LOGIN_SUCCESS,
  TENANT_ADMIN_LOGIN_FAIL,
  TENANT_ADMIN_LOGIN_AS,
  TENANT_ADMIN_LOGIN_AS_SUCCESS,
  TENANT_ADMIN_LOGIN_AS_FAIL,
} from 'actions/authAction';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';

const initialState = {
  loginData: {},
  tenantAdminLoginData: {},
  error: '',
  isLoading: false,
};

// const location = JSON.parse(localStorage.getItem('pathname')) ? JSON.parse(localStorage.getItem('pathname')) : '/dashboard';

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN:
    case CHANGE_PASSWORD:
    case LOGIN_WITH_OTP:
    case TENANT_ADMIN_LOGIN:
    case TENANT_ADMIN_LOGIN_AS: {
      return {
        ...state,
        error: '',
        isLoading: true,
      };
    }

    case LOGIN_SUCCESS: {
      toast.success('Login successfully');
      cookie.set('token', payload.tokens.access.token);
      cookie.set('user-role', payload.user.role);

      if (
        payload.user.role === 'doco' ||
        payload.user.role === 'super_admin' ||
        payload.user.role === 'b2bclient' ||
        payload.user.role === 'fleet_owner' ||
        payload.user.role === 'gentari_custom_role' ||
        payload.user.role === 'finance'
      ) {
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 1000);
      } else if (
        payload.user.role === 'support_admin' ||
        payload.user.role === 'network_admin' ||
        payload.user.role === 'ZMT_admin' ||
        payload.user.role === 'finance_admin'
      ) {
        setTimeout(() => {
          window.location.href = '/tenantAdmin';
        }, 1000);
      } else {
        setTimeout(() => {
          // user with access can view the stations page

          window.location.href = '/stations';
        }, 1000);
      }
      if (payload.user.role === 'super_admin') {
        cookie.set('appToken', cookie.get('token'));
        cookie.set('appUserRole', cookie.get('user-role'));
      }

      //   if (payload.user.role === ('super_admin' || 'cpo' || 'finance' || 'doco' || 'admin')) {
      //     localStorage.removeItem('pathname');
      //     setTimeout(() => {
      //       window.location.href = '/dashboard';
      //     }, 1000);
      //   } else {
      //     localStorage.removeItem('pathname');
      //     setTimeout(() => {
      //       window.location.href = '/dashboard';
      //     }, 1000);
      // }

      return {
        ...state,
        loginData: payload.user,
        isLoading: false,
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loginData: payload,
        isLoading: false,
      };
    }
    case LOGIN_WITH_OTP_SUCCESS: {
      toast.success('Login successfully');
      cookie.set('token', payload.tokens.access.token);
      cookie.set('user-role', payload.user.role);
      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 1000);
      return {
        ...state,
        loginData: payload.user,
        isLoading: false,
      };
    }

    case TENANT_ADMIN_LOGIN_SUCCESS: {
      toast.success('Tenant login successfully');
      cookie.set('token', payload.tokens.access.token);
      cookie.set('user-role', payload.user.role);
      if (payload.user.role === 'tenant_admin') {
        setTimeout(() => {
          window.location.href = '/stations';
        }, 1000);
      } else {
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 1000);
      }

      return {
        ...state,
        tenantAdminLoginData: payload.user,
        isLoading: false,
      };
    }

    case TENANT_ADMIN_LOGIN_AS_SUCCESS: {
      toast.success('Tenant login successfully');
      localStorage.setItem(`${payload.user.tenant}_token`, payload.tokens.access.token);
      localStorage.setItem(
        `${payload.user.tenant}_tenant_data`,
        JSON.stringify({
          tenantId: payload.user.tenant,
          role: payload.user.role,
        })
      );

      if (payload.user.role === 'support' || payload.user.role === 'admin' || payload.user.role === 'ZMT' || payload.user.role === 'finance') {
        setTimeout(() => {
          window.open('/dashboard?tenant=' + payload.user.tenant, '_blank');
        }, 1000);
      }

      return {
        ...state,
        tenantAdminLoginAsData: payload.user,
        isLoading: false,
      };
    }

    case LOGIN_FAIL:
    case CHANGE_PASSWORD_FAIL:
    case LOGIN_WITH_OTP_FAIL:
    case TENANT_ADMIN_LOGIN_FAIL:
    case TENANT_ADMIN_LOGIN_AS_FAIL: {
      //toast.error('Incorrect Phone or Password');
      return {
        ...state,
        error: payload.message,
        isLoading: false,
      };
    }

    default:
      return { ...state };
  }
};
export default authReducer;