import React, { useEffect, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import * as _ from 'lodash';
import Card from 'components/inputs/Card';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { CREATE_CPO, GET_CPO, UPDATE_CPO } from 'actions/partnerManagement';
import { FETCH_COUNTRY, FETCH_STATE, FETCH_CITY } from 'actions/address';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
//import { Spinner } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const EmspInformation = ({ setCurrentTab }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ocpiId } = useParams();
  const token = cookie.get('token');

  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCity = useSelector((state) => state.city.city);
  const isLoading = useSelector((state) => state.partnerManagement.isLoading);
  const ocpiData = useSelector((state) => state.partnerManagement.partnerInfo);

  const getEmspData = useCallback(() => {
    dispatch({ type: GET_CPO, payload: ocpiId });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  useEffect(() => {
    ocpiId && getEmspData();
    getAllCountry();
    ocpiId && getStateByCountry({ country_name: ocpiData.country });
    ocpiId && getCityByState({ state_name: ocpiData.state });
  }, [ocpiData.country, ocpiData.state]);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY, payload: data });
  }, []);

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name');
    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const eMSPSchema = Yup.object().shape({
    role: Yup.string().required(t('emspInformation.role')),
    partner_name: Yup.string().required(t('emspInformation.partnerName')),
    // country: Yup.string().required(t('emspInformation.country')),
    //category: Yup.string().required(t("emspInformation.category")),
    // firstAddress: Yup.string().required(t('emspInformation.address')),
    // secondAddress: Yup.string(),
    // state: Yup.string().required(t('emspInformation.state')),
    // city: Yup.string().required(t('emspInformation.city')),
    // pincode: Yup.string().required(t('emspInformation.pincode')),
    // website_url: Yup.string().required(t('emspInformation.websiteUrl')),
    // gst_no: Yup.string().required(t('emspInformation.gstNo')),
  });

  const initialValues = ocpiId
    ? {
        role: ocpiData.role,
        country: ocpiData.country,
        //category: ocpiData.category,
        firstAddress: ocpiData.firstAddress,
        secondAddress: ocpiData.secondAddress,
        state: ocpiData.state,
        city: ocpiData.city,
        pincode: ocpiData.pincode,
        website_url: ocpiData.website_url,
        gst_no: ocpiData.gst_no,
        logo: ocpiData.logo,
        partner_name: ocpiData.partner_name,
      }
    : {
        role: '',
        country: '',
        //category: '',
        firstAddress: '',
        secondAddress: '',
        state: '',
        city: '',
        pincode: '',
        website_url: '',
        gst_no: '',
        logo: '',
        partner_name: '',
      };

  const partnersRole = [
    { label: 'CPO', value: 'CPO' },
    { label: 'EMSP', value: 'EMSP' },
    { label: 'Both', value: 'Both' },
  ];

  return (
    <React.Fragment>
      <LayoutContainer>
        {isLoading ? (
          <div className="loader--block">
            <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
              <Skeleton height={15} width={100} />
            </SkeletonTheme>
          </div>
        ) : (
          <Card className="customerBooking-table">
            {
              <Formik
                initialValues={initialValues}
                validationSchema={eMSPSchema}
                onSubmit={(val) => {
                  if (ocpiId) {
                    // const { firstAddress, secondAddress, state, city, pincode, website_url, gst_no, logo, role } = val;
                    // const data = { firstAddress, secondAddress, state, city, pincode, website_url, gst_no, logo, role };
                    const ocpiData = _.omit(val, ['country']);
                    dispatch({
                      type: UPDATE_CPO,
                      payload: { ...ocpiData, id: ocpiId },
                      cb: (res) => {
                        if (_.get(res, 'status', '') === UPDATE_STATUS_CODE) {
                          setCurrentTab('Connectivity');
                        }
                      },
                    });
                  } else {
                    dispatch({
                      type: CREATE_CPO,
                      payload: val,
                      cb: (res) => {
                        if (_.get(res, 'status', '') === CREATE_STATUS_CODE) {
                          setCurrentTab('Connectivity');
                        }
                      },
                    });
                  }
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue, handleChange }) => (
                  <Form onSubmit={handleSubmit}>
                    <Card>
                      <div className="cpo_sec">
                        <div className="business_details_sec">
                          <div className="cpo_title">
                            <span>{t('cpoInformation.business')}</span>
                          </div>
                          <Row>
                            <Col>
                              <Select
                                isRequired={ocpiId ? false : true}
                                label={t('cpoInformation.roleLabel')}
                                options={_.map(partnersRole, (item) => {
                                  return { label: item.label, value: item.value };
                                })}
                                placeholder={t('cpoInformation.roleLabel')}
                                name="role"
                                value={values.role}
                                onMenuScrollDown={true}
                                onChange={(val) => {
                                  setFieldValue(`role`, val);
                                }}
                                error={errors.role && touched.role ? errors.role : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Select
                                // isRequired={ocpiId ? false : true}
                                label={t('filters.country')}
                                options={_.map(allCountries, (item) => {
                                  return { label: item.name, value: item.name };
                                })}
                                placeholder={t('placeHolder.selectCountry')}
                                name="country"
                                value={values.country}
                                onMenuScrollDown={true}
                                getDataOnScrollDown={getAllCountry}
                                page={countryPage}
                                totalPage={countryTotalPage}
                                onChange={(val) => {
                                  getStateByCountry({ country_name: val });
                                  setFieldValue(`country`, val);
                                }}
                                // error={errors.country && touched.country ? errors.country : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextInput
                                isRequired
                                label={t('cpoInformation.partnerName')}
                                placeholder={t('cpoInformation.partnerName')}
                                name="partner_name"
                                value={values.partner_name}
                                onMenuScrollDown={true}
                                onChange={handleChange}
                                error={errors.partner_name && touched.partner_name ? errors.partner_name : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {ocpiId ? (
                                <TextInput
                                  type="file"
                                  label={t('cpoInformation.partnerLogo')}
                                  name="logo"
                                  accept="image/*"
                                  onChange={(e) => handleImage(e.currentTarget.files[0], 'logo', setFieldValue)}
                                  image={values.logo}
                                />
                              ) : (
                                <TextInput
                                  type="file"
                                  label={t('cpoInformation.partnerLogo')}
                                  name="logo"
                                  accept="image/*"
                                  onChange={(e) => handleImage(e.currentTarget.files[0], 'logo', setFieldValue)}
                                />
                              )}
                            </Col>
                          </Row>
                        </div>
                        <div className="address_details_sec">
                          <div className="cpo_title">
                            <span>{t('cpoInformation.addressInfo')}</span>
                          </div>
                          <Row>
                            <Col>
                              <TextInput
                                // isRequired
                                label={t('cpoInformation.firstAddress')}
                                name="type"
                                placeholder={t('cpoInformation.firstAddress')}
                                value={values.firstAddress}
                                onChange={(val) => setFieldValue('firstAddress', val.target.value)}
                                // error={errors.firstAddress && touched.firstAddress ? errors.firstAddress : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextInput
                                // isRequired
                                label={t('cpoInformation.secondAddress')}
                                placeholder={t('cpoInformation.secondAddress')}
                                value={values.secondAddress}
                                onChange={(val) => setFieldValue('secondAddress', val.target.value)}
                                // error={errors.secondAddress && touched.secondAddress ? errors.secondAddress : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Select
                                // isRequired
                                label={t('filters.state')}
                                options={_.map(allState, (item) => {
                                  return { label: item.name, value: item.name };
                                })}
                                placeholder={t('placeHolder.selectState')}
                                name="state"
                                value={values.state}
                                onMenuScrollDown={true}
                                getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                                page={statePage}
                                totalPage={stateTotalPage}
                                onChange={(val) => {
                                  getCityByState({ state_name: val });
                                  const currentState = _.find(allState, { name: val });
                                  setFieldValue(`state`, currentState.name);
                                  setFieldValue(`city`, '');
                                }}
                                // error={errors.state && touched.state ? errors.state : null}
                              />
                            </Col>
                            <Col>
                              <Select
                                // isRequired
                                label={t('filters.city')}
                                options={
                                  values.state &&
                                  _.map(allCity, (item) => {
                                    return { label: item.name, value: item.name };
                                  })
                                }
                                placeholder={t('placeHolder.selectCity')}
                                name="city"
                                value={values.city}
                                onMenuScrollDown={true}
                                getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: values.state })}
                                page={cityPage}
                                totalPage={cityTotalPage}
                                onChange={(val) => {
                                  const currentCity = _.find(allCity, { name: val });
                                  setFieldValue('city', currentCity.name);
                                }}
                                // error={errors.city && touched.city ? errors.city : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextInput
                                // isRequired
                                label={t('cpoInformation.pincode')}
                                placeholder={t('cpoInformation.pincode')}
                                value={values.pincode}
                                onChange={(val) => setFieldValue('pincode', val.target.value)}
                                // error={errors.pincode && touched.pincode ? errors.pincode : null}
                              />
                            </Col>
                            <Col>
                              <TextInput
                                // isRequired
                                label={t('cpoInformation.website_url')}
                                placeholder={t('cpoInformation.website_url')}
                                value={values.website_url}
                                onChange={(val) => setFieldValue('website_url', val.target.value)}
                                // error={errors.website_url && touched.website_url ? errors.website_url : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextInput
                                // isRequired
                                label={t('cpoInformation.gst')}
                                placeholder={t('cpoInformation.gst')}
                                value={values.gst_no}
                                onChange={(val) => setFieldValue('gst_no', val.target.value)}
                                // error={errors.gst_no && touched.gst_no ? errors.gst_no : null}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Card>
                    <div>&nbsp;</div>
                    <div className="cpo_save_btn">
                      <Button type="submit" className="cpo_save_btn__1">
                        {t('button.save')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            }
          </Card>
        )}
      </LayoutContainer>
    </React.Fragment>
  );
};
EmspInformation.propTypes = {
  setCurrentTab: PropTypes.func,
};
export default EmspInformation;
