import {
  FETCH_COUPONS,
  FETCH_COUPONS_SUCCESS,
  FETCH_COUPONS_FAIL,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  UPDATE_COUPON,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  CREATE_COUPON,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  GET_COUPON,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAIL,
  BOOKING_COUPON,
  BOOKING_COUPON_SUCCESS,
  BOOKING_COUPON_FAIL,
} from 'actions/coupon';
import { toast } from 'react-toastify';
import _ from 'lodash';

const initialState = {
  isLoading: false,
  coupons: [],
  couponDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
  bookingCoupon: [],
};

const couponReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_COUPON:
    case DELETE_COUPON:
    case FETCH_COUPONS:
    case CREATE_COUPON:
    case GET_COUPON: {
      return { ...state, isLoading: true };
    }

    case FETCH_COUPONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        coupons: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_COUPON_SUCCESS: {
      return {
        ...state,
        couponDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_COUPON_SUCCESS: {
      toast.success('Coupon added successfully');
      return {
        ...state,
        coupons: _.concat(state.coupons, payload),
        isLoading: false,
      };
    }

    case UPDATE_COUPON_SUCCESS: {
      toast.success('Coupon updated successfully');
      return {
        ...state,
        isLoading: false,
        coupons: state.coupons.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_COUPON_SUCCESS: {
      toast.success('Coupon deleted successfully');
      return {
        ...state,
        coupons: state.coupons.filter((data) => data.id !== payload),
      };
    }
    case BOOKING_COUPON:
    case BOOKING_COUPON_SUCCESS: {
      return {
        ...state,
        bookingCoupon: payload.results,
      };
    }

    case DELETE_COUPON_FAIL:
    case UPDATE_COUPON_FAIL:
    case FETCH_COUPONS_FAIL:
    case CREATE_COUPON_FAIL:
    case GET_COUPON_FAIL:
    case BOOKING_COUPON_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};

export default couponReducer;
