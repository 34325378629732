import { GET_LOCATION, GET_LOCATION_FAIL, GET_LOCATION_SUCCESS } from 'actions/partnerManagement';

const initialState = {
  isLoading: false,
  partnerLocation: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const partnerLocationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_LOCATION: {
      return { ...state, isLoading: true };
    }
    case GET_LOCATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        partnerLocation: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_LOCATION_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default partnerLocationReducer;
