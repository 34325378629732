import React, { useState, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
// import './admin.css';
import { toast } from 'react-toastify';
import { updateMeterValuesAPI } from 'api/negativeValue';
import { updateBooking } from 'api/socapi';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import RadioButton from 'components/inputs/RadioButton';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { STOP_TRANSACTION } from 'actions/chargerBooking';
import Footer from 'components/general/Footer';
import CheckBox from 'components/inputs/CheckBox';
import { GiSwipeCard } from 'react-icons/gi';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import fileDownload from 'js-file-download';
import moment from 'moment';
import {
  UPLOAD_BULK_BOOKING_TIME,
  UPLOAD_BULK_METER_VALUES_B2B,
  UPLOAD_BULK_METER_VALUES_B2C,
  UPLOAD_BULK_SOC_VALUES,
  UPLOAD_BULK_STOP_TRANSACTION,
} from 'actions/updateBulkNegativeValue';

const Negativemetervalue = () => {
  const { t } = useTranslation();
  const [StopChargerId, setStopChargerId] = useState('');
  const [transactionId, settransactionId] = useState('');
  const [idTag, setidTag] = useState('');
  const [meterStop, setmeterStop] = useState('');
  const [timestamp, settimestamp] = useState('');
  const handleDownStartTimeChange = (event) => {
    settimestamp(event.target.value);
  };
  const [selectTime, setSelectTime] = useState('');

  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const handleSelectTimeChange = (event) => {
    setSelectTime(event.target.value);
  };

  const [submitting] = useState(false);
  const dispatch = useDispatch();

  const handleUpdateStopMeter = () => {
    // Perform validations for the inputs
    if (transactionId === '' || idTag === '' || meterStop === '' || timestamp === '' || selectTime === '') {
      toast.error('Please fill all fields');
      return;
    }

    // Concatenate timestamp and selectTime, and convert to UTC
    const timestampUTC = new Date(`${timestamp}T${selectTime}`).toISOString();

    let data = {
      id: StopChargerId,
      transactionId,
      idTag,
      meterStop,
      timestamp: timestampUTC,
    };

    dispatch({
      type: STOP_TRANSACTION,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Stop Transaction successfully');
          }
        }
      },
    });
    handleClearStopMeterAfterUpdate();
  };

  const [chargerId, setChargerId] = useState('');
  const [dateStart, setDateStart] = useState('');
  const [timeStart, setTimeStart] = useState('');
  const [dateStop, setDateStop] = useState('');
  const [timeStop, setTimeStop] = useState('');
  const [booking_start, setbooking_start] = useState('');
  const [booking_stop, setbooking_stop] = useState('');
  const [isInputMode, setIsInputMode] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const [bookingId, setBookingId] = useState('');
  const [startSoC, setStartSoC] = useState('');
  const [stopSoC, setStopSoC] = useState('');

  const sampleMeterValuesList = isInputMode
    ? !isChecked
      ? [
          {
            bookingId: '',
            meterStart: '',
            meterStop: '',
          },
        ]
      : [
          {
            chargerBookingId: '',
            meterstart: '',
            meterstop: '',
          },
        ]
    : [
        {
          chargerBookingId: '',
          'startDate (MM/DD/YYYY)': '',
          'startTime (HH:mm:ss)': '',
          'stopDate (MM/DD/YYYY)': '',
          'stopTime (HH:mm:ss)': '',
        },
      ];

  const sampleSOCValuesList = [
    {
      chargerBookingId: '',
      StartSoC: '',
      StopSoC: '',
    },
  ];

  const sampleStopTransaction = [
    {
      charger_id: '',
      transactionId: '',
      idTag: '',
      meterStop: '',
      'Date (MM/DD/YYYY)': '',
      'time (HH:mm:ss)': '',
    },
  ];

  const updateBulkStopTransaction = useCallback((data) => {
    dispatch({
      type: UPLOAD_BULK_STOP_TRANSACTION,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === 200) {
          toast.success('Data Updated Successfully');
          fileDownload(res.data, `${'Stop Transactions'}.xlsx`);
          window.location.reload(); 
        } else {
          toast.error('An error occurred while updating list.');
          window.location.reload(); 
        }
      },
    });
  }, []);

  const uploadfile = () => {
    //upload a excel file and get data of uploaded excel
    const fileUpload = document.getElementById('uploadFile');
    const regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = () => {
            processExcelFile(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        toast.error('This browser does not support HTML5.');
      }
    } else {
      toast.error('Please upload a valid Excel file.');
    }
  };

  const processExcelFile = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
  
    let uniqueEntries = new Set(); // To track duplicates
  
    _.map(excelRows, (data, index) => {
      // Fetch the raw date value from the excel sheet
      let rawDate = data['Date (DD/MM/YYYY)'];
      let rawTime = data['time (HH:mm:ss)'];
  
      // Check for missing date or time
      if (!rawDate || !rawTime) {
        toast.error(`Missing value in Date or Time column at row ${index + 2}`);
        window.location.reload(); 
        return; // Skip this row
      }
  
      // Check if the date is in serial format (Excel serial dates are numbers)
      if (!isNaN(rawDate)) {
        // Convert Excel serial date to a proper date
        let excelDate = new Date(Math.round((rawDate - 25569) * 86400 * 1000));
        rawDate = moment(excelDate).format('DD/MM/YYYY');
      }
  
      // Parse the date with moment using multiple formats
      let formattedDate = moment(rawDate, ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD.MM.YYYY', moment.ISO_8601], true);
  
      if (!formattedDate.isValid()) {
        toast.error(`Invalid date format at row ${index + 2}: ${rawDate}`);
        window.location.reload(); 
        return; // Skip this row
      }
  
      // Force the date into DD/MM/YYYY format
      const dateString = formattedDate.format('DD/MM/YYYY');
  
      // Handle time in serial format (some versions of Excel store time as fractions of a day)
      if (!isNaN(rawTime)) {
        let excelTime = moment.utc(Math.round(rawTime * 86400 * 1000)).format('HH:mm:ss');
        rawTime = excelTime;
      }
  
      // Check if time is valid using regex (HH:mm:ss)
      const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
      if (!timeRegex.test(rawTime)) {
        toast.error(`Invalid time format at row ${index + 2}: ${rawTime}`);
        return; // Skip this row
      }
  
      const z = dateString.split('/');
      const z1 = rawTime.split(':');
  
      // Create timestamp using the correct date format and time
      const formattedStopDate = moment().set({
        year: z[2],
        month: z[1] - 1, // moment.js months are 0-based
        date: z[0],
        hour: z1[0],
        minute: z1[1],
        second: z1[2]
      });
  
      // Check for duplicate entries using a combination of date and time (or use idTag if available)
      const uniqueKey = `${dateString}-${rawTime}-${data.idTag || ''}`;
      if (uniqueEntries.has(uniqueKey)) {
        toast.error(`Duplicate entry found at row ${index + 2}: Date ${dateString}, Time ${rawTime}`);
        window.location.reload(); 
        return; // Skip this row
      }
      uniqueEntries.add(uniqueKey);
  
      // Assign the timestamp to the data
      data.timestamp = formattedStopDate;
  
      // Remove the original date and time fields
      delete data['Date (DD/MM/YYYY)'];
      delete data['time (HH:mm:ss)'];
  
      // Ensure idTag is a string
      data.idTag = data.idTag ? data.idTag.toString() : '';
    });
  
    updateBulkStopTransaction(excelRows);
  };
  
  
  

  const updateBulkMeterValuesB2C = useCallback((data) => {
    dispatch({
      type: UPLOAD_BULK_METER_VALUES_B2C,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === 200) {
          toast.success('Data Updated Successfully');
          fileDownload(res.data, `${'Meter Values B2C'}.xlsx`);
        } else {
          toast.error('An error occurred while updating list.');
        }
      },
    });
  }, []);

  const updateBulkMeterValuesB2B = useCallback((data) => {
    dispatch({
      type: UPLOAD_BULK_METER_VALUES_B2B,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === 200) {
          toast.success('Data Updated Successfully');
          fileDownload(res.data, `${'Meter Values B2B'}.xlsx`);
        } else {
          toast.error('An error occurred while updating list.');
        }
      },
    });
  }, []);

  const updateBulkBookings = useCallback((data) => {
    dispatch({
      type: UPLOAD_BULK_BOOKING_TIME,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === 200) {
          toast.success('Data Updated Successfully');
          fileDownload(res.data, `${'Booking Dates'}.xlsx`);
        } else {
          toast.error('An error occurred while updating list.');
        }
      },
    });
  }, []);

  const updateBulkSOC = useCallback((data) => {
    dispatch({
      type: UPLOAD_BULK_SOC_VALUES,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === 200) {
          toast.success('Data Updated Successfully');
          fileDownload(res.data, `${'SOC Values'}.xlsx`);
        } else {
          toast.error('An error occurred while updating list.');
        }
      },
    });
  }, []);

  const upload = () => {
    //upload a excel file and get data of uploaded excel
    const fileUpload = document.getElementById('fileUpload');
    const regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = () => {
            processExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        toast.error('This browser does not support HTML5.');
      }
    } else {
      toast.error('Please upload a valid Excel file.');
    }
  };

  const processExcel = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
    if (isInputMode) {
      if (!isChecked) {
        updateBulkMeterValuesB2C(excelRows);
      } else {
        updateBulkMeterValuesB2B(excelRows);
      }
    } else {
      _.map(excelRows, (data) => {
        const startDateTime = (data['startDate (MM/DD/YYYY)'] - 25569) * 86400 * 1000 + data['startTime (HH:mm:ss)'] * 86400 * 1000;
        const formattedStartDate = moment(startDateTime).subtract(330, 'minutes');
        data.booking_start = formattedStartDate;
        delete data['startDate (MM/DD/YYYY)'];
        delete data['startTime (HH:mm:ss)'];

        const stopDateTime = (data['stopDate (MM/DD/YYYY)'] - 25569) * 86400 * 1000 + data['stopTime (HH:mm:ss)'] * 86400 * 1000;
        const formattedStopDate = moment(stopDateTime).subtract(330, 'minutes');
        data.booking_stop = formattedStopDate;
        delete data['stopDate (MM/DD/YYYY)'];
        delete data['stopTime (HH:mm:ss)'];
      });
      updateBulkBookings(excelRows);
    }
  };

  const handleUpdate = () => {
    if (stopSoC > 100) {
      toast.error('stop SOC Can not grater then 100');
      return;
    }

    if (stopSoC === '') {
      toast.error('Please fill all fields');
      return;
    }

    if (startSoC === '') {
      toast.error('Please fill all fields');
      return;
    }

    updateBooking(bookingId, startSoC, stopSoC)
      .then(() => {
        toast.success('Data Updated');
        bookingId('');
        startSoC('');
        stopSoC('');
        // Handle any further actions upon successful update
      })
      .catch((error) => {
        toast.error(error);
        // Handle any error cases
      });
    handleClearFieldsSOC();
  };

  const uploads = () => {
    //upload a excel file and get data of uploaded excel
    const fileUpload = document.getElementById('file');
    const regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = () => {
            processingExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        toast.error('This browser does not support HTML5.');
      }
    } else {
      toast.error('Please upload a valid Excel file.');
    }
  };

  const processingExcel = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
    updateBulkSOC(excelRows);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (isInputMode && booking_start === '') {
      toast.error('Please fill all fields');
      return;
    }

    if (isInputMode && booking_stop === '') {
      toast.error('Please fill all fields');
      return;
    }

    if (parseFloat(booking_start) > parseFloat(booking_stop)) {
      toast.error('MeterStart must be less then MeterStop');
      return;
    }

    if (!isInputMode && (!dateStart || !timeStart || !dateStop || !timeStop)) {
      toast.error('Please fill in the date and time');
      return;
    }

    // Check if start date and time are greater than end date and time
    if (!isInputMode) {
      try {
        const startDate = new Date(`${dateStart}T${timeStart}:00.000Z`);
        const stopDate = new Date(`${dateStop}T${timeStop}:00.000Z`);

        if (startDate.getTime() > stopDate.getTime()) {
          toast.error('End Time must be greater than Start Time');
          return;
        }
      } catch (error) {
        // Handle the error appropriately, such as displaying an error message or logging the error.
        toast.error('Ensure date time combination is correct');
        return;
      }
    }

    try {
      let formattedStartDate = '';
      let formattedStopDate = '';

      if (isInputMode) {
        formattedStartDate = new Date().toISOString();
        formattedStopDate = new Date().toISOString();
      } else {
        const iststartDate = new Date(`${dateStart}T${timeStart}:00.000Z`);
        formattedStartDate = new Date(iststartDate.getTime() - iststartDate.getTimezoneOffset() * -60000).toISOString();
        console.log(formattedStartDate);

        const iststopDate = new Date(`${dateStop}T${timeStop}:00.000Z`);
        formattedStopDate = new Date(iststopDate.getTime() - iststopDate.getTimezoneOffset() * -60000).toISOString();
        console.log(formattedStopDate);
      }

      await updateMeterValuesAPI(
        chargerId,
        isInputMode ? booking_start : null,
        isInputMode ? booking_stop : null,
        formattedStartDate,
        formattedStopDate,
        isChecked
      );
      toast.success('Data Updated ');
    } catch (error) {
      toast.error('Failed to update meter values:', error);
    }
    handleClearFieldsMeterValue();
  };

  const handleRadioChange = (event) => {
    const value = event.target.value === 'input';
    setIsInputMode(value);
    if (!value) {
      setbooking_start('');
      setbooking_stop('');
    }
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const downloadSample = (file, fileName) => {
    // download static Excel file
    const ws = XLSX.utils.json_to_sheet(file);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    // Adjust column widths based on content length
    const wscols = [];
    XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
      row.forEach((col, i) => {
        if (!wscols[i]) {
          wscols[i] = { wch: 0 };
        }
        if (col && col.toString().length + 2 > wscols[i].wch) {
          wscols[i].wch = col.toString().length + 20;
        }
      });
    });
    ws['!cols'] = wscols;

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleClearFieldsMeterValue = () => {
    setChargerId('');
    setDateStart('');
    setTimeStart('');
    setDateStop('');
    setTimeStop('');
    setbooking_start('');
    setbooking_stop('');
  };

  const handleClearFieldsSOC = () => {
    setBookingId('');
    setStartSoC('');
    setStopSoC('');
  };
  const handleClearStopMeter = () => {
    settransactionId('');
    setidTag('');
    setmeterStop('');
    settimestamp('');
    setSelectTime('');
    setStopChargerId('');
  };
  const handleClearStopMeterAfterUpdate = () => {
    settransactionId('');
    setidTag('');
    setmeterStop('');
    settimestamp('');
    setSelectTime('');
    setStopChargerId('');
  };

  return (
    <LayoutContainer>
      <AdminHeader title={t('header.negativeMeterValue')} />
      <div className="page-content-wrapper scrollable">
        <div className="adminmain">
          <h4>{t('addNegetivemetervalue.updateFaultyBooking')}</h4>
          <form onSubmit={handleFormSubmit}>
            <Row className="align-items-center">
              <Col lg={6} md={6}>
                <TextInput
                  label="Booking ID"
                  placeholder={t('placeHolder.bookingID')}
                  name="Booking"
                  type="text"
                  value={chargerId}
                  onChange={(e) => setChargerId(e.target.value)}
                />
              </Col>
              <Col lg={6} md={6}>
                <div className="radioModule">
                  <RadioButton containerClassName="radio-field" checked={isInputMode} onChange={handleRadioChange} value="input">
                    {t('addNegetivemetervalue.meterValue')}
                  </RadioButton>
                  <RadioButton containerClassName="radio-field" value="date" checked={!isInputMode} onChange={handleRadioChange}>
                    {t('addNegetivemetervalue.chooseDate')}
                  </RadioButton>
                </div>
                {isInputMode && (
                  <Row>
                    <Col lg={6} md={6}>
                      <div className="radioModule">
                        <CheckBox containerClassName="radio-field" checked={!isChecked} onChange={handleChange}>
                          {t('addNegetivemetervalue.b2c')}
                        </CheckBox>
                        <CheckBox containerClassName="radio-field" checked={isChecked} onChange={handleChange}>
                          {t('addNegetivemetervalue.b2b')}
                        </CheckBox>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              {isInputMode ? (
                <Col lg={6} md={6}>
                  <Row>
                    <Col lg={6} md={6}>
                      <TextInput
                        label="Meter Start"
                        placeholder={t('placeHolder.meterStart')}
                        name="meter_start"
                        type="text"
                        value={booking_start}
                        onChange={(e) => setbooking_start(e.target.value)}
                      />
                    </Col>
                    <Col lg={6} md={6}>
                      <TextInput
                        label="Meter Stop"
                        placeholder={t('placeHolder.meterStop')}
                        name="meter_stop"
                        type="text"
                        value={booking_stop}
                        onChange={(e) => setbooking_stop(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>
              ) : (
                <>
                  <Col lg={6} md={6}>
                    <Row>
                      <p>{t('placeHolder.startTime')}</p>
                      <Col lg={6} md={6}>
                        <TextInput type="date" name="meterstartdate" value={dateStart} onChange={(e) => setDateStart(e.target.value)} />
                      </Col>
                      <Col lg={6} md={6}>
                        <TextInput type="time" name="meterstarttime" value={timeStart} onChange={(e) => setTimeStart(e.target.value)} />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} md={6}>
                    <Row>
                      <p>{t('placeHolder.stopTime')}</p>
                      <Col lg={6} md={6}>
                        <TextInput type="date" name="meterenddate" value={dateStop} onChange={(e) => setDateStop(e.target.value)} />
                      </Col>
                      <Col lg={6} md={6}>
                        <TextInput type="time" name="meterendtime" value={timeStop} onChange={(e) => setTimeStop(e.target.value)} />
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>

            <Col lg={6} md={12}>
              <div className="import-admin-block">
                <TextInput
                  label={isInputMode ? t('addNegetivemetervalue.importMeterValues') : t('addNegetivemetervalue.importBookingTime')}
                  type="file"
                  onChange={upload}
                  name="fileUpload"
                  id="fileUpload"
                />
                <span className="download-sample-block">
                  <div
                    className="download-sample--file"
                    onClick={() => downloadSample(sampleMeterValuesList, isInputMode ? 'MeterValues' : 'BookingTime')}
                  >
                    <GiSwipeCard color="#58d37d" size={20} />(
                    {isInputMode ? t('addNegetivemetervalue.sampleMeterValueList') : t('addNegetivemetervalue.sampleBookingTimeList')})
                  </div>
                </span>
              </div>
            </Col>
            <div className="button-group">
              <button type="submit">{t('button.update')}</button>
              <button type="button" id="clear" onClick={handleClearFieldsMeterValue}>
                {t('button.clearField')}
              </button>
            </div>
          </form>
        </div>

        <div className="adminmain">
          <h4>{t('addNegetivemetervalue.updateSOCValues')}</h4>
          <Row className="align-items-center">
            <Col lg={4} md={4}>
              <TextInput
                label={t('placeHolder.bookingID')}
                placeholder={t('placeHolder.bookingID')}
                value={bookingId}
                onChange={(e) => setBookingId(e.target.value)}
                type="text"
              />
            </Col>
            <Col lg={4} md={4}>
              <TextInput
                label={t('placeHolder.startSoC')}
                placeholder={t('placeHolder.startSoC')}
                value={startSoC}
                onChange={(e) => setStartSoC(e.target.value)}
                type="text"
              />
            </Col>
            <Col lg={4} md={4}>
              <TextInput
                label={t('placeHolder.stopSoC')}
                placeholder={t('placeHolder.stopSoC')}
                value={stopSoC}
                onChange={(e) => setStopSoC(e.target.value)}
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12}>
              <div className="import-admin-block">
                <TextInput label={t('addNegetivemetervalue.importSOCValues')} type="file" onChange={uploads} name="fileUpload" id="file" />
                <span className="download-sample-block">
                  <div className="download-sample--file" onClick={() => downloadSample(sampleSOCValuesList, 'SOCValue')}>
                    <GiSwipeCard color="#58d37d" size={20} />({t('addNegetivemetervalue.sampleSOCValuesList')})
                  </div>
                </span>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <button onClick={handleUpdate}>{t('button.update')}</button>
              <button type="button" id="clear" onClick={handleClearFieldsSOC}>
                {t('button.clearField')}
              </button>
            </Col>
          </Row>
        </div>
        <div className="adminmain">
          <h4>{t('addStopMeter.Title')}</h4>
          <div style={{ alignItems: 'center' }}>
            <Row>
              <Col lg={3} md={3}>
                <TextInput
                  label={t('placeHolder.transactionId')}
                  placeholder={t('placeHolder.transactionId')}
                  value={transactionId}
                  onChange={(e) => settransactionId(e.target.value)}
                  type="text"
                />
              </Col>
              <Col lg={3} md={3}>
                <TextInput
                  label={t('placeHolder.idTag')}
                  placeholder={t('placeHolder.idTag')}
                  value={idTag}
                  onChange={(e) => setidTag(e.target.value)}
                  type="text"
                />
              </Col>
              <Col lg={3} md={3}>
                <TextInput
                  label={t('placeHolder.chargerTimestamp')}
                  placeholder={t('placeHolder.chargerTimestamp')}
                  name="down_start_time"
                  value={timestamp}
                  onChange={handleDownStartTimeChange}
                  type="date"
                />
              </Col>
              <Col lg={3} md={3}>
                <TextInput
                  label={t('placeHolder.selectTime')}
                  placeholder={t('placeHolder.selectTime')}
                  name="select_time"
                  value={selectTime}
                  onChange={handleSelectTimeChange}
                  type="time"
                />
              </Col>
            </Row>
          </div>
          <Row className="align-items-center">
            <Col lg={3} md={3}>
              <TextInput
                label={t('placeHolder.meterStop')}
                type="text"
                placeholder={t('placeHolder.meterStop')}
                value={meterStop}
                onChange={(e) => setmeterStop(e.target.value)}
                style={{ marginTop: '15px' }}
              />
            </Col>
            <Col lg={3} md={3}>
              <TextInput
                type="text"
                label={t('placeHolder.StopChargerId')}
                placeholder={t('placeHolder.StopChargerId')}
                value={StopChargerId}
                onChange={(e) => setStopChargerId(e.target.value)}
                style={{ marginTop: '15px' }}
              />
            </Col>
            <Col lg={6} md={12}>
              <div className="import-admin-block">
                <TextInput
                  label={t('addNegetivemetervalue.importStopTransaction')}
                  type="file"
                  onChange={uploadfile}
                  name="fileUpload"
                  id="uploadFile"
                />
                <span className="download-sample-block">
                  <div className="download-sample--file" onClick={() => downloadSample(sampleStopTransaction, 'StopTransactionList')}>
                    <GiSwipeCard color="#58d37d" size={20} />({t('addNegetivemetervalue.sampleStopTransactionList')})
                  </div>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <button onClick={handleUpdateStopMeter} disabled={submitting}>
                {submitting ? 'Updating...' : t('button.submit')}
              </button>
              <button type="button" id="clear" onClick={handleClearStopMeter}>
                Cancel
              </button>
            </Col>
          </Row>
        </div>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </div>
    </LayoutContainer>
  );
};

export default Negativemetervalue;
