import React, { useEffect, useState, useCallback, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import SessionHeading from 'components/general/SessionHeading';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_CHARGING_STATION, GET_CHARGING_STATION, UPDATE_CHARGING_STATION } from 'actions/chargingStation';
import CheckBox from 'components/inputs/CheckBox';
import RadioButton from 'components/inputs/RadioButton';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { FETCH_AMENITY } from 'actions/amenity';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { FETCH_AREA, FETCH_CITY, FETCH_COUNTRY, FETCH_STATE } from 'actions/address';
import { FETCH_ELECTRICITY_SUPPLIER } from 'actions/electricitySupplier';
import { FETCH_CHARGING_STATION_ACCESS_TYPE } from 'actions/stationAccessType';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { FETCH_TAGS } from 'actions/tagmanagement';
import { MdClear } from 'react-icons/md';
import { CREATE_STATUS_CODE, GET_ADD_ONS, GET_STATIONS, MANAGE_ADD_ONS, MANAGE_STATIONS, UPDATE_STATUS_CODE } from 'components/common/constant';
import { NavContext } from 'components/privateRoute/PrivateRoute';

export const WeekEndOptions = [
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];

export const WeekDayOptions = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
];

const StationForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);

  const { stationId } = useParams();

  const [phoneError, setPhoneError] = useState('');
  const [isDraft, setDraft] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [currentCityId, setCurrentCityId] = useState('');

  const getCountryCode = _.get(parsePhoneNumber(countryCode), 'countryCallingCode', '');
  const getPhoneNumber = _.get(parsePhoneNumber(countryCode), 'nationalNumber', '');

  const chargingStationData = useSelector((state) => state.chargingStation.chargingStationDetail);
  const stationAccessTypeList = useSelector((state) => state.stationAccessType.stationAccessTypes);
  const allElectricitySuppliers = useSelector((state) => state.electricitySupplier.electricitySuppliers);
  const allAmenities = useSelector((state) => state.amenity.amenities);
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCity = useSelector((state) => state.city.city);
  const allArea = useSelector((state) => state.area.areas);
  const allTags = useSelector((state) => state.tagmanagement.tags);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const areaPage = useSelector((state) => state.area.page);
  const electricitySuppliersPage = useSelector((state) => state.electricitySupplier.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);
  const areaTotalPage = useSelector((state) => state.area.totalPages);
  const electricitySuppliersTotalPage = useSelector((state) => state.electricitySupplier.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  const supportApp = _.get(profileData, 'tenant.support_cz_app_booking', '');

  const token = cookie.get('token');
  const editStation = useCallback(
    (data) => {
      dispatch({
        type: UPDATE_CHARGING_STATION,
        payload: data,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            setTimeout(() => {
              navigateTo(`/stations/${stationId}`);
            }, 1000);
          }
        },
      });
    },
    [stationId]
  );

  const getStationDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGING_STATION, payload: id });
  }, []);

  const getAddOns =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_ADD_ONS),
      (data) => data.name
    );

  const manageAddOns =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_ADD_ONS),
      (data) => data.name
    );

  const getStation =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATIONS),
      (data) => data.name
    );

  const manageStation =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATIONS),
      (data) => data.name
    );

  useEffect(() => {
    if (stationId && _.isEmpty(chargingStationData)) {
      getStationDetail(stationId);
    }
    if (stationId && !_.isEmpty(chargingStationData)) {
      getStateByCountry({ country_name: chargingStationData.country, limit: 999 });
      getCityByState({ state_name: chargingStationData.state });
      getAreaByCity({ city_name: chargingStationData.city });
    }
  }, [stationId, chargingStationData]);

  useEffect(() => {
    if (stationId && !_.isEmpty(chargingStationData) && !_.isEmpty(allCity)) {
      const getCityInfo = _.find(allCity, { name: chargingStationData.city });
      if (getCityInfo) {
        getAllElectricitySupplierByCity({ city: getCityInfo.id });
      }
    }
  }, [stationId, allCity]);

  const addStation = useCallback((data) => {
    dispatch({
      type: CREATE_CHARGING_STATION,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/stations');
          }, 1000);
        }
      },
    });
  }, []);

  const getAllAmenity = useCallback((data) => {
    dispatch({ type: FETCH_AMENITY, payload: data ? data : {} });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY, payload: data });
  }, []);

  const getAreaByCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_AREA, payload: data });
  }, []);

  const getAllElectricitySupplierByCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_ELECTRICITY_SUPPLIER, payload: data });
  }, []);

  const getStationAccessType = useCallback((data = {}) => {
    dispatch({ type: FETCH_CHARGING_STATION_ACCESS_TYPE, payload: data });
  }, []);

  const getAllTags = useCallback((data = {}) => {
    dispatch({ type: FETCH_TAGS, payload: data });
  }, []);
  // const convertTimeToUTC = (inputTime) => {
  //   const convertedTime = moment(inputTime, ['hh:mm A']).utc().format();
  //   return convertedTime;
  // };

  // const modifyWorkingHour = (working_hours) => {
  //   working_hours.map((obj) => {
  //     obj.from = convertTimeToUTC(obj.from);
  //     obj.to = convertTimeToUTC(obj.to);
  //   });
  //   return working_hours;
  // };

  useEffect(() => {
    getAllAmenity({ limit: 30 });
    getAllCountry();
    getStationAccessType();
    getAllTags({ limit: 999 });
  }, []);

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name', '');

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };
  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleImages = async (files, savePath, setFieldValue) => {
    let allPhotos = [];
    let allUrls = [...initialValues.photos];
    for (let i = 0; i < Object.keys(files).length; i++) {
      const fileName = _.get(files[i], 'name', '');
      const reader = new FileReader();
      reader.onload = async (event) => {
        allPhotos.push(event.target.result);
        try {
          const response = await axios({
            method: 'get',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
          });

          if (response.status === 201) {
            const signedRequest = _.get(response, 'data.signedRequest', '');
            const url = await handleUploadFiles(signedRequest, reader.fileUsed);
            if (url) {
              allUrls.push(url);
              initialValues[savePath] = allUrls;
              setFieldValue(savePath, allUrls);
            }
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error('Error -> ', error);
        }
      };
      reader.fileUsed = files[i];
      reader.readAsDataURL(files[i]);
    }
    setFieldValue(allUrls);
  };

  const handleUploadFiles = async (url, imageData) => {
    const fileType = _.get(imageData, 'type', '');

    try {
      const uploadResponse = await axios({
        method: 'put',
        headers: {
          'Content-Type': fileType,
          'Content-Encoding': 'base64',
        },
        url: url,
        data: imageData,
      });

      if (uploadResponse.status === 200) {
        const imgUrlData = queryString.parseUrl(url);
        return imgUrlData.url;
      } else {
        toast.error(uploadResponse.data.message);
      }
    } catch (error) {
      console.error('Error -> ', error);
    }
    return false;
  };
  const AddStationSchema = Yup.object().shape({
    name: Yup.string().required(t('stationForm.name')).strict(true).trim(t('stationForm.space')).max(100, t('stationForm.maxlimit')),
    countryCode: Yup.string().required(t('stationForm.countryCode')),
    location: Yup.object().shape({
      lat: Yup.number().required(t('stationForm.lat')).max(9000, t('stationForm.maxLat')).typeError(t('stationForm.typeError')),
      lng: Yup.number().required(t('stationForm.lng')).max(9000, t('stationForm.maxLng')).typeError(t('stationForm.typeError')),
    }),

    razorpay: Yup.object().shape({
      accountId: Yup.string(),
      percentage: Yup.number().min(0, t('stationForm.percentage')).max(100, t('stationForm.percentage')).typeError(t('stationForm.percentage')),
    }),
    // contact_person: Yup.object().shape({
    //   name: Yup.string().required('Name is required').strict(true).trim('Space is not allowed'),
    //   phone: Yup.string().required('Phone number is required').strict(true).trim('Space is not allowed'),
    //   phone: Yup.string().required('Phone number is required').strict(true).trim('Space is not allowed'),
    //   email: Yup.string().required('Email is required').strict(true).trim('Space is not allowed'),
    //   address: Yup.string().required('Address is required').strict(true).trim('Space is not allowed'),
    // }),
    access_type: Yup.string().required(t('stationForm.accessType')),
    country: Yup.string().required(t('stationForm.country')),
    state: Yup.string().required(t('stationForm.state')),
    city: Yup.string().required(t('stationForm.city')),
    area: Yup.string().required(t('stationForm.area')),
    address: Yup.string().required(t('stationForm.address')).strict(true).trim(t('stationForm.space')),
    pincode: Yup.string().required(t('stationForm.pincode')),
    status: Yup.string().required(t('stationForm.status')),
    working_hours: Yup.array().of(
      Yup.object().shape({
        from: Yup.string().required(t('stationForm.fromTo')),
        to: Yup.string().required(t('stationForm.fromTo')),
      })
    ),
    down_start_time: Yup.date(),
    down_stop_time: Yup.date().min(Yup.ref('down_start_time'), t('stationForm.expiryDateText')),
  });

  const EditStationSchema = Yup.object().shape({
    name: Yup.string().required(t('stationForm.name')).strict(true).trim(t('stationForm.space')).max(100, t('stationForm.maxlimit')),
    countryCode: Yup.string().required(t('stationForm.countryCode')),
    location: Yup.object().shape({
      lat: Yup.number().required(t('stationForm.lat')).max(9000, t('stationForm.maxLat')),
      lng: Yup.number().required(t('stationForm.lng')).max(9000, t('stationForm.maxLng')),
    }),

    razorpay: Yup.object().shape({
      accountId: Yup.string(),
      percentage: Yup.number()

        .min(0, t('stationForm.percentage'))
        .max(100, t('stationForm.percentage'))
        .typeError(t('stationForm.percentage')),
    }),

    access_type: Yup.string().required(t('stationForm.accessType')),
    country: Yup.string().required(t('stationForm.country')),
    state: Yup.string().required(t('stationForm.state')),
    city: Yup.string().required(t('stationForm.city')),
    area: Yup.string().required(t('stationForm.area')),
    address: Yup.string().required(t('stationForm.address')),
    pincode: Yup.string().required(t('stationForm.pincode')),
    status: Yup.string().required(t('stationForm.status')),
    // tags: Yup.array().of(Yup.string().required('Tags is required')),
    working_hours: Yup.array().of(
      Yup.object().shape({
        from: Yup.string().required(t('stationForm.fromTo')),
        to: Yup.string().required(t('stationForm.fromTo')),
      })
    ),
    down_start_time: Yup.date(),
    down_stop_time: Yup.date().min(Yup.ref('down_start_time'), t('stationForm.expiryDateText')),
  });

  const initialValues =
    stationId && !_.isEmpty(chargingStationData)
      ? {
          ...chargingStationData,
          tags: _.map(chargingStationData.tags, (item) => item && item.id),
          amenity: _.map(chargingStationData.amenity, (item) => item && item.id),
          pincode: chargingStationData.area ? chargingStationData.pincode : '',
          photos: _.map(chargingStationData?.photos, (item) => item),
          down_start_time: chargingStationData.down_start_time ? moment(_.get(chargingStationData, 'down_start_time')).format('YYYY-MM-DD') : '',
          down_stop_time: chargingStationData.down_stop_time ? moment(_.get(chargingStationData, 'down_stop_time')).format('YYYY-MM-DD') : '',
          contact_phone: `+${_.get(chargingStationData, 'contact_country_code')} ${_.get(chargingStationData, 'contact_phone')}`.toString(),
          razorpay: {
            accountId: _.get(chargingStationData, 'razorpay.accountId', ''),
            percentage: _.get(chargingStationData, 'razorpay.percentage', ''),
          },
        }
      : {
          name: '',
          access_type: '',
          address: '',
          location: {
            lat: '',
            lng: '',
          },
          location_type: '',
          property_name: '',
          evcs_type: '',
          operation_type: '',
          razorpay: {
            accountId: '',
            percentage: '',
          },
          countryCode: '',
          working_hours: [{ day: '', from: '', to: '', avaiabilty_type: 'Available' }],
          timing_type: 'daily',
          description: '',
          amenity: [],
          photos: [],
          down_start_time: '',
          down_stop_time: '',
          partner_logo: '',
          country: '',
          state: '',
          city: '',
          area: '',
          pincode: '',
          show_on_mobile_app: true,
          show_on_cz_app: false,
          show_to_ocpi_partners: true,
          does_support_home_charging: false,
          is_chargecoin_disabled: false,
          home_charging_code: '',
          status: '',
          // tags: [],
          settings: {
            energy_limits: '',
            DISCOM_provider: '',
            notification: ['Email', 'SMS'],
          },
          is_active: true,
          contact_name: '',
          contact_email: '',
          contact_address: '',
          contact_phone: '',
          contact_country_code: '',
        };

  const [droppedPartnerLogo, setDroppedPartnerLogo] = useState(null);

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${stationId ? 'Edit' : 'Add'} ${t('header.chargingStation')}`} backTo={'/stations'} />
        <div className="page-content-wrapper scrollable">
          <div className="add-charging-station-page__main">
            <Card className="station-form--card">
              <Formik
                enableReinitialize={!!stationId}
                initialValues={initialValues}
                validationSchema={stationId ? EditStationSchema : AddStationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  values['is_active'] = !isDraft;
                  const stationValues = {
                    ...values,
                    location: { lat: values.location.lat.toString(), lng: values.location.lng.toString() },
                    razorpay: { accountId: values.razorpay.accountId.toString(), percentage: values.razorpay.percentage.toString() },
                    settings: { ...values.settings, energy_limits: _.get(values, 'settings.energy_limits', '').toString() },
                    countryCode: values.countryCode.toString(),
                    contact_country_code: getCountryCode,
                    contact_name: values.contact_name === '' ? null : values.contact_name,
                    contact_email: values.contact_email === '' ? null : values.contact_email,
                    contact_address: values.contact_address === '' ? null : values.contact_address,
                    contact_phone: values.contact_phone === undefined ? null : getPhoneNumber,
                    partner_logo: values.partner_logo && values.partner_logo.toString(),
                    // working_hours: modifyWorkingHour(values.working_hours),
                  };
                  if (!stationId && values.contact_phone && !isValidPhoneNumber(values.contact_phone)) {
                    setPhoneError('Phone number is not valid!');
                  } else {
                    if (stationId) {
                      const stationData = _.omit(stationValues, [
                        'avg_rating',
                        'rating_count',
                        'total_chargers',
                        'energy_consumed',
                        'chargers_in_use',
                        'average_charging_time',
                        'contact_person',
                        'number_of_sessions',
                        'chargers',
                        'tenant',
                        'availableToUsers',
                        'isFavorite',
                        'createdAt',
                        'updatedAt',
                        'is_external_station',
                        'total_ev_charged',
                        'ocpiCredential',
                        'external_location_id',
                        'accountId',
                        'percentage',
                        'station_power_limit_activate',
                      ]);
                      //edit single station Charging
                      editStation(stationData);
                    } else {
                      //add station Charging
                      const newStationData = {
                        ...values,
                        location: { lat: values.location.lat.toString(), lng: values.location.lng.toString() },
                        razorpay: { accountId: values.razorpay.accountId.toString(), percentage: values.razorpay.percentage.toString() },
                        settings: { ...values.settings, energy_limits: _.get(values, 'settings.energy_limits', '').toString() },
                        countryCode: values.countryCode.toString(),
                        contact_country_code: getCountryCode,
                        contact_phone: getPhoneNumber,
                        partner_logo: values.partner_logo && values.partner_logo.toString(),
                      };
                      addStation(newStationData);
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, handleChange, touched, errors, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <SessionHeading text={t('addStationForm.basicInformations')} />
                      <div className="station-form--block">
                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              label={t('addStationForm.nameofChargingStation')}
                              placeholder={t('placeHolder.chargingStationName')}
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              error={errors.name && touched.name ? errors.name : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label={t('addStationForm.chargingStationAccessType')}
                              options={_.map(stationAccessTypeList, (item) => {
                                return { label: item.name, value: item.name };
                              })}
                              placeholder={t('placeHolder.selectType')}
                              name="access_type"
                              value={values.access_type}
                              onChange={(val) => {
                                setFieldValue(`access_type`, val);
                              }}
                              error={errors.access_type && touched.access_type ? errors.access_type : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label={t('filters.tags')}
                              isMulti
                              options={_.map(allTags, (item) => {
                                return { label: item.name, value: item.id };
                              })}
                              placeholder={t('placeHolder.selectTags')}
                              name="tags"
                              value={values.tags} // Set the initial value directly
                              onChange={(val) => {
                                setFieldValue('tags', val);
                              }}
                              error={errors.tags && touched.tags ? errors.tags : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              as="textarea"
                              rows="1"
                              inputClass="scrollable"
                              label={t('addStationForm.description')}
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                            />
                          </Col>
                          {values.access_type === 'Private' ? (
                            <Col>
                              <Row>
                                <Col lg={6} md={12}>
                                  <div className="show-on-mobile-field-main--block">
                                    <div className="show-on-mobile-checkbox-input__block">
                                      <label htmlFor="does_support_home_charging" className="show-on-mobile--label">
                                        {t('addStationForm.doesSupportHomeCharging')}
                                      </label>
                                      <CheckBox
                                        name="does_support_home_charging"
                                        checked={values.does_support_home_charging}
                                        onChange={(e) => {
                                          setFieldValue('does_support_home_charging', e.target.checked);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                {values.does_support_home_charging === true ? (
                                  <Col lg={6} md={12}>
                                    <TextInput
                                      label={t('addStationForm.homeChargingCode')}
                                      placeholder={t('placeHolder.homeChargingCode')}
                                      name="home_charging_code"
                                      value={values.home_charging_code}
                                      onChange={handleChange}
                                    />
                                  </Col>
                                ) : null}
                              </Row>
                            </Col>
                          ) : null}
                          <Row>
                            <Col lg={12}>
                              <div className="notification-setting__block">
                                <label htmlFor="notification_allow" className="notification--label">
                                  {t('addStationForm.notificationAllowAs')}
                                </label>
                                <div id="notification_allow" className="notification-setting--inner">
                                  <div className="notification--item">
                                    <Select
                                      value={_.get(values, 'settings.notification')}
                                      onChange={(selectedValues) => setFieldValue('settings.notification', selectedValues)}
                                      options={[
                                        { value: 'Email', label: t('addStationForm.email') },
                                        { value: 'SMS', label: t('addStationForm.sms') },
                                      ]}
                                      isMulti={true} // Enable multi-select
                                      placeholder={t('Select Notification Method')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={'auto'}>
                              <div className="show-on-mobile-field-main--block">
                                <div className="show-on-mobile-checkbox-input__block">
                                  <label htmlFor="show_on_mobile_app" className="show-on-mobile--label">
                                    {t('addStationForm.showOnMobileApp')}
                                  </label>
                                  <CheckBox
                                    name="show_on_mobile_app"
                                    checked={values.show_on_mobile_app}
                                    onChange={(e) => setFieldValue('show_on_mobile_app', e.target.checked)}
                                  />
                                </div>
                              </div>
                            </Col>
                            {supportApp ? (
                              <Col lg={'auto'}>
                                <div className="show-on-mobile-field-main--block">
                                  <div className="show-on-mobile-checkbox-input__block">
                                    <label htmlFor="show_on_cz_app" className="show-on-mobile--label">
                                      {t('addStationForm.showOnChargezoneApp')}
                                    </label>
                                    <CheckBox
                                      name="show_on_cz_app"
                                      checked={values.show_on_cz_app}
                                      onChange={(e) => setFieldValue('show_on_cz_app', e.target.checked)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            ) : (
                              ''
                            )}
                            <Col lg={'auto'}>
                              <div className="show-on-mobile-field-main--block">
                                <div className="show-on-mobile-checkbox-input__block">
                                  <label htmlFor="show_to_ocpi_partners" className="show-on-mobile--label">
                                    {t('addStationForm.showToOcpiPartner')}
                                  </label>
                                  <CheckBox
                                    name="show_to_ocpi_partners"
                                    checked={values.show_to_ocpi_partners}
                                    onChange={(e) => setFieldValue('show_to_ocpi_partners', e.target.checked)}
                                  />
                                </div>
                              </div>
                            </Col>
                            {supportApp ? (
                              <Col lg={'auto'}>
                                <div className="show-on-mobile-field-main--block">
                                  <div className="show-on-mobile-checkbox-input__block">
                                    <label htmlFor="is_chargecoin_disabled" className="show-on-mobile--label">
                                      {t('addStationForm.isDisableChargecoin')}
                                    </label>
                                    <CheckBox
                                      name="is_chargecoin_disabled"
                                      checked={values.is_chargecoin_disabled}
                                      onChange={(e) => setFieldValue('is_chargecoin_disabled', e.target.checked)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            ) : (
                              ''
                            )}
                          </Row>
                        </Row>

                        <div className="amenity-group-block">
                          <Row>
                            <Col>
                              <div className="checkbox-input--group">
                                <label htmlFor="amenities" className="checkbox-input-label">
                                  {t('addStationForm.surroundingAmenities')}
                                </label>
                                <div id="amenities" className="amenities-list--block">
                                  <Select
                                    value={values.amenity} // Assuming values.amenity is an array of selected amenity IDs
                                    onChange={(selectedValues) => setFieldValue('amenity', selectedValues)}
                                    options={allAmenities.map((item) => ({ value: item.id, label: item.name }))}
                                    isMulti={true} // Enable multi-select
                                    placeholder={t('placeHolder.selectAmenities')}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              label={t('addStationForm.address')}
                              name="address"
                              value={values.address}
                              onChange={handleChange}
                              error={errors.address && touched.address ? errors.address : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col>
                                <TextInput
                                  isRequired
                                  label={t('addStationForm.latitude')}
                                  name="location.lat"
                                  // type="number"
                                  value={values.location.lat}
                                  onChange={handleChange}
                                  onWheelCapture={(e) => {
                                    e.target.blur();
                                  }}
                                  error={getIn(errors, 'location.lat') && getIn(touched, 'location.lat') ? getIn(errors, 'location.lat') : null}
                                />
                              </Col>
                              <Col>
                                <TextInput
                                  isRequired
                                  label={t('addStationForm.longitude')}
                                  name="location.lng"
                                  // type="number"
                                  value={values.location.lng}
                                  onChange={handleChange}
                                  onWheelCapture={(e) => {
                                    e.target.blur();
                                  }}
                                  error={getIn(errors, 'location.lng') && getIn(touched, 'location.lng') ? getIn(errors, 'location.lng') : null}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col>
                                <Select
                                  isRequired
                                  label={t('addStationForm.country')}
                                  options={allCountries.map((item) => {
                                    return { label: item.name, value: item.name };
                                  })}
                                  placeholder={t('placeHolder.selectCountry')}
                                  name="country"
                                  value={values.country}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={getAllCountry}
                                  page={countryPage}
                                  totalPage={countryTotalPage}
                                  onChange={(val) => {
                                    getStateByCountry({ country_name: val });
                                    const currentCountry = _.find(allCountries, { name: val });
                                    setFieldValue(`country`, val);
                                    setFieldValue(`countryCode`, currentCountry.country_code);
                                    setFieldValue(`state`, '');
                                    setFieldValue(`city`, '');
                                    setFieldValue(`area`, '');
                                    setFieldValue(`pincode`, '');
                                    setFieldValue(`settings.DISCOM_provider`, '');
                                  }}
                                  error={errors.country && touched.country ? errors.country : null}
                                />
                              </Col>
                              <Col>
                                <TextInput
                                  isRequired
                                  disabled
                                  label={t('addStationForm.countryCode')}
                                  name="countryCode"
                                  value={values.countryCode}
                                  error={errors.countryCode && touched.countryCode ? errors.countryCode : null}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col>
                                <Select
                                  isRequired
                                  label={t('addStationForm.state')}
                                  options={_.map(allState, (item) => {
                                    return { label: item.name, value: item.name };
                                  })}
                                  placeholder={t('placeHolder.selectState')}
                                  name="state"
                                  value={values.state}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: _.get(values, 'country') })}
                                  page={statePage}
                                  totalPage={stateTotalPage}
                                  onChange={(val) => {
                                    getCityByState({ state_name: val });
                                    const currentState = _.find(allState, { name: val });
                                    setFieldValue(`state`, currentState.name);
                                    setFieldValue(`city`, '');
                                    setFieldValue(`area`, '');
                                    setFieldValue(`pincode`, '');
                                    setFieldValue(`settings.DISCOM_provider`, '');
                                  }}
                                  error={errors.state && touched.state ? errors.state : null}
                                />
                              </Col>
                              <Col>
                                <Select
                                  isRequired
                                  label={t('addStationForm.city')}
                                  options={
                                    values.state &&
                                    _.map(allCity, (item) => {
                                      return { label: item.name, value: item.name };
                                    })
                                  }
                                  placeholder={t('placeHolder.selectCity')}
                                  name="city"
                                  value={values.city}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: _.get(values, 'state') })}
                                  page={cityPage}
                                  totalPage={cityTotalPage}
                                  onChange={(val) => {
                                    getAreaByCity({ city_name: val });
                                    const currentCity = _.find(allCity, { name: val });
                                    setCurrentCityId(currentCity.id);
                                    getAllElectricitySupplierByCity({ city: currentCity.id });
                                    setFieldValue('city', currentCity.name);
                                    setFieldValue(`area`, '');
                                    setFieldValue(`pincode`, '');
                                  }}
                                  error={errors.city && touched.city ? errors.city : null}
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col lg={6} md={12}>
                            <Row>
                              <Col>
                                <Select
                                  isRequired
                                  label={t('addStationForm.area')}
                                  options={
                                    values.city &&
                                    _.map(allArea, (item) => {
                                      return { label: item.name, value: item.name };
                                    })
                                  }
                                  placeholder={t('placeHolder.selectArea')}
                                  name="area"
                                  value={values.area}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={(data) => getAreaByCity({ ...data, city_name: _.get(values, 'city') })}
                                  page={areaPage}
                                  totalPage={areaTotalPage}
                                  onChange={(val) => {
                                    const currentArea = _.find(allArea, { name: val });
                                    setFieldValue('area', currentArea.name);
                                    setFieldValue(`pincode`, _.get(currentArea, 'postal_code', ''));
                                  }}
                                  error={errors.area && touched.area ? errors.area : null}
                                />
                              </Col>
                              <Col>
                                <TextInput
                                  isRequired
                                  disabled
                                  label={t('addStationForm.pincode')}
                                  name="pincode"
                                  value={values.pincode}
                                  onChange={handleChange}
                                  error={errors.pincode && touched.pincode ? errors.pincode : null}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label={t('addStationForm.status')}
                              options={[
                                { label: 'Unavailable', value: 'Unavailable' },
                                { label: 'Available', value: 'Available' },
                                { label: 'Under Maintenance', value: 'Under Maintenance' },
                                { label: 'Coming Soon', value: 'Coming Soon' },
                                { label: 'In Use', value: 'In Use' },
                                { label: 'Under Commissioning', value: 'Under Commissioning'},
                              ]}
                              placeholder={t('placeHolder.status')}
                              name="status"
                              value={values.status}
                              onChange={(val) => setFieldValue(`status`, val)}
                              error={errors.status && touched.status ? errors.status : null}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput
                              post_text="kWh"
                              type="number"
                              label={t('addStationForm.energyLimit')}
                              name="settings.energy_limits"
                              value={values.settings.energy_limits}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              label={t('addStationForm.discomProvider')}
                              placeholder={t('placeHolder.selectDISCOMProvider')}
                              name="settings.DISCOM_provider"
                              options={
                                values.city &&
                                _.map(allElectricitySuppliers, (electricitySupplierItem) => {
                                  return { label: electricitySupplierItem.name, value: electricitySupplierItem.name };
                                })
                              }
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getAllElectricitySupplierByCity({ ...data, city: currentCityId })}
                              page={electricitySuppliersPage}
                              totalPage={electricitySuppliersTotalPage}
                              value={_.get(values, 'settings.DISCOM_provider')}
                              onChange={(val) => {
                                setFieldValue(`settings.DISCOM_provider`, val === null ? '' : val);
                              }}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <div style={{ textAlign: 'center' }}>
                              <label htmlFor="fileInput" style={{ display: 'block', marginBottom: '10px' }}>
                                {t('addStationForm.addStationImage')}
                              </label>
                              <div
                                onClick={() => document.getElementById('fileInput').click()}
                                onDrop={(e) => {
                                  e.preventDefault();
                                  const droppedFiles = e.dataTransfer.files;
                                  handleImages(droppedFiles, 'photos', setFieldValue);
                                }}
                                onDragOver={(e) => e.preventDefault()}
                                style={{
                                  display: 'block',
                                  border: '2px dashed #ccc',
                                  padding: '20px',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <input
                                  multiple
                                  type="file"
                                  id="fileInput"
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  onChange={(e) => {
                                    const selectedFiles = e.target.files;
                                    handleImages(selectedFiles, 'photos', setFieldValue);
                                  }}
                                />
                                <p>
                                  {values.photos.length !== 0 ? (
                                    'Image Preview'
                                  ) : (
                                    <>
                                      <FaCloudUploadAlt style={{ fontSize: '24px', marginBottom: '5px' }} />
                                      <br />
                                      {t('placeHolder.dragDrop')}
                                    </>
                                  )}
                                </p>
                                {!!values.photos.length &&
                                  values.photos.map((photo, index) => (
                                    <div key={index} style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', gap: '10px' }}>
                                      <img src={photo} alt="Dropped Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                      <MdClear
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          const clearImage = values?.photos.filter((item, i) => i !== index);
                                          initialValues.photos = clearImage;
                                          setFieldValue('photos', clearImage);
                                        }}
                                      />
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </Col>

                          <Col lg={6} md={12}>
                            <div style={{ textAlign: 'center' }}>
                              <label htmlFor="partnerLogoInput" style={{ display: 'block', marginBottom: '10px' }}>
                                {t('addStationForm.partnerLogo')}
                              </label>
                              <div
                                onClick={() => document.getElementById('partnerLogoInput').click()}
                                onDrop={(e) => {
                                  e.preventDefault();
                                  const droppedFiles = e.dataTransfer.files;
                                  if (droppedFiles.length > 0) {
                                    const reader = new FileReader();
                                    reader.onload = (event) => {
                                      setDroppedPartnerLogo(event.target.result);
                                      handleImage(droppedFiles[0], 'partner_logo', setFieldValue);
                                    };
                                    reader.readAsDataURL(droppedFiles[0]);
                                  }
                                }}
                                onDragOver={(e) => e.preventDefault()}
                                style={{
                                  display: 'block',
                                  border: '2px dashed #ccc',
                                  padding: '20px',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <input
                                  type="file"
                                  id="partnerLogoInput"
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  onChange={(e) => {
                                    const selectedFile = e.target.files[0];
                                    if (selectedFile) {
                                      const reader = new FileReader();
                                      reader.onload = (event) => {
                                        setDroppedPartnerLogo(event.target.result);
                                        handleImage(selectedFile, 'partner_logo', setFieldValue);
                                      };
                                      reader.readAsDataURL(selectedFile);
                                    }
                                  }}
                                />
                                <p>
                                  {droppedPartnerLogo ? (
                                    t('stationForm.partnerLogo')
                                  ) : (
                                    <>
                                      <FaCloudUploadAlt style={{ fontSize: '24px', marginBottom: '5px' }} />
                                      <br />
                                      {t('stationForm.dragDrop')}
                                    </>
                                  )}
                                </p>
                                {droppedPartnerLogo && (
                                  <img
                                    src={droppedPartnerLogo}
                                    alt="Dropped Partner Logo Preview"
                                    style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
                                  />
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} md={12} style={{ marginTop: '10px' }}>
                            <TextInput
                              label={t('addStationForm.downStartTime')}
                              placeholder={t('addStationForm.downStartTime')}
                              type="date"
                              name="down_start_time"
                              value={values.down_start_time}
                              onChange={handleChange}
                              error={errors.down_start_time && touched.down_start_time ? errors.down_start_time : null}
                            />
                          </Col>
                          <Col lg={6} md={12} style={{ marginTop: '10px' }}>
                            <TextInput
                              label={t('addStationForm.downStopTime')}
                              placeholder={t('addStationForm.downStopTime')}
                              type="date"
                              name="down_stop_time"
                              value={values.down_stop_time}
                              onChange={handleChange}
                              error={errors.down_stop_time && touched.down_stop_time ? errors.down_stop_time : null}
                            />
                          </Col>
                          <Col lg={6} md={12} style={{ marginTop: '10px' }}>
                            <TextInput
                              label={t('addStationForm.locationType')}
                              placeholder={t('addStationForm.locationType')}
                              name="location_type"
                              value={values.location_type}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={6} md={12} style={{ marginTop: '10px' }}>
                            <TextInput
                              label={t('addStationForm.propertyName')}
                              placeholder={t('addStationForm.propertyName')}
                              name="property_name"
                              value={values.property_name}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={6} md={12} style={{ marginTop: '10px' }}>
                            <TextInput
                              label={t('addStationForm.evscType')}
                              placeholder={t('addStationForm.evscType')}
                              name="evcs_type"
                              value={values.evcs_type}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={6} md={12} style={{ marginTop: '10px' }}>
                            <TextInput
                              label={t('addStationForm.operationType')}
                              placeholder={t('addStationForm.operationType')}
                              name="operation_type"
                              value={values.operation_type}
                              onChange={handleChange}
                            />
                          </Col>
                          {supportApp ? (
                            <>
                              <Col lg={6} md={12} style={{ marginTop: '10px' }}>
                                <TextInput
                                  label={t('addStationForm.routeAccountNumber')}
                                  placeholder={t('addStationForm.revenueSharing')}
                                  type="text"
                                  name="razorpay.accountId"
                                  value={values.razorpay.accountId}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col lg={6} md={12} style={{ marginTop: '10px' }}>
                                <TextInput
                                  label={t('addStationForm.routeAccountPercentage')}
                                  placeholder={t('addStationForm.revenuePercentage')}
                                  type="text"
                                  name="razorpay.percentage"
                                  value={values.razorpay.percentage}
                                  onChange={handleChange}
                                  onWheelCapture={(e) => {
                                    e.target.blur();
                                  }}
                                  error={
                                    getIn(errors, 'razorpay.percentage') && getIn(touched, 'razorpay.percentage')
                                      ? getIn(errors, 'razorpay.percentage')
                                      : null
                                  }
                                />
                              </Col>
                            </>
                          ) : (
                            ''
                          )}
                        </Row>
                      </div>
                    </div>

                    {/*<SessionHeading text="Tag Information" />*/}
                    {/*<div className="station-form--block">*/}
                    {/*  <div className="tag-block__field-inputs">*/}
                    {/*    <FieldArray*/}
                    {/*      name="tags"*/}
                    {/*      render={(arrayHelpers) => (*/}
                    {/*        <>*/}
                    {/*          <Row>*/}
                    {/*            {values.tags.map((tags, index) => (*/}
                    {/*              <>*/}
                    {/*                <Col xl={4} md={6} key={`tags-${index}`}>*/}
                    {/*                  <Row>*/}
                    {/*                    <Col>*/}
                    {/*                      <TextInput*/}
                    {/*                        name="tags"*/}
                    {/*                        value={tags}*/}
                    {/*                        onChange={(e) => setFieldValue(`tags.[${index}]`, e.target.value)}*/}
                    {/*                        error={*/}
                    {/*                          getIn(errors, `tags.[${index}]`) && getIn(touched, `tags.[${index}]`)*/}
                    {/*                            ? getIn(errors, `tags.[${index}]`)*/}
                    {/*                            : null*/}
                    {/*                        }*/}
                    {/*                      />*/}
                    {/*                    </Col>*/}
                    {/*                    <Col xl={1} lg={1} md={1} className="p-0 d-flex align-items-center">*/}
                    {/*                      <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>*/}
                    {/*                        <BiTrash size={26} />*/}
                    {/*                      </Button>*/}
                    {/*                    </Col>*/}
                    {/*                  </Row>*/}
                    {/*                </Col>*/}
                    {/*              </>*/}
                    {/*            ))}*/}
                    {/*          </Row>*/}
                    {/*          <Button className="field--btn" onClick={() => arrayHelpers.insert(values.tags.length)}>*/}
                    {/*            <BiPlus size={22} className="plus--icon" /> Add*/}
                    {/*          </Button>*/}
                    {/*        </>*/}
                    {/*      )}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div>
                      <SessionHeading text={t('addStationForm.operationalTimings')} />
                      <div className="station-form--block">
                        <div className="checkbox-input--group">
                          <div id="timing" className="checkbox--input-items">
                            <Row>
                              <Col xxl={6} xl={8} md={12}>
                                <Row className="m-0">
                                  <Col xl={2} lg={2} md={6} className="p-0">
                                    <RadioButton
                                      checked={values.timing_type === 'daily'}
                                      onChange={() => {
                                        setFieldValue('timing_type', 'daily');
                                        setFieldValue(`working_hours[0].day`, 'daily');
                                      }}
                                    >
                                      {t('addStationForm.daily')}
                                    </RadioButton>
                                  </Col>
                                  <Col xl={4} lg={4} md={6} className="p-0">
                                    <RadioButton checked={values.timing_type === 'weekday'} onChange={() => setFieldValue('timing_type', 'weekday')}>
                                      {t('addStationForm.weekdaysonly')}
                                    </RadioButton>
                                  </Col>
                                  <Col xl={4} lg={4} md={6} className="p-0">
                                    <RadioButton
                                      checked={values.timing_type === 'weekends'}
                                      onChange={() => setFieldValue('timing_type', 'weekends')}
                                    >
                                      {t('addStationForm.weekendsonly')}
                                    </RadioButton>
                                  </Col>
                                  <Col xl={2} lg={2} md={6} className="p-0">
                                    <RadioButton checked={values.timing_type === 'custom'} onChange={() => setFieldValue('timing_type', 'custom')}>
                                      {t('addStationForm.custom')}
                                    </RadioButton>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        {values.timing_type && (
                          <div className="time-block__field-inputs">
                            <FieldArray
                              name="working_hours"
                              render={(arrayHelpers) => (
                                <>
                                  {_.map(values.working_hours, (field, index) => (
                                    <>
                                      <Row key={`station-timimg-${index}`}>
                                        {values.timing_type !== 'daily' && (
                                          <Col lg={5} md={4}>
                                            <Select
                                              label="Day"
                                              name="day"
                                              placeholder="Select Day"
                                              value={field.day || ''}
                                              options={
                                                values.timing_type === 'weekends'
                                                  ? WeekEndOptions
                                                  : values.timing_type === 'weekday'
                                                  ? WeekDayOptions
                                                  : [...WeekDayOptions, ...WeekEndOptions] // For 'custom', show all options
                                              }
                                              onChange={(val) => setFieldValue(`working_hours[${index}].day`, val)}
                                            />
                                          </Col>
                                        )}
                                        <Col lg={6} md={7}>
                                          <Row>
                                            <Col>
                                              <TextInput
                                                isRequired
                                                type="time"
                                                label={t('addStationForm.hourFrom')}
                                                name="from"
                                                value={field.from}
                                                onChange={(e) => setFieldValue(`working_hours[${index}].from`, e.target.value)}
                                                error={
                                                  getIn(errors, `working_hours[${index}].from`) && getIn(touched, `working_hours[${index}].from`)
                                                    ? getIn(errors, `working_hours[${index}].from`)
                                                    : null
                                                }
                                              />
                                            </Col>
                                            <Col>
                                              <TextInput
                                                isRequired
                                                type="time"
                                                label={t('addStationForm.hourTo')}
                                                name="to"
                                                value={field.to}
                                                onChange={(e) => setFieldValue(`working_hours[${index}].to`, e.target.value)}
                                                error={
                                                  getIn(errors, `working_hours[${index}].to`) && getIn(touched, `working_hours[${index}].to`)
                                                    ? getIn(errors, `working_hours[${index}].to`)
                                                    : null
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md={1} lg={1} sm={1} className="d-flex align-items-center">
                                          {index > 0 && (
                                            <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                              <BiTrash size={26} />
                                            </Button>
                                          )}
                                        </Col>
                                      </Row>
                                    </>
                                  ))}
                                  
                                    <div>
                                      <Button
                                        className="field--btn"
                                        onClick={() =>
                                          arrayHelpers.insert(values.working_hours.length, {
                                            day: '',
                                            from: moment().format('h:mm a'),
                                            to: moment().format('h:mm a'),
                                            avaiabilty_type: 'Available',
                                          })
                                        }
                                      >
                                        <BiPlus size={22} className="plus--icon" /> Add
                                      </Button>
                                    </div>
                                  
                                </>
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <SessionHeading text={t('addStationForm.owner/contactPerson')} />
                      <div className="station-form--block">
                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput label={t('addStationForm.name')} name="contact_name" value={values.contact_name} onChange={handleChange} />
                          </Col>
                          <Col lg={6} md={12}>
                            <PhoneNumberInput
                              label={t('addStationForm.phoneNumber')}
                              name="contact_phone"
                              value={values.contact_phone}
                              onChange={(val) => {
                                setFieldValue(`contact_phone`, val);
                                setCountryCode(val || '');
                                if (!_.isEmpty(phoneError)) {
                                  setPhoneError('');
                                }
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput
                              label={t('addStationForm.emailAddress')}
                              name="contact_email"
                              value={values.contact_email}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              label={t('addStationForm.officeAddress')}
                              name="contact_address"
                              value={values.contact_address}
                              onChange={handleChange}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {!_.isEmpty(manageAddOns) ||
                    (!_.isEmpty(getAddOns) && !_.isEmpty(manageAddOns)) ||
                    !_.isEmpty(manageStation) ||
                    (!_.isEmpty(getStation) && !_.isEmpty(manageStation)) ? (
                      <div className="save-draft-btn--block mt-5">
                        <Button type="submit" className="save-btn draft--btn" disabled={isSubmitting} onClick={() => setDraft(true)}>
                          {t('button.saveDraft')}
                        </Button>
                        <Button type="submit" className="save-btn" disabled={isSubmitting} onClick={() => setDraft(false)}>
                          {t('button.savePublish')}
                        </Button>
                      </div>
                    ) : (
                      <div className="save-draft-btn--block mt-5">
                        <Button className="save-btn draft--btn">{t('button.saveDraft')}</Button>
                        <Button className="save-btn">{t('button.savePublish')}</Button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default StationForm;
